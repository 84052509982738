import fetch from 'cross-fetch';
import base64 from 'base-64';
import { API, OPENPAY, MERCHANT_ID, API_KEY } from '../config/api';
import { version } from '../../package.json';

export const getMemberInfoService = (memberId, authToken) =>
  fetch(`${API}/api/v4/community_members/${memberId}?version=${version}`, {
    headers: {
      Authorization: `Token token=${authToken}`
    }
  });

export const getVerificationCodeService = (phone, countries) =>
  fetch(`${API}/api/v4/community_members`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone,
      countries
    })
  });

export const verifyCodeService = (phone, countries, code) =>
  fetch(`${API}/api/v4/community_members/confirm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone,
      countries,
      verify_code: code
    })
  });

export const getMemberBalanceService = (memberId, authToken) =>
  fetch(`${API}/api/v4/community_members/${memberId}/balances`, {
    headers: {
      Authorization: `Token token=${authToken}`
    }
  });

export const getUserCreditCardsService = (memberId, authToken) =>
  fetch(`${API}/api/v4/community_members/${memberId}/credit_cards`, {
    headers: {
      Authorization: `Token token=${authToken}`
    }
  });

export const rechargeBalanceService = (
  memberId,
  authToken,
  creditCard,
  amount,
  currency
) =>
  fetch(`${API}/api/v4/community_members/${memberId}/top_ups`, {
    method: 'POST',
    headers: {
      Authorization: `Token token=${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      credit_card_id: creditCard,
      amount,
      currency
    })
  });

export const deletePaymentMethodService = (memberId, authToken, cardId) =>
  fetch(`${API}/api/v4/community_members/${memberId}/credit_cards/${cardId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Token token=${authToken}`
    }
  });

export const addCreditCardOpenpayService = (
  cardNumber,
  holderName,
  expirationYear,
  expirationMonth,
  cvv2
) =>
  fetch(`${OPENPAY}/${MERCHANT_ID}/tokens`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Basic ${base64.encode(`${API_KEY}:`)}`
    },
    body: JSON.stringify({
      card_number: cardNumber,
      holder_name: holderName,
      expiration_year: expirationYear,
      expiration_month: expirationMonth,
      cvv2
    })
  });

export const addEdenredCardService = (
  authToken,
  memberId,
  code,
  deviceSessionId,
  name
) =>
  fetch(`${API}/api/v4/community_members/${memberId}/credit_cards`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token token=${authToken}`
    },
    body: JSON.stringify({
      token: code,
      device_session_id: deviceSessionId,
      holder_name: name,
      brand_name: 'edenred'
    })
  });

export const addCreditCardService = (
  memberId,
  authToken,
  token,
  deviceSessionId,
  holderName,
  brand
) =>
  fetch(`${API}/api/v4/community_members/${memberId}/credit_cards`, {
    method: 'POST',
    headers: {
      Authorization: `Token token=${authToken}`,
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      token,
      device_session_id: deviceSessionId,
      holder_name: holderName,
      brand_name: brand
    })
  });

export const getDealsHistoryService = (memberId, authToken) =>
  fetch(`${API}/api/v4/community_members/${memberId}/deals`, {
    headers: {
      Authorization: `Token token=${authToken}`
    }
  });

export const updateUserService = (
  memberId,
  authToken,
  name,
  birthDate,
  gender,
  email
) =>
  fetch(`${API}/api/v4/community_members/${memberId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token token=${authToken}`,
      Accept: 'application/json',
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      name,
      gender,
      email,
      birth_date: birthDate
    })
  });

export const resendEmailVerificationService = (memberId, authToken) =>
  fetch(`${API}/api/v4/community_members/${memberId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token token=${authToken}`,
      Accept: 'application/json',
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      resend_email_confirmation: true
    })
  });

export const updateUserForPaymentsService = (
  memberId,
  authToken,
  name,
  email
) =>
  fetch(`${API}/api/v4/community_members/${memberId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token token=${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      name,
      email
    })
  });

export const updateTopUpInfoService = (memberId, authToken, body) =>
  fetch(`${API}/api/v4/community_members/${memberId}/update_auto_top_up`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token token=${authToken}`,
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    body: JSON.stringify(body)
  });

export const getMemberConfigurationsService = (memberId, authToken) =>
  fetch(
    `${API}/api/v4/community_members/${memberId}/community_member_configurations`,
    {
      headers: {
        Authorization: `Token token=${authToken}`
      }
    }
  );

export const updateMemberConfigurationsService = (
  memberId,
  authToken,
  displayRatingModal
) =>
  fetch(
    `${API}/api/v4/community_members/${memberId}/community_member_configurations`,
    {
      method: 'POST',
      headers: {
        Authorization: `Token token=${authToken}`,
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ displayRatingModal })
    }
  );

export const sendRatingService = (memberId, authToken, rating) =>
  fetch(`${API}/api/v4/ratings`, {
    method: 'POST',
    headers: {
      Authorization: `Token token=${authToken}`,
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ community_member_id: memberId, rating })
  });

export const getCommunityMemberMessagesService = (memberId, authToken) =>
  fetch(
    `${API}/api/v4/community_member_messages?community_member_id=${memberId}`,
    {
      headers: {
        Authorization: `Token token=${authToken}`
      }
    }
  );
