import types from './types';

const setLanguage = (language) => (dispatch) => {
  dispatch({
    type: types.SET_LANGUAGE,
    language
  });
};

export default {
  setLanguage
};
