import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    man: "Man",
    woman: "Woman",
    other: "Other",
    selectYourGender: "Select your gender",
    signUp: "Sign up",
    instructions: "Instructions:",
    insufficientPointsText: "Keep scanning tickets to accumulate more points",
    insufficientPointsTitle: "insufficient Saba points",
    pay: "Get for",
    scanTicketLabel: "Scan ticket",
    sabaRewardsTitleOne: 'Saba',
    sabaRewardsTitleTwo: 'rewards',
    buy: 'BUY',
    signInTitle: 'Verify phone',
    countryCodeLabel: 'Country Code',
    enterPhoneLabel: 'Enter your phone number',
    enterPhonePlaceholder: '10 digits',
    checkText: `I have read the`,
    checkTextTwo: `and accept`,
    privacyPolicy: 'privacy policy',
    termsConditions: 'terms and conditions',
    promiseTitle: 'We pledge to',
    promise1: 'Keep your personal data safe.',
    promise2: 'Never sell your personal data.',
    promise3: 'Never charge you without permission.',
    textHelp: 'Need help? Chat with us.',
    continueLabel: 'Continue',
    verificationTitle: 'Phone verification',
    messageSent: 'We just sent a code to:',
    enterCodeLabel: 'Enter 4 digit verification code',
    verifyButton: 'Verify code',
    connectLabel: 'Connect',
    orEnterID: 'Or enter the ID:',
    payments: 'Mobile top-ups',
    free: 'FREE',
    priceLabel: 'Get for',
    freeSample: 'Get free sample',
    buyFor: 'Buy for',
    rechargeLabel: 'Add funds for',
    connecting: 'Connecting to',
    communicationFailure: 'Communication failure',
    communicationFailureDescription:
      'The machine could not be contacted. Please come back later',
    userTimeout: 'User timeout',
    userTimeoutDescription:
      "Did you make your selection on the machine's keyboard?",
    retry: 'Retry',
    downloadSuccessful: 'Download Successful',
    retrieveProduct: 'Get your product from',
    credit: 'Credit',
    machineReady: 'Product delivery',
    makeSelection: 'Making your selection on',
    selectLabel: 'Select',
    onLabel: 'on',
    internalServerError: 'Internal Error',
    tryAgainLater: 'Please try again later',
    backLabel: 'Back',
    recents: 'Recently used',
    noRecents: 'No recently used machines',
    scan: 'Scan QR code',
    viewProfile: 'View Profile',
    topUpLabel: 'Add funds',
    touchlessKeyboard: 'TOUCHLESS',
    dealsAndProducts: 'DEALS',
    historyLabel: 'History',
    contactLabel: 'Contact us',
    myWalletsLabel: 'My wallets',
    paymentMethodsLabel: 'Payment methods',
    editLabel: 'Edit',
    logOut: 'Log Out',
    payCashless: 'Pay cashless',
    addThisAmount: 'Add this amount',
    usingCard: 'Using card',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    okLabel: 'OK',
    autoPayText1: 'Add this amount whenever my balance drops below ',
    autoPayText2: 'in the wallet',
    beforeTopUpMessageTitle: 'Top up',
    beforeTopUpMessageStart: 'Do you confirm you want to add',
    beforeTopUpMessageMiddle: 'to your wallet for purchases in',
    beforeTopUpMessageEnd: 'by debiting your card?',
    afterTopUpMessageTitle: 'Successful top up',
    afterTopUpMessageStart: 'Your new balance is',
    addPaymentMethod: 'Add new',
    deleteLabel: 'Delete',
    deleteCardTitle: 'Delete Card',
    deleteCardAlert: 'Are you sure you want to delete the card',
    cardNumberLabel: 'Card number',
    cvvLabel: 'CVV',
    nameOnCard: 'Name on card',
    expirationLabel: 'Expiration',
    saveNewCard: 'Save new card',
    cardAdded1: 'The card',
    cardAdded2: 'has been added correctly',
    outOfService: 'Machine out of service',
    machineNotFound: 'Machine not found',
    lastTransactionLabel: 'Last transaction',
    viewHistory: 'View history',
    enableQr: 'Scan the QR on the machine with your cell phone camera to continue.',
    moreOfNimanic: 'More about Nimanic',
    changeLanguage: 'En español',
    connectionLostToKeyboard: 'The session expired',
    reconnectToKeyboard: 'Click to reconnect',
    emailLabel: 'Email',
    saveLabel: 'Save',
    enterNamePlaceholder: 'Enter name',
    birthDatePlaceholder: 'DD/MM/YYYY',
    enterGenderPlaceholder: 'Select gender',
    enterEmailPlaceholder: 'Enter email',
    nameLabel: 'Name',
    birthDateLabel: 'Birth date',
    genderLabel: 'Gender',
    resendMailVerification: 'Resend email verification',
    delivering: 'Delivering selection, please wait...',
    dealStatus5: 'Product not delivered',
    dealStatus5Info: 'But don’t worry, we didn’t charge you. Please try again.',
    dealStatus6: 'Something went wrong',
    dealStatus6Info: 'The machine responded with an error. Please try again',
    dealStatus7: 'Machine in use',
    dealStatus7Info:
      'Please wait until the other transaction has finished and try again',
    dealStatus8: 'Operation canceled by user',
    dealStatus8Info: '',
    dealStatus9: 'Operation cancelled successfully',
    dealStatus9Info: '',
    dealStatus10: 'Something went wrong',
    dealStatus10Info: 'The machine reported an unknown error. Please try again',
    dealStatus11: 'Machine in use',
    dealStatus11Info: 'Please wait for other users to finish and try again',
    dealStatus12: 'Insufficient funds',
    dealStatus12Info:
      'Please increase your balance to make this purchase or select another product',
    dealStatus13: 'Audit in progress',
    dealStatus13Info:
      'Please come back in a few minutes when audit has finished',
    dealStatus14: 'Wrong selection',
    dealStatus14Info: 'Please make the indicated selection to get your product',
    dealStatus16: 'Closed credit',
    dealStatus16Info: "You've closed your credit on the machine",
    downloadApp: 'Download Nimanic App from the app store and play store',
    downloadLabel: 'Go to store',
    dontShowAgain: "Don't show again",
    selectPaymentMethod: 'Which payment method do you wish to add?'
  },
  es: {
    man: "Hombre",
    woman: "Mujer",
    other: "Otro",
    selectYourGender: "Selecciona tu género",
    signUp: "Regístrate",
    instructions: "Instrucciones:",
    insufficientPointsText: "Sigue escaneando tickets para acumular más puntos",
    insufficientPointsTitle: "Puntos Saba insuficientes",
    pay: "Obtener por",
    scanTicketLabel: "Escanear ticket",
    sabaRewardsTitleOne: 'Saba',
    sabaRewardsTitleTwo: 'recompensas',
    touchlessKeyboard: 'TECLADO TOUCHLESS',
    dealsAndProducts: 'OFERTAS Y PRODUCTOS',
    buy: 'COMPRA DIRECTA',
    signInTitle: 'Verifica tu celular',
    countryCodeLabel: 'Código de país',
    enterPhoneLabel: 'Ingresa tu número celular',
    enterPhonePlaceholder: '10 dígitos',
    checkText: `He leído el`,
    checkTextTwo: `y acepto los`,
    privacyPolicy: 'aviso de privacidad',
    termsConditions: 'téminos y condiciones',
    promiseTitle: 'Nuestra promesa',
    promise1: 'Mantendremos tus datos personales seguros.',
    promise2: 'Nunca venderemos tus datos a terceros.',
    promise3: 'No te haremos ningún cargo sin tu permiso.',
    textHelp: '¿Necesitas ayuda? Contáctanos aquí.',
    continueLabel: 'Continuar',
    verificationTitle: 'Verifica tu celular',
    messageSent: 'Te enviamos un mensaje de texto al número:',
    enterCodeLabel: 'Ingresa el código de 4 dígitos',
    verifyButton: 'Verificar código',
    connectLabel: 'Conectar',
    orEnterID: 'O escribe el ID:',
    payments: 'Recargas',
    free: 'GRATIS',
    priceLabel: 'Obtener por',
    freeSample: 'Obtener gratis',
    buyFor: 'Comprar x',
    rechargeLabel: 'Abonar x',
    connecting: 'Conectándote a',
    communicationFailure: 'Error de comunicación',
    communicationFailureDescription:
      'Error de comunicación con los servidores Nimanic. Por favor intenta más tarde',
    userTimeout: 'Tiempo expirado',
    userTimeoutDescription: '¿Hiciste tu selección en la máquina?',
    retry: 'Reintentar',
    downloadSuccessful: 'Descarga exitosa',
    retrieveProduct: 'Retira tu regalo de',
    credit: 'Crédito',
    machineReady: 'Máquina conectada',
    makeSelection: 'Haz tu selección en la máquina',
    selectLabel: 'Selecciona',
    onLabel: 'en',
    internalServerError: 'Error',
    tryAgainLater: 'Por favor intenta más tarde',
    backLabel: 'Regresar',
    recents: 'Recientes',
    noRecents: 'No tienes máquinas recientes',
    scan: 'Escanea código QR',
    scanTitle: 'Escanea el QR en la máquina”',
    viewProfile: 'Ver Perfil',
    topUpLabel: 'Abonar Saldo',
    historyLabel: 'Historial',
    contactLabel: 'Contáctanos',
    myWalletsLabel: 'Mis monederos',
    paymentMethodsLabel: 'Métodos de pago',
    editLabel: 'Editar',
    logOut: 'Cerrar sesión',
    payCashless: 'Paga sin efectivo',
    addThisAmount: 'Monto a agregar',
    usingCard: 'Usando la Tarjeta:',
    confirmLabel: 'Confirmar',
    cancelLabel: 'Cancelar',
    okLabel: 'OK',
    autoPayText1: 'Abonar esta cantidad si hay menos de ',
    autoPayText2: 'en la cartera',
    beforeTopUpMessageTitle: 'Abonar Saldo',
    beforeTopUpMessageStart: '¿Confirmas que deseas abonar',
    beforeTopUpMessageMiddle: 'a tu monedero para compras en',
    beforeTopUpMessageEnd: 'haciendo el cargo a tu tarjeta?',
    afterTopUpMessageTitle: 'Abono exitoso',
    afterTopUpMessageStart: 'Tu nuevo saldo es',
    addPaymentMethod: 'Agregar nuevo',
    deleteLabel: 'Borrar',
    deleteCardTitle: 'Borrar Tarjeta',
    deleteCardAlert: '¿Estás seguro que quieres borrar la tarjeta',
    cardNumberLabel: 'Número de tarjeta',
    cvvLabel: 'CVV',
    nameOnCard: 'Nombre como aparece en la tarjeta',
    expirationLabel: 'Fecha de expiración',
    saveNewCard: 'Guardar tarjeta',
    cardAdded1: 'La tarjeta',
    cardAdded2: 'ha sido añadida correctamente',
    outOfService: 'Máquina fuera de servicio',
    machineNotFound: 'Máquina no encontrada',
    lastTransactionLabel: 'Última transacción',
    viewHistory: 'Ver historial',
    enableQr: 'Escanea el QR en la máquina con la cámara de tu celular para continuar.',
    moreOfNimanic: 'Más sobre Nimanic',
    changeLanguage: 'To english',
    connectionLostToKeyboard: 'Tu sesión expiró',
    reconnectToKeyboard: 'Da clic para reconectar',
    emailLabel: 'Correo electrónico',
    saveLabel: 'Guardar',
    enterNamePlaceholder: 'Nombre',
    birthDatePlaceholder: 'DD/MM/YYYY',
    enterGenderPlaceholder: 'Género',
    enterEmailPlaceholder: 'Email',
    nameLabel: 'Nombre',
    birthDateLabel: 'Fecha de nacimiento',
    genderLabel: 'Género',
    resendMailVerification: 'Reenviar verificación de email',
    delivering: 'Entregando selección, espera un momento',
    dealStatus5: 'El producto no cayó',
    dealStatus5Info:
      'No te preocupes, no te cobramos nada. Intenta otra vez o escoge otro producto.',
    dealStatus6: 'Algo salió mal :(',
    dealStatus6Info:
      'La máquina respondió con un error. Por favor intenta otra vez.',
    dealStatus7: 'La máquina está en uso…',
    dealStatus7Info: 'Espera a que termine la transacción e intenta otra vez.',
    dealStatus8: 'Operación cancelada por el usuario',
    dealStatus8Info: '',
    dealStatus9: 'Operación cancelada',
    dealStatus9Info: '',
    dealStatus10: 'Algo salió mal :(',
    dealStatus10Info:
      'La máquina respondió con un error. Por favor intenta otra vez.',
    dealStatus11: 'La máquina esta en uso',
    dealStatus11Info: 'Espera a que termine la transacción e intenta otra vez.',
    // dealStatus12: 'Fondos insuficientes',
    // dealStatus12Info:
    //   'Por favor abona más saldo a tu cuenta para hacer la compra.',
    dealStatus12: 'Puntos Saba insuficientes',
    dealStatus12Info:
      'Sigue escaneando tickets para acumular mas puntos',
    dealStatus13: 'Auditoría en progreso',
    dealStatus13Info: 'Por favor regresa en unos minutos.',
    dealStatus14: 'Selección incorrecta',
    dealStatus14Info:
      'Por favor haz la selección correcta para obtener tu producto.',
    dealStatus16: 'Crédito cerrado',
    dealStatus16Info: 'Has cerrado tu crédito en la máquina vending',
    downloadApp:
      'Descarga la aplicación de Nimanic desde la App Store y Play Store',
    downloadLabel: 'Ir a tienda',
    dontShowAgain: 'No mostrar',
    selectPaymentMethod: '¿Qué tipo de método de pago desea agregar?'
  }
});

export default strings;
