import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number } from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import styles from '../../../styles';
import strings from '../../../language';
import PrimaryButton from '../../Button/PrimaryButton';

class ConnectionErrorPage extends Component {
  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  retry = () => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      authToken,
      selectedVendingMachineId,
      selectedProduct,
      language,
      saleType
    } = this.props;
    const origin = 'web';
    const selectedProductUserCode = selectedProduct.user_code;
    const anyProduct = saleType === 2;
    actionsFromVendingMachine.createNewDeal(
      authToken,
      selectedVendingMachineId,
      selectedProductUserCode,
      saleType,
      origin,
      anyProduct,
      language,
      this.goTo
    );
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, deliveryStatus, deal } = this.props;
    console.log('deliveryStatus', deliveryStatus);
    console.log('deal', deal);
    const {
      communicationFailure,
      communicationFailureDescription,
      userTimeout,
      userTimeoutDescription,
      retry,
      dealStatus5,
      dealStatus5Info,
      dealStatus6,
      dealStatus6Info,
      dealStatus7,
      dealStatus7Info,
      dealStatus8,
      dealStatus8Info,
      dealStatus9,
      dealStatus9Info,
      dealStatus10,
      dealStatus10Info,
      dealStatus11,
      dealStatus11Info,
      dealStatus12,
      dealStatus12Info,
      dealStatus13,
      dealStatus13Info,
      dealStatus14,
      dealStatus14Info,
      dealStatus16,
      dealStatus16Info
    } = strings;
    const statusMessages = {
      1: {
        title: communicationFailure,
        description: communicationFailureDescription
      },
      2: {
        title: userTimeout,
        description: userTimeoutDescription
      },
      3: {
        title: userTimeout,
        description: userTimeoutDescription
      },
      4: {
        title: communicationFailure,
        description: communicationFailureDescription
      },
      5: {
        title: dealStatus5,
        description: dealStatus5Info
      },
      6: {
        title: dealStatus6,
        description: dealStatus6Info
      },
      7: {
        title: dealStatus7,
        description: dealStatus7Info
      },
      8: {
        title: dealStatus8,
        description: dealStatus8Info
      },
      9: {
        title: dealStatus9,
        description: dealStatus9Info
      },
      10: {
        title: dealStatus10,
        description: dealStatus10Info
      },
      11: {
        title: dealStatus11,
        description: dealStatus11Info
      },
      12: {
        title: dealStatus12,
        description: dealStatus12Info
      },
      13: {
        title: dealStatus13,
        description: dealStatus13Info
      },
      14: {
        title: dealStatus14,
        description: dealStatus14Info
      },
      16: {
        title: dealStatus16,
        description: dealStatus16Info
      }
    };
    if (deliveryStatus === null) {
      return null;
    }
    return (
      <Grid
        className={classes.containerWithHeader}
        container
        justify="flex-start"
        alignItems="flex-start"
      >
        <Header
          leftContent={<ChevronLeft />}
          leftAction={() => this.goTo('/machine-inventory')}
          type="secondary"
        />
        <Grid
          container
          className={classes.bodyWithHeaderContainer}
          justify="center"
          alignItems="center"
          direction="column"
          wrap="nowrap"
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <h1 className={classes.titleSecColor}>
              {statusMessages[deliveryStatus].title}
            </h1>
            <p
              className={classnames(classes.bodyDefaultText, classes.centered)}
            >
              {statusMessages[deliveryStatus].description}
            </p>
            <Grid container className={classes.mediumButtonContainer}>
              <PrimaryButton
                color="primary"
                variant="contained"
                onClick={this.retry}
              >
                {retry}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ConnectionErrorPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  selectedProduct: shape({}).isRequired,
  language: string.isRequired,
  authToken: string.isRequired,
  selectedVendingMachineId: number,
  deliveryStatus: number,
  saleType: number,
  deal: shape({}).isRequired
};

ConnectionErrorPage.defaultProps = {
  selectedVendingMachineId: null,
  deliveryStatus: null,
  saleType: null
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    selectedVendingMachine,
    deal,
    selectedProduct
  } = state.vendingMachine;
  const {
    delivery_status: deliveryStatus,
    payment_type: { code: saleType } = {}
  } = deal;
  const {
    id: selectedVendingMachineId,
    connect_to_machine_timeout: connnectToMachineTimeout
  } = selectedVendingMachine;
  const { language } = state.language;
  return {
    authToken,
    communityMemberId,
    selectedVendingMachineId,
    connnectToMachineTimeout,
    deliveryStatus,
    deal,
    saleType,
    selectedProduct,
    language
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConnectionErrorPage));
