import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool } from 'prop-types';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import classnames from 'classnames';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import sessionActions from '../../../actions/session';
import languageActions from '../../../actions/language';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import Button from '../../Button';
import styles from '../../../styles';
import strings from '../../../language';
import InstructionsModal from '../../Modals/InstructionsModal';
import ScanTicketModal from '../../Modals/ScanTicketModal';

const nimanicLogo = require('../../../assets/logo-nimanic.png');
const nestleLogo = require('../../../assets/Nescafe-Logo.png');
const automateLogo = require('../../../assets/automate-iq-logo.png');
const sabaLogo = require('../../../assets/Saba_logo_02.png');

const countryCodeMenuOptions = [
  { menuOption: '+52 México', optionValue: '+52' },
  { menuOption: '+31 Netherlands', optionValue: '+31' },
  { menuOption: '+1 United States', optionValue: '+1' },
  { menuOption: '+972 Israel', optionValue: '+972' },
  { menuOption: '+81 日本', optionValue: '+81' },
  { menuOption: '+++', optionValue: '+++' }
];

const privacyPolicyLink =
  'https://nimanic.com/aviso-de-privacidad/?plain_text=true';

const termsAndConditionsLink =
  'https://nimanic.com/terminos-y-condiciones/';

const nimanicLink = 'http://nimanic.com/about';

const chatLink = 'https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const { country, phone } = props;
    this.state = {
      country,
      phone,
      privacyCheck: false,
      phoneError: false,
      countryError: false,
      privacyCheckError: false,
      instructionsModal: true,
      scanTicketModal: false,
    };
  }

  componentDidMount() {
    const {
      communityMemberId,
      authToken,
      selectedVendingMachineId
    } = this.props;
    if (communityMemberId && authToken && selectedVendingMachineId) {
      this.goTo('/machine-inventory');
    }
    if (communityMemberId && authToken) {
      this.goTo('/machine-selection');
    }
    this.setState({ privacyCheck: false });
  }

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  handleFocus = (id) => {
    this.setState({ [`${id}Error`]: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { sessionActions: actionsFromSession } = this.props;
    const { country, phone, privacyCheck } = this.state;
    if (phone.length === 10 && country !== '+++' && privacyCheck) {
      actionsFromSession.getVerificationCode(phone, country, this.goTo);
    } else {
      if (!phone || phone.length < 10) {
        this.setState({ phoneError: true });
      }
      if (country === '+++') {
        this.setState({ countryError: true });
      }
      if (!privacyCheck) {
        this.setState({ privacyCheckError: true });
      }
    }
  };

  closeInsModal = () => {
    sessionStorage.setItem('instructionsModalState', 'true');
    this.setState({ instructionsModal: false });
  };

  closeScanModal = () => {
    this.setState({ scanTicketModal: false });
  }

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      privacyCheck: !prevState.privacyCheck,
      privacyCheckError: false
    }));
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  changeLanguage = () => {
    const { languageActions: actionsFromLanguage, language } = this.props;
    const newLanguage = language === 'es' ? 'en' : 'es';
    strings.setLanguage(newLanguage);
    actionsFromLanguage.setLanguage(newLanguage);
  }

  render() {
    const { classes, loading, language } = this.props;
    const {
      signInTitle,
      sabaRewardsTitleOne,
      sabaRewardsTitleTwo,
      countryCodeLabel,
      enterPhoneLabel,
      enterPhonePlaceholder,
      checkText,
      checkTextTwo,
      privacyPolicy,
      termsConditions,
      promiseTitle,
      promise1,
      promise2,
      promise3,
      textHelp,
      continueLabel,
      moreOfNimanic,
      changeLanguage
    } = strings;
    const {
      country,
      phone,
      privacyCheck,
      phoneError,
      countryError,
      privacyCheckError,
      instructionsModal,
      scanTicketModal,
    } = this.state;
    const checkBoxClass = privacyCheckError
      ? { root: classes.checkBoxRootError }
      : { root: classes.checkBoxRoot };
    const phoneClass = phoneError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const countryClass = countryError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <InstructionsModal
          instructionsModal={instructionsModal}
          closeInstructionsModal={this.closeInsModal}
        />

        <ScanTicketModal
          scanTicketModal={scanTicketModal}
          closeScanTicketModal={this.closeScanModal}
        />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          wrap="nowrap"
          className={classes.mainContainer}
        >
          <div className={classes.loginSupContainer}>
            <img src={sabaLogo} alt="Nimanic" className={classes.sabaLogo} />
            <h1 className={classes.sabaTitleOne}>{sabaRewardsTitleOne}&reg; <span className={classes.sabaTitleTwo}>{sabaRewardsTitleTwo}</span></h1>
            <h1 className={classes.sabaTitle}>{signInTitle}</h1>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              wrap="nowrap"
              className={classes.smContentContainer}
            >
              <div className={classes.width100Container}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <p className={classes.countryCodeLabel}>{countryCodeLabel}</p>
                  <p className={classes.phoneLabel}>{enterPhoneLabel}</p>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                  <FormControl className={classes.countryCodeInput}>
                    <Select
                      value={country}
                      onChange={(e) => this.handleChange(e, 'country')}
                      onFocus={() => this.handleFocus('country')}
                      error={countryError}
                      input={<CustomSelect />}
                      classes={countryClass}
                    >
                      {countryCodeMenuOptions.map(({ menuOption, optionValue }) => (
                        <MenuItem key={optionValue} value={optionValue}>
                          {menuOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.phoneInput}>
                    <CustomInput
                      placeholder={enterPhonePlaceholder}
                      value={phone}
                      onChange={(e) => this.handleChange(e, 'phone')}
                      onFocus={() => this.handleFocus('phone')}
                      error={phoneError}
                      className={classes.select}
                      classes={phoneClass}
                    />
                  </FormControl>
                </Grid>
              </div>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.checkContainer}
              >
                <Checkbox
                  value={privacyCheck}
                  color="primary"
                  onChange={this.toggleCheckbox}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircleOutline />}
                  classes={checkBoxClass}
                />
                <p className={classnames(classes.bodySmallText, classes.checkText)}>
                  {`${checkText} `}
                  <a
                    href={privacyPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.bodySmallLink}
                  >
                    {privacyPolicy}
                  </a>
                  {` `}
                  {`${checkTextTwo}`}
                  {` `}
                  <a
                    href={termsAndConditionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.bodySmallLink}
                  >
                    {termsConditions}
                  </a>
                </p>
              </Grid>
              <Button size="md" type="primary" action={this.handleSubmit}>
                {continueLabel}
              </Button>

              {/* <Grid className={classes.promisesText}>
            <h2 className={classes.bodyDefaultText}>{promiseTitle}</h2>
            <p className={classnames(classes.bodySmallText, classes.noMargin)}>
              {promise1}
            </p>
            <p className={classnames(classes.bodySmallText, classes.noMargin)}>
              {promise2}
            </p>
            <p className={classnames(classes.bodySmallText, classes.noMargin)}>
              {promise3}
            </p>
          </Grid> */}
              {/* <p className={classnames(classes.bodyDefaultText, classes.centered)}>
            <a
              href={nimanicLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.bodyDefaultLink}
            >
              {moreOfNimanic}
            </a>
          </p> */}
              {/* <p
            className={classnames(
              classes.bodyDefaultText,
              classes.centered,
              classes.bodyDefaultLink
            )}
            onClick={this.changeLanguage}
          >
            {changeLanguage}
          </p> */}
            </Grid>
          </div>
          <div className={classes.loginContactContainer}>
            <p className={classnames(classes.bodyDefaultText, classes.centered)}>
              <a
                href={chatLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.bodyDefaultLink}
              >
                {textHelp}
              </a>
            </p>
          </div>
        </Grid>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  country: string.isRequired,
  phone: string.isRequired,
  history: shape({}).isRequired,
  authToken: string,
  communityMemberId: number,
  selectedVendingMachineId: number,
  loading: bool.isRequired,
  language: string,
  languageActions: shape({}).isRequired
};

LoginPage.defaultProps = {
  authToken: '',
  communityMemberId: null,
  selectedVendingMachineId: null,
  language: ''
};

const mapStateToProps = (state) => {
  const {
    country,
    phone,
    communityMember = {},
    loading: sessionLoading
  } = state.session;
  const { language } = state.language;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    selectedVendingMachine,
    loading: vendingMachineLoading
  } = state.vendingMachine;
  const { id: selectedVendingMachineId } = selectedVendingMachine;
  const loading = sessionLoading || vendingMachineLoading;
  return {
    country,
    phone,
    communityMemberId,
    authToken,
    selectedVendingMachineId,
    loading,
    language
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  languageActions: bindActionCreators(languageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage));
