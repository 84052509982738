import { Button } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const PrimaryButton = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      textTransform: 'none',
      fontWeight: '400',
      fontSize: '1rem',
      padding: '6px 12px',
      border: '2px solid #DF1995',
      borderRadius: 5,
      backgroundColor: '#ffffff',
      color: '#4A4A4A',
      outline: 'none',
      '&:hover': {
        backgroundColor: '#ffffff'
      },
      '&:active': {
        backgroundColor: '#ffffff',
        outline: 'none'
      },
      '&:focus': {
        backgroundColor: '#ffffff',
        outline: 'none'
      }
    }
  })
)(Button);

export default PrimaryButton;
