import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';

const exampleImage = require('../../../assets/photoExampleTwo.png');

const SecondPhoto = ({
    classes,
    closeAction,
    buttonAction,
}) => {

    return (
        <div
            className={classes.generalContainer}
        >
            <div className={classes.width100}>
                <HeaderSaba
                    closeAction={closeAction}
                    title={"Tips para tu foto"}
                />
            </div>
            <ol style={{ maxWidth: 320 }}>
                <li>Coloca tu ticket en una superficie plana.</li>
                <li>Toma la foto enfocando el centro de tu ticket.</li>
                <li>Asegúrate de tomar el ticket completo.</li>
            </ol>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p className={classes.generalTextSabaSmall} style={{ fontWeight: 'bold' }}>Desde el logo de Walmart</p>
                <p className={classes.generalTextSabaSmall} style={{ fontWeight: 'bold' }}>hasta el código de barras</p>
            </div>
            <div style={{ marginTop: 20 }}>
                <img src={exampleImage} alt="ejemplo fotografía" className={classes.processingImageStyle} />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 60 }}>
                <Button size="md" type="primary" action={buttonAction}>
                    Tomar foto
                </Button>
            </div>
        </div>
    );
};

SecondPhoto.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    buttonAction: func,
};

SecondPhoto.defaultProps = {
    closeAction: () => { },
    buttonAction: () => { },
};

export default withStyles(styles)(SecondPhoto);