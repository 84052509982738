import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool, arrayOf, element } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import sessionActions from '../../../actions/session';
import vendingMachineActions from '../../../actions/vendingMachine';
import styles from '../../../styles';
import strings from '../../../language';
import BarcodeScan from '../../SabaPointsSteps/BarcodeScan';
import PhotoTakeExample from '../../SabaPointsSteps/PhotoTakeExample';
import TakePhoto from '../../SabaPointsSteps/TakePhoto';
import SendPhoto from '../../SabaPointsSteps/SendPhoto';
import Processing from '../../SabaPointsSteps/Processing';
import PointsObtained from '../../SabaPointsSteps/PointsObtained';
import TicketNoDetected from '../../SabaPointsSteps/TicketNoDetected';
import ticketsActions from '../../../actions/tickets';
import BarCodeScanTwo from '../../SabaPointsSteps/BarCodeScanTwo';
import TakePhotoTwo from '../../SabaPointsSteps/TakePhotoTwo';
import SendRevisionRequestModal from '../../Modals/SendRevisionRequestModal';
import Selfie from '../../SabaPointsSteps/GetUserMedia';
import CaptureProcessModal from '../../Modals/CaptureProcessModal';
import SecondPhoto from '../../SabaPointsSteps/SecondPhoto';

class TicketScan extends Component {
  constructor() {
    super();
    this.state = {
      stateWindow: "step2",
      barCode: "",
      stopStream: false,
      image: null,
      imageFile: null,
      revisionModal: false,
      errorDetected: false,
      ticketStatus: "",
      errorStatus: 0,
      revisionStatus: "",
      isLoading: false,
      showCameraButton: false,
      captureModal: false,
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = async () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ image: imageSrc });
    const url = imageSrc;
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "Ticket photo", { type: "image/png" })
        this.setState({ imageFile: file, stateWindow: "step4" });
      })
  };

  captureTwo = async (photoResponse) => {
    if (photoResponse) {
      const imageSrc = photoResponse;
      this.setState({ image: imageSrc });
      // console.log("photo", photoResponse);
      const url = imageSrc;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "Ticket photo", { type: "image/png" })
          this.setState({ imageFile: file, stateWindow: "step4" });
        })
    }
  };

  componentDidMount() {
    const {
      authToken,
      communityMemberId,
      sessionActions: actionsFromSession,
      vendingMachineActions: actionsFromVendingMachine,
      selectedVendingMachineId,
    } = this.props;
    if (!authToken) {
      this.goTo('/');
    } else {
      actionsFromSession.getDealsHistory(communityMemberId, authToken);
      actionsFromVendingMachine.getVendingMachineItems(
        selectedVendingMachineId
      );
    }
  }

  videoEle = React.createRef();
  canvasEle = React.createRef();

  startCamera = async () => {
    try {

      // let features = {
      //   video: {
      //     width: { ideal: 4096 },
      //     height: { ideal: 2160 },
      //     facingMode: "environment",
      //   }
      // };

      // let display = await navigator.mediaDevices.getUserMedia(features);
      // let settings = display.getVideoTracks()[0].getSettings();
      // let width = settings.width;
      // let height = settings.height;

      // console.log("settings", settings);

      // let stream = await navigator.mediaDevices.getUserMedia({
      //   video: {
      //     facingMode: "environment",
      //     width: width,
      //     height: height
      //   }
      // });

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
          width: { ideal: 4096 },
          height: { ideal: 2160 },
        }
      });

      if (this.videoEle.current) {
        this.videoEle.current.srcObject = stream;
        this.setState({ showCameraButton: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  takePhotoAndroid = () => {
    this.setState({ showCameraButton: false });
    this.setState({ captureModal: true });
    setTimeout(() => {
      this.takeCapture();
    }, 500);
  }

  takeCapture = async () => {

    // Get the exact size of the video element.
    const width = this.videoEle.current.videoWidth;
    const height = this.videoEle.current.videoHeight;

    // get the context object of hidden canvas
    const ctx = this.canvasEle.current.getContext("2d");

    // Set the canvas to the same dimensions as the video.
    this.canvasEle.current.width = width;
    this.canvasEle.current.height = height;

    // Draw the current frame from the video on the canvas.
    ctx.drawImage(this.videoEle.current, 0, 0, width, height);

    // Get an image dataURL from the canvas.
    // const imageDataURL = this.canvasEle.current.toDataURL("image/png");
    const imageDataURL = this.canvasEle.current.toDataURL("image/jpg");
    this.stopCam();

    const imageSrc = imageDataURL;
    this.setState({ image: imageSrc });
    // console.log("photo", photoResponse);
    const url = imageSrc;
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "Ticket photo", { type: "image/jpg" })
        this.setState({ captureModal: false });
        this.setState({ imageFile: file, stateWindow: "step4" });
      })
      console.log("url", url);
  };

  stopCam = () => {
    const stream = this.videoEle.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  closeRevisionModal = () => {
    setTimeout(() => {
      this.setState({ revisionModal: false });
      this.goTo('/machine-inventory');
    }, 3000);

  };

  closeRevisionModalError = () => {
    setTimeout(() => {
      this.setState({ revisionModal: false });
    }, 3000);
  };

  onScanBarcode = (result) => {
    if (result) {
      const { ticketsActions: actionsFromTickets, selectedVendingMachineId, authToken } = this.props;
      actionsFromTickets.verifyTicketBarcode(
        authToken,
        result,
        selectedVendingMachineId,
        () => {
          this.setState({ barCode: result, stopStream: true, stateWindow: "step2" })
        });
      ;
    }
  };

  onSendTicketPhoto = () => {
    const { ticketsActions: actionsFromTickets, authToken, selectedVendingMachineId, sessionActions: actionsFromSession, communityMemberId } = this.props;
    const { imageFile } = this.state;
    console.log("selectedVendigMachineId", selectedVendingMachineId);
    actionsFromTickets.sendTicketPhoto(
      authToken,
      selectedVendingMachineId,
      imageFile,
      (ticketStatus) => {
        this.setState({ ticketStatus });
        this.setState({ stateWindow: "step6" });
        console.log("authToken", authToken);
        console.log("communityMemberId", communityMemberId);
        actionsFromSession.getMemberBalance(
          communityMemberId,
          authToken,
        );
      },
      (ticketStatus, errorStatus) => {
        this.setState({ ticketStatus });
        this.setState({ errorStatus });
        this.setState({ stateWindow: "step7" });
      }
    );
  }

  onSendRevisionPhoto = () => {
    const { ticketsActions: actionsFromTickets, authToken, selectedVendingMachineId } = this.props;
    const { imageFile } = this.state;
    actionsFromTickets.sendRevisionPhoto(
      authToken,
      selectedVendingMachineId,
      imageFile,
      (revisionStatus) => {
        this.setState({ revisionStatus });
        this.revisionPhoto();
      },
      () => {
        this.setState({ errorDetected: true });
        this.revisionPhotoError();
      }
    );
  }

  revisionPhoto = () => {
    this.setState({ revisionModal: true });
    this.closeRevisionModal();
  }

  revisionPhotoError = () => {
    this.setState({ revisionModal: true });
    this.closeRevisionModalError();
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  render() {
    const { classes, language, deals, loading, ticket, error, processingPhoto, isLoading, newBalance, balances } = this.props;
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    const { stateWindow, barCode, stopStream, image, imageFile, errorDetected, revisionModal, revisionStatus, ticketStatus, errorStatus, showCameraButton, captureModal } = this.state;

    if (processingPhoto) {
      return (
        <Processing />
      );
    }

    console.log("image", image);

    return (
      <>
        <SendRevisionRequestModal
          revisionModal={revisionModal}
          errorDetected={errorDetected}
        />
        <CaptureProcessModal
          captureModal={captureModal}
        />
        {stateWindow === "step1" && (
          <BarCodeScanTwo
            data={barCode}
            closeAction={() => {
              this.setState({ stopStream: true })
              this.goTo('/machine-inventory')
            }}
            setData={this.onScanBarcode}
            stopStream={stopStream}
          />
        )}
        {stateWindow === "step2" && (
          <PhotoTakeExample
            closeAction={() => this.goTo('/machine-inventory')}
            buttonAction={() => this.setState({ stateWindow: "step3" })}
          />
        )}

        {/* {stateWindow === "step3" ?
          (this.getMobileOperatingSystem() === "Android" ? (
            <Selfie
              refFunc={this.videoEle}
              savePhoto={this.takePhotoAndroid}
              startFunction={this.startCamera}
              canvasRef={this.canvasEle}
              showButton={showCameraButton}
            />
          ) : (
            <TakePhotoTwo
              handleTakePhoto={this.captureTwo}
            />
          )) : null
        } */}
        {stateWindow === "step3" &&
          <TakePhotoTwo
          handleTakePhoto={this.captureTwo}
        />
        }
        {/* {stateWindow === "step3" &&
          <Selfie
            refFunc={this.videoEle}
            savePhoto={this.takePhotoAndroid}
            startFunction={this.startCamera}
            canvasRef={this.canvasEle}
            showButton={showCameraButton}
          />
        } */}
        {stateWindow === "step4" && (
          <SendPhoto
            closeAction={() => this.goTo('/machine-inventory')}
            sendButtonAction={this.onSendTicketPhoto}
            takeButtonAction={() => this.setState({ stateWindow: "step3" })}
            photoImage={image}
          />
        )}
        {stateWindow === "step5" && (
          <Processing />
        )}
        {stateWindow === "step6" && (
          <PointsObtained
            closeAction={() => this.goTo('/machine-inventory')}
            buttonAction={() => this.goTo('/machine-inventory')}
            pointsObtained={ticket.points}
          />
        )}
        {stateWindow === "step7" && (
          <TicketNoDetected
            closeAction={() => this.goTo('/machine-inventory')}
            takeButtonAction={() => this.setState({ stateWindow: "step8" })}
            manualButtonAction={this.onSendRevisionPhoto}
            title={ticketStatus}
            errorStatus={errorStatus}
            isLoading={isLoading ? <CircularProgress className={classes.primaryLoading} /> : 'Revisión manual'}
          />
        )}
        {stateWindow === "step8" && (
          <SecondPhoto
            closeAction={() => this.goTo('/machine-inventory')}
            buttonAction={() => this.setState({ stateWindow: "step3" })}
          />
        )}
      </>
    );
  }
}

TicketScan.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  language: string.isRequired,
  authToken: string,
  loading: bool.isRequired,
  communityMemberId: number,
  deals: arrayOf(shape({})).isRequired,
  ticketsActions: shape({}).isRequired,
  ticket: shape({}).isRequired,
  error: string,
  processingPhoto: bool,
  isLoading: string | element,
  selectedVendingMachineId: number,
  newBalance: number.isRequired,
  balance: number,
  balances: arrayOf(shape({})).isRequired,
};

TicketScan.defaultProps = {
  authToken: '',
  communityMemberId: null,
  ticket: {},
  error: "",
  processingPhoto: false,
  isLoading: string,
  selectedVendingMachineId: null,
  balance: 0,
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { communityMember, loading, deals, newBalance, balances } = state.session;
  const { auth_token: authToken, id: communityMemberId, balance: cmBalance } = communityMember;
  const { ticket, error, processingPhoto, isLoading } = state.tickets;
  const {
    selectedVendingMachine,
  } = state.vendingMachine;
  const {
    id: selectedVendingMachineId,
  } = selectedVendingMachine;
  let balance = cmBalance;
  if (newBalance) {
    balance = newBalance;
  }
  return {
    language,
    authToken,
    loading,
    communityMemberId,
    deals,
    ticket,
    error,
    balance,
    newBalance,
    processingPhoto,
    isLoading,
    selectedVendingMachineId,
    balances
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch),
  ticketsActions: bindActionCreators(ticketsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TicketScan));