import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';

const ticketPhotoExample = require('../../../assets/ticketPhotoExample.png');

const SendPhoto = ({
    classes,
    closeAction,
    sendButtonAction,
    takeButtonAction,
    photoImage,
}) => {

    return (
        // <div
        //     className={classes.generalContainerScanTicket}
        // >
        //     <div className={classes.width100}>
        //         <HeaderSaba
        //             closeAction={closeAction}
        //             title={"Escanea un ticket"}
        //         />
        //     </div>
        //     <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
        //         <img src={photoImage} alt="fotografía tomada" className={classes.ticketPhotoStyle} />
        //     </div>
        //     <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
        //         <Button size="md" type="primary" action={sendButtonAction}>
        //             Enviar foto
        //         </Button>
        //         <Button size="md" type="outlined" action={takeButtonAction}>
        //             Volver a tomar
        //         </Button>
        //     </div>
        // </div>
        <div style={{ width: '100%', height: '100%', backgroundColor: 'black' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={photoImage} alt="fotografía" style={{ maxWidth: '100%', maxHeight: '100%', position: 'absolute' }} />
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', position: 'relative', left: 0, bottom: 0 }}>
                    <HeaderSaba
                        closeAction={closeAction}
                        title={""}
                    />
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <Button size="md" type="primary" action={sendButtonAction}>
                            Enviar foto
                        </Button>
                        <Button size="md" type="outlined" action={takeButtonAction}>
                            Volver a tomar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

SendPhoto.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    sendButtonAction: func,
    takeButtonAction: func,
    photoImage: string,
};

SendPhoto.defaultProps = {
    closeAction: () => { },
    sendButtonAction: () => { },
    takeButtonAction: () => { },
    photoImage: ""
};

export default withStyles(styles)(SendPhoto);