import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomInput = withStyles((theme) =>
  createStyles({
    root: {
      borderBottom: '2px solid #DF1995'
    },
    input: {
      position: 'relative',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: 16,
      fontWeight: 400,
      textAlign: 'center',
      padding: '10px 12px',
      transition: theme.transitions.create(['background-color']),
      '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      }
    }
  })
)(InputBase);

export default CustomInput;