import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, number, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';
import classnames from 'classnames';
import Header from '../Header';
import Clear from '@material-ui/icons/Clear';
import Menu from '@material-ui/icons/Menu';

const sabaLogo = require('../../assets/Saba_logo_02.png');

const chatLink = 'https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm';

const ScanTicketModal = ({
  classes,
  scanTicketModal,
  closeScanTicketModal,
  scanTicketModalGoTo,
  newBalance
}) => {
  const {
    afterTopUpMessageTitle,
    afterTopUpMessageStart,
    confirmLabel,
    signInTitle,
    sabaRewardsTitleOne,
    sabaRewardsTitleTwo,
    instructions,
    textHelp,
  } = strings;
  return (
    <Modal open={scanTicketModal}>
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        className={classes.containerScanTicketModal}
      >
        {/* <div className={classes.closeModal}>
          <div>
            <Clear />
          </div>
        </div> */}
        {/* <img
          src={sabaLogo}
          alt="Nimanic"
          className={classes.nimanicLogo}
        /> */}
        {/* <h1 className={classes.sabaTitleOne}>{sabaRewardsTitleOne}&reg; <span className={classes.sabaTitleTwo}>{sabaRewardsTitleTwo}</span></h1> */}
        <h1 className={classes.scanTicketModalTitle}>¿Quieres escanear un ticket nuevo?</h1>
        <div>
          <Button size="lg" type="primary" action={scanTicketModalGoTo}>
            Escanear
          </Button>
          <Button size="lg" type="outlined" action={closeScanTicketModal}>
            Continuar sin escanear
          </Button>
        </div>
        <div className={classes.texScanModal}>
          <p className={classes.textScanModalMenu}>Siempre podrás escanear un ticket</p>
          <div className={classes.textScanModalMenuTwo}>
            <p className={classes.textScanModalMenu}>nuevo entrando al menú</p>
            <Menu className={classes.menuIconModal} />
          </div>
          <p className={classes.textScanModalMenu} style={{ marginTop: 4, marginBottom: 6 }}>ó</p>
          <p className={classes.textScanModalMenu}>presionando el botón <span className={classes.textScanModalSpan}>"Escanear ticket"</span></p>
          <p className={classes.textScanModalMenuFontSmall}>{`(esquina inferior derecha de tu pantalla)`}</p>
        </div>
        {/* <h1 className={classes.titledUnderlined}>{instructions}</h1> */}
        {/* <ol>
            <li className={classes.bodyDefaultText}>Verifica tu celular</li>
            <li className={classes.bodyDefaultText}>Regístrate</li>
            <li className={classes.bodyDefaultText}>Escanea tu ticket y obtén puntos</li>
            <li className={classes.bodyDefaultText}>Canjea tus puntos por regalos</li>
          </ol> */}
        {/* <Grid
          item
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="md" type="primary" action={closeScanTicketModal}>
            Comenzar
          </Button>
        </Grid> */}
        {/* <div className={classes.loginContactContainer}>
          <p className={classnames(classes.bodyDefaultText, classes.centered)}>
            <a
              href={chatLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.bodyDefaultLink}
            >
              {textHelp}
            </a>
          </p>
        </div> */}
      </Grid>
    </Modal>
  );
};

ScanTicketModal.propTypes = {
  classes: shape({}).isRequired,
  balanceRechargedModal: bool.isRequired,
  closeBalanceRechargedModal: func.isRequired,
  newBalance: number.isRequired
};

const mapStateoProps = (state) => {
  const { newBalance } = state.session;
  return {
    newBalance
  };
};

export default connect(
  mapStateoProps,
  null
)(withStyles(styles)(ScanTicketModal));