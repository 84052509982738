import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';
import Webcam from "react-webcam";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const ticketPhotoExample = require('../../../assets/ticketPhotoExample.png');

const TakePhoto = ({
    classes,
    refFunc,
    savePhoto,
}) => {

    return (
        <div
            className={classes.mainPhotoContainer}
        >
            <div className={classes.photoComponentContainer}>
                <Webcam
                    audio={false}
                    ref={refFunc}
                    screenshotFormat="image/png"
                    width="100%"
                    height={"100%"}
                    // className="webcam-style"
                    videoConstraints={{
                        facingMode: "environment",
                    }}
                    forceScreenshotSourceSize={true}
                    // screenshotQuality={1}
                />
            </div>
            <div className={classes.cameraIconContainer} onClick={savePhoto}>
                <PhotoCameraIcon className={classes.cameraIcon} />
            </div>
        </div>
    );
};

TakePhoto.propTypes = {
    classes: shape({}).isRequired,
    refFunc: func,
    savePhoto: func,
};

TakePhoto.defaultProps = {
    refFunc: () => { },
    savePhoto: () => { }
};

export default withStyles(styles)(TakePhoto);