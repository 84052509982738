import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, arrayOf, bool, string, number } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import sessionActions from '../../../actions/session';
import Header from '../../Header';
import ConfirmDeleteCardModal from '../../Modals/ConfirmDeleteCardModal';
import Button from '../../Button';
import strings from '../../../language';
import styles from '../../../styles';

const americanExpress = require('../../../assets/american-express-logo.png');
const masterCard = require('../../../assets/mastercard-logo.png');
const visa = require('../../../assets/visa-logo.png');
const edenred = require('../../../assets/edenred.png');

class PaymentMethodsPage extends Component {
  constructor() {
    super();
    this.state = {
      deletePaymentMethodModal: false,
      selectedPaymentMethodId: null,
      selectedPaymentMethod: ''
    };
  }

  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  deleteCard = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId
    } = this.props;
    const { selectedPaymentMethodId, selectedPaymentMethod } = this.state;
    this.setState({
      deletePaymentMethodModal: false,
      selectedPaymentMethodId: null,
      selectedPaymentMethod: ''
    });
    actionsFromSession.deletePaymentMethod(
      communityMemberId,
      authToken,
      selectedPaymentMethodId,
      selectedPaymentMethod,
      this.getCrediCards
    );
  };

  getCrediCards = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId
    } = this.props;
    actionsFromSession.getUserCreditCards(communityMemberId, authToken);
  };

  confirmDelete = (selectedPaymentMethodId, selectedPaymentMethod) => {
    this.setState({
      deletePaymentMethodModal: true,
      selectedPaymentMethodId,
      selectedPaymentMethod
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      deletePaymentMethodModal: false,
      selectedPaymentMethodId: null,
      selectedPaymentMethod: ''
    });
  };

  render() {
    const { classes, loading, creditCards } = this.props;
    const { paymentMethodsLabel, addPaymentMethod, deleteLabel } = strings;
    const { deletePaymentMethodModal, selectedPaymentMethod } = this.state;
    const brands = {
      mastercard: masterCard,
      american_express: americanExpress,
      visa,
      edenred
    };
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{paymentMethodsLabel}</h1>
    );
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <ConfirmDeleteCardModal
          deletePaymentMethodModal={deletePaymentMethodModal}
          closeConfirmDelete={this.closeConfirmDelete}
          deleteCard={this.deleteCard}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => this.goTo('/machine-inventory')}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          >
            {creditCards.map(({ id, brand, card_number: cardNumber }) => (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction="row"
                wrap="nowrap"
                key={id}
              >
                <img
                  src={brands[brand]}
                  alt={brand}
                  className={classes.paymentMethodBrandImage}
                />
                <p className={classes.bodyDefaultText}>{cardNumber}</p>
                <p
                  className={classes.bodyDefaultError}
                  onClick={() => {
                    this.confirmDelete(id, cardNumber);
                  }}
                >
                  {deleteLabel}
                </p>
              </Grid>
            ))}
            <Button
              size="md"
              type="primary"
              action={() => this.goTo('/select-payment-method')}
            >
              {addPaymentMethod}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

PaymentMethodsPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  loading: bool.isRequired,
  creditCards: arrayOf(shape({})).isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  communityMemberId: number
};

PaymentMethodsPage.defaultProps = {
  authToken: '',
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { loading, creditCards, communityMember } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  return {
    loading,
    creditCards,
    authToken,
    communityMemberId
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentMethodsPage));
