import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import styles from '../../styles';
import classnames from 'classnames';

const CaptureProcessModal = ({
    classes,
    captureModal,
}) => {

    return (
        <Modal open={captureModal}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.notConectedModalContainer}
                wrap="nowrap"
                style={{ zIndex: 101 }}
            >
                <p className={classes.bodyDefaultTextCenterNoMargin}>Espera un momento por favor</p>
            </Grid>
        </Modal>
    );
};

CaptureProcessModal.propTypes = {
    classes: shape({}).isRequired,
};

export default connect(
    null
)(withStyles(styles)(CaptureProcessModal));