import React, { Component } from "react";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import "./Selfie.css";
import { withStyles } from '@material-ui/core/styles';
import { shape, func, bool } from 'prop-types';
import { IconButton } from '@material-ui/core';
import styles from '../../../styles';

class Selfie extends Component {

    componentDidMount = async () => {
        const {
            startFunction,
        } = this.props;
        startFunction()
    };

    render() {

        const {
            classes,
            refFunc,
            canvasRef,
            savePhoto,
            showButton,
        } = this.props;

        return (
            <>
                <div className={classes.selfie}>
                    <div className={classes.cam}>
                        <video
                            width="100%"
                            // height="100%"
                            className={classes.videoPlayer}
                            autoPlay={true}
                            ref={refFunc}
                            controls={false}
                        ></video>
                        {showButton && (
                            <div className={classes.captureBtn}>
                                <IconButton onClick={savePhoto}>
                                    <PhotoCameraIcon className={classes.cameraIcon} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                </div>
            </>
        );
    }
}

Selfie.propTypes = {
    classes: shape({}).isRequired,
    refFunc: func,
    savePhoto: func,
    startFunction: func,
    canvasRef: func,
    showButton: bool,
};

Selfie.defaultProps = {
    refFunc: () => { },
    savePhoto: () => { },
    startFunction: () => { },
    canvasRef: () => { }
};

export default withStyles(styles)(Selfie);

