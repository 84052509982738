export const GET_VERIFICATION_CODE_REQUEST = 'GET_VERIFICATION_CODE_REQUEST';
export const GET_VERIFICATION_CODE_SUCCESS = 'GET_VERIFICATION_CODE_SUCCESS';
export const GET_VERIFICATION_CODE_FAIL = 'GET_VERIFICATION_CODE_FAIL';
export const GET_MEMBER_INFO_REQUEST = 'GET_MEMBER_INFO_REQUEST';
export const GET_MEMBER_INFO_SUCCESS = 'GET_MEMBER_INFO_SUCCESS';
export const GET_MEMBER_INFO_FAIL = 'GET_MEMBER_INFO_FAIL';
export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAIL = 'VERIFY_CODE_FAIL';
export const GET_MEMBER_BALANCE_REQUEST = 'GET_MEMBER_BALANCE_REQUEST';
export const GET_MEMBER_BALANCE_SUCCESS = 'GET_MEMBER_BALANCE_SUCCESS';
export const GET_MEMBER_BALANCE_FAIL = 'GET_MEMBER_BALANCE_FAIL';
export const GET_USER_CREDIT_CARDS_REQUEST = 'GET_USER_CREDIT_CARDS_REQUEST';
export const GET_USER_CREDIT_CARDS_SUCCESS = 'GET_USER_CREDIT_CARDS_SUCCESS';
export const GET_USER_CREDIT_CARDS_FAIL = 'GET_USER_CREDIT_CARDS_FAIL';
export const GET_MEMBER_NEW_BALANCE_REQUEST = 'GET_MEMBER_NEW_BALANCE_REQUEST';
export const GET_MEMBER_NEW_BALANCE_SUCCESS = 'GET_MEMBER_NEW_BALANCE_SUCCESS';
export const GET_MEMBER_NEW_BALANCE_FAIL = 'GET_MEMBER_NEW_BALANCE_FAIL';
export const RECHARGE_BALANCE_REQUEST = 'RECHARGE_BALANCE_REQUEST';
export const RECHARGE_BALANCE_SUCCESS = 'RECHARGE_BALANCE_SUCCESS';
export const RECHARGE_BALANCE_FAIL = 'RECHARGE_BALANCE_FAIL';
export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAIL = 'DELETE_PAYMENT_METHOD_FAIL';
export const ADD_CREDIT_CARD_REQUEST = 'ADD_CREDIT_CARD_REQUEST';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_FAIL = 'ADD_CREDIT_CARD_FAIL';
export const GET_DEALS_HISTORY_REQUEST = 'GET_DEALS_HISTORY_REQUEST';
export const GET_DEALS_HISTORY_SUCCESS = 'GET_DEALS_HISTORY_SUCCESS';
export const GET_DEALS_HISTORY_FAIL = 'GET_DEALS_HISTORY_FAIL';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_TOP_UP_INFO_REQUEST = 'UPDATE_TOP_UP_INFO_REQUEST';
export const UPDATE_TOP_UP_INFO_SUCCESS = 'UPDATE_TOP_UP_INFO_SUCCESS';
export const UPDATE_TOP_UP_INFO_FAIL = 'UPDATE_TOP_UP_INFO_FAIL';
export const GET_MEMBER_CONFIGURATIONS_REQUEST =
  'GET_MEMBER_CONFIGURATIONS_REQUEST';
export const GET_MEMBER_CONFIGURATIONS_SUCCESS =
  'GET_MEMBER_CONFIGURATIONS_SUCCESS';
export const GET_MEMBER_CONFIGURATIONS_FAIL = 'GET_MEMBER_CONFIGURATIONS_FAIL';
export const UPDATE_MEMBER_CONFIGURATIONS_REQUEST =
  'UPDATE_MEMBER_CONFIGURATIONS_REQUEST';
export const UPDATE_MEMBER_CONFIGURATIONS_SUCCESS =
  'UPDATE_MEMBER_CONFIGURATIONS_SUCCESS';
export const UPDATE_MEMBER_CONFIGURATIONS_FAIL =
  'UPDATE_MEMBER_CONFIGURATIONS_FAIL';
export const SEND_RATING_REQUEST = 'SEND_RATING_REQUEST';
export const SEND_RATING_SUCCESS = 'SEND_RATING_SUCCESS';
export const SEND_RATING_FAIL = 'SEND_RATING_FAIL';
export const GET_COMMUNITY_MEMBER_MESSAGES_REQUEST =
  'GET_COMMUNITY_MEMBER_MESSAGES_REQUEST';
export const GET_COMMUNITY_MEMBER_MESSAGES_SUCCESS =
  'GET_COMMUNITY_MEMBER_MESSAGES_SUCCESS';
export const GET_COMMUNITY_MEMBER_MESSAGES_FAIL =
  'GET_COMMUNITY_MEMBER_MESSAGES_FAIL';
export const ADD_EDENRED_CARD_REQUEST = 'ADD_EDENRED_CARD_REQUEST';
export const ADD_EDENRED_CARD_SUCCESS = 'ADD_EDENRED_CARD_SUCCESS';
export const ADD_EDENRED_CARD_FAIL = 'ADD_EDENRED_CARD_FAIL';
export const LOGOUT = 'LOGOUT';
