import * as types from '../actions/vendingMachine/types';

const initialState = {
  selectedVendingMachine: {},
  productServerItems: [],
  productServerServices: [],
  selectedProduct: {},
  deal: {},
  anyProduct: false,
  error: null,
  recentVendingMachines: [],
  loadingRecentMachines: false,
  loading: false,
  vendingMachineKeyboardInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VENDING_MACHINE_REQUEST:
      return {
        ...state,
        loading: true,
        selectedVendingMachine: {}
      };
    case types.GET_VENDING_MACHINE_SUCCESS:
      return {
        ...state,
        selectedVendingMachine: action.selectedVendingMachine,
        loading: false
      };
    case types.GET_VENDING_MACHINE_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_VENDING_MACHINE_KEYBOARD_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_VENDING_MACHINE_KEYBOARD_INFO_SUCCESS:
      return {
        ...state,
        vendingMachineKeyboardInfo: action.vendingMachineKeyboardInfo,
        loading: false
      };
    case types.GET_VENDING_MACHINE_KEYBOARD_INFO_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_RECENT_VENDING_MACHINES_REQUEST:
      return {
        ...state,
        loadingRecentMachines: true
      };
    case types.GET_RECENT_VENDING_MACHINES_SUCCESS:
      return {
        ...state,
        recentVendingMachines: action.recentVendingMachines,
        loadingRecentMachines: false
      };
    case types.GET_RECENT_VENDING_MACHINES_FAIL:
      return {
        ...state,
        loadingRecentMachines: false
      };
    case types.GET_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_INVENTORY_SUCCESS:
      return {
        ...state,
        productServerItems: action.productServerItems,
        loading: false
      };
    case types.GET_INVENTORY_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        productServerServices: action.productServerServices,
        loading: false
      };
    case types.GET_SERVICES_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.SELECT_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: action.selectedProduct
      };
    case types.CREATE_NEW_DEAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_NEW_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        deal: action.deal,
        anyProduct: action.anyProduct
      };
    case types.CREATE_NEW_DEAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case types.GET_DEAL_STATUS_REQUEST:
      return {
        ...state
      };
    case types.GET_DEAL_STATUS_SUCCESS:
      return {
        ...state,
        deal: action.deal
      };
    case types.GET_DEAL_STATUS_FAIL:
      return {
        ...state
      };
    case types.GET_PRODUCT_BY_ID_REQUEST:
      return {
        ...state
      };
    case types.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        selectedProduct: action.selectedProduct
      };
    case types.GET_PRODUCT_BY_ID_FAIL:
      return {
        ...state
      };
    case types.RESET:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
