import { Button } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const DisabledButton = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      textTransform: 'none',
      fontSize: '1.2rem',
      padding: '6px 12px',
      border: 'none',
      borderRadius: 5,
      backgroundColor: '#C2C2C2',
      color: '#ffffff',
      outline: 'none',
      '&:hover': {
        backgroundColor: '#C2C2C2'
      },
      '&:active': {
        backgroundColor: '#C2C2C2',
        outline: 'none'
      },
      '&:focus': {
        backgroundColor: '#C2C2C2',
        outline: 'none'
      }
    }
  })
)(Button);

export default DisabledButton;
