import fetch from 'cross-fetch';
import { API } from '../config/api';

export const getVendingMachineByNameForKeyboardService = (vendingMachineName) =>
  fetch(`${API}/api/v4/product_server/${vendingMachineName}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

export const getVendingMachineByIdService = (vendingMachineId, authToken = null) =>
  fetch(`${API}/api/v4/product_servers/${vendingMachineId}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Token token=${authToken}`
    }
  });

export const getVendingMachineItemsService = (vendingMachineId) =>
  fetch(`${API}/api/v4/product_servers/${vendingMachineId}/products`, {
    headers: {
      Accept: 'application/json'
    }
  });

export const getVendingMachineServicesService = (vendingMachineId, authToken) =>
  fetch(`${API}/api/v4/product_servers/${vendingMachineId}/payment_products`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Token token=${authToken}`
    }
  });

export const getProductByIdService = (vendingMachineId, productId, authToken) =>
  fetch(
    `${API}/api/v4/product_servers/${vendingMachineId}/products/${productId}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Token token=${authToken}`
      }
    }
  );

export const createNewDealService = (
  authToken,
  selectedVendingMachineId,
  selectedProductUserCode,
  saleType,
  origin,
  anyProduct,
  locale
) =>
  fetch(`${API}/api/v4/product_servers/${selectedVendingMachineId}/deals`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Token token=${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_code: selectedProductUserCode,
      sale_type_code: saleType,
      origin,
      any_product: anyProduct,
      locale
    })
  });

export const createNewDealDirectPaymentService = (
  authToken,
  selectedVendingMachineId,
  selectedProductUserCode,
  saleType,
  origin,
  anyProduct,
  creditCardId,
  locale
) =>
  fetch(`${API}/api/v4/product_servers/${selectedVendingMachineId}/deals`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Token token=${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_code: selectedProductUserCode,
      sale_type_code: saleType,
      origin,
      any_product: anyProduct,
      locale,
      credit_card_id: creditCardId
    })
  });

export const getDealStatusService = (
  authToken,
  selectedVendingMachineId,
  dealId
) =>
  fetch(
    `${API}/api/v4/product_servers/${selectedVendingMachineId}/deals/${dealId}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Token token=${authToken}`
      }
    }
  );

export const getRecentVendingMachinesService = (authToken, communityMemberId) =>
  fetch(
    `${API}/api/v4/community_members/${communityMemberId}/product_servers`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Token token=${authToken}`
      }
    }
  );
