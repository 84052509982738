import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import QrReader from 'react-qr-reader';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';
import { isSupported } from '../../config/utils';

const scanQrImage = require('../../assets/scan.png');

class QrScanner extends Component {
  constructor() {
    super();
    this.state = {
      qrEnabled: false
    };
  }

  enableQrReader = () => {
    this.setState({ qrEnabled: true });
  };

  handleScan = (data) => {
    if (data && data.includes('nimanic.com/')) {
      const vendingMachineName = data.split('nimanic.com/');
      const { getVendingMachineByName } = this.props;
      getVendingMachineByName(vendingMachineName[1]);
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  render() {
    const { classes } = this.props;
    const { qrEnabled } = this.state;
    const { scan, enableQr } = strings;
    const supported = isSupported();
    if (!supported) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.qrImageAndButton}
        >
          <p style={{ textAlign: 'center' }}>{enableQr}</p>
        </Grid>
      );
    }
    return (
      <>
        {qrEnabled ? (
          <QrReader
            delay={100}
            style={{
              width: '100%',
              objectFit: 'cover'
            }}
            onError={this.handleError}
            onScan={this.handleScan}
          />
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={classes.qrImageAndButton}
          >
            <div
              style={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <img src={scanQrImage} alt="QR" className={classes.scanQrImage} />
              <Grid className={classes.mdButtonContainer}>
                <Button size="md" type="primary" action={this.enableQrReader}>
                  {scan}
                </Button>
              </Grid>
            </div>
          </Grid>
        )}
      </>
    );
  }
}

QrScanner.propTypes = {
  classes: shape({}).isRequired,
  getVendingMachineByName: func.isRequired
};

export default withStyles(styles)(QrScanner);
