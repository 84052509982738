import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import Clear from '@material-ui/icons/Clear';

const HeaderSabaTwo = ({
    classes,
    closeAction,
}) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.headerSaba}
        >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.headerUpperContent}
            >
                <div onClick={closeAction} style={{ padding: 10, marginLeft: 10, marginTop: 10 }}>
                    <Clear style={{ color: "#DF1995" }} onClick={closeAction} />
                </div>
            </Grid>
        </Grid>
    );
};

HeaderSabaTwo.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
};

HeaderSabaTwo.defaultProps = {
    closeAction: () => { },
};

export default withStyles(styles)(HeaderSabaTwo);