import { combineReducers } from 'redux';
import language from './language';
import session from './session';
import vendingMachine from './vendingMachine';
import tickets from './tickets';

const rootReducer = combineReducers({
  language,
  session,
  vendingMachine,
  tickets,
});

export default rootReducer;
