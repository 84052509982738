import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import styles from '../../styles';
import classnames from 'classnames';

const SendRevisionRequestModal = ({
    classes,
    revisionModal,
    errorDetected,
}) => {

    return (
        <Modal open={revisionModal}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.notConectedModalContainer}
                wrap="nowrap"
                style={{ zIndex: 101 }}
            >
                <p className={classes.bodyDefaultTextCenterNoMargin}>{errorDetected ? `No pudimos procesar tu solicitud, inténtalo de nuevo.` : `Tu solicitud ha sido enviada.`}</p>
            </Grid>
        </Modal>
    );
};

SendRevisionRequestModal.propTypes = {
    classes: shape({}).isRequired,
};

export default connect(
    null
)(withStyles(styles)(SendRevisionRequestModal));