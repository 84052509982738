const styles = (theme) => {
  const { saba } = theme;
  const { colors, sizes } = saba;
  const {
    primaryHeaderBackgroundColor,
    primaryHeaderFontColor,
    secondaryHeaderBackgroundColor,
    secondaryHeaderFontColor,
    bodyBackgroundColor,
    bodyFontColor,
    inventoryBackgroundColor,
    // primaryButtonBackgroundColor,
    // primaryButtonFontColor,
    // secondaryButtonBackgroundColor,
    // secondaryButtonFontColor,
    // disabledButtonBackgroundColor,
    // disabledButtonFontColor,
    // footerBackgroundColor,
    // footerFontColor,
    primaryColor,
    secondaryColor,
    accentColor,
    alertColor,
  } = colors;
  const { font, button } = sizes;
  const {
    xs: xsFont,
    sm: smFont,
    md: mdFont,
    lg: lgFont,
    xl: xlFont,
    xxl: xxlFont,
  } = font;
  const { sm: smButton, md: mdButton, lg: lgButton } = button;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  return {
    // DEFAULTS
    noMargin: {
      margin: 0,
    },
    marginTop20: {
      marginTop: 30,
    },
    centered: {
      textAlign: "center",
    },
    centeredImage: {
      maxHeight: "40vh",
      marginBottom: 15,
      maxWidth: "70vw",
    },
    fullWidthInput: {
      width: "90%",
    },
    fullWidthInputLabel: {
      color: primaryColor,
      width: "90%",
      fontSize: xsFont,
      textAlign: "left",
      fontWeight: 100,
    },
    fullWidthCheckContainer: {
      width: "90%",
      marginTop: 15,
      marginBottom: 15,
    },
    fullWidthCheckText: {
      width: "calc(100% - 60px)",
      margin: "9px !important",
    },
    modalContainer: {
      width: 400,
      height: 300,
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    floatingButtonIcon: {
      color: "white",
      zIndex: 102,
      fontSize: 35,
    },
    floatingButton: {
      width: "140px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      // backgroundColor: "rgba(223, 25, 149, 0.70)",
      backgroundColor: primaryColor,
      // position: "fixed",
      // bottom: 15,
      // right: 20,
      zIndex: 102,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.35)",
    },
    lowerContainer: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: 10,
      // backgroundColor: "rgba(223, 25, 149, 0.70)",
      backgroundColor: "white",
      position: "fixed",
      bottom: -1,
      right: 0,
      zIndex: 101,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.35)",
    },
    mainContainer: {
      width: "100%",
      height: "100%",
    },
    width100Container: {
      width: "100%",
    },
    loginContactContainer: {
      width: "100%",
      paddingBottom: 30,
      marginTop: 10,
    },
    loginSupContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loginModalContainer: {
      width: "90%",
      maxHeight: "90%",
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
      overflow: "scroll",
    },
    notConectedModalContainer: {
      width: "90%",
      height: 100,
      maxHeight: "50%",
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
      overflow: "scroll",
    },
    closeModal: {
      width: "100%",
    },
    loginModalContainerScanTicket: {
      width: "90%",
      height: "60%",
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
    },
    containerScanTicketModal: {
      width: "90%",
      height: "420px",
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
    },
    modalHeaderLeft: {
      width: "10%",
    },
    modalHeaderCenter: {
      width: "80%",
      margin: 0,
    },
    modalHeaderRight: {
      width: "10%",
    },
    directPaymentModalContainer: {
      width: "90%",
      height: "40%",
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
    },

    // BODY
    bodyContainer: {
      width: "100%",
      height: "100%",
      backgroundColor: bodyBackgroundColor,
      color: bodyFontColor,
      fontSize: smFont,
      overflowX: "hidden",
      position: "relative",
    },
    bodyDefaultTitle: {
      color: bodyFontColor,
      fontSize: xlFont,
      fontWeight: 100,
      textAlign: "center",
      marginTop: 0,
    },
    titleSecColor: {
      color: primaryHeaderBackgroundColor,
      fontSize: xlFont,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: 0,
    },
    genderContainer: {
      textAlign: "center",
    },
    titledUnderlined: {
      color: bodyFontColor,
      fontSize: lgFont,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: 0,
      textDecoration: "underline",
      marginBottom: 25,
    },
    sabaTitle: {
      color: primaryColor,
      fontSize: xlFont,
      fontWeight: 100,
      textAlign: "center",
      marginTop: 0,
    },
    scanTicketModalTitle: {
      color: primaryColor,
      fontSize: xlFont,
      fontWeight: 100,
      textAlign: "center",
      margin: 0,
    },
    sabaTitleModalVer: {
      color: primaryColor,
      fontSize: xlFont,
      fontWeight: 100,
      textAlign: "center",
      marginTop: 0,
      marginBottom: 40,
    },
    sabaTitleRegistration: {
      color: primaryColor,
      fontSize: xlFont,
      fontWeight: 100,
      textAlign: "center",
      marginTop: 0,
      marginBottom: 30,
    },
    bodyDownloadText: {
      marginBottom: "25%",
      textAlign: "center",
    },
    bodyDefaultText: {
      color: bodyFontColor,
      fontSize: smFont,
      fontWeight: 100,
      marginTop: 0,
      marginBottom: 10,
    },
    bodyDefaultTextCenter: {
      color: bodyFontColor,
      fontSize: smFont,
      fontWeight: 100,
      textAlign: "center",
      paddingLeft: 50,
      paddingRight: 50,
      marginTop: 50,
      marginBottom: 50,
    },
    bodyDefaultTextCenterNoMargin: {
      color: bodyFontColor,
      fontSize: smFont,
      fontWeight: 100,
      textAlign: "center",
    },
    bodySmallText: {
      color: bodyFontColor,
      fontSize: xsFont,
      fontWeight: 100,
    },
    bodyDefaultLink: {
      color: primaryColor,
      fontSize: smFont,
      fontWeight: 100,
    },
    bodySmallLink: {
      color: primaryColor,
      fontSize: xsFont,
      fontWeight: 100,
    },
    bodyDefaultError: {
      color: alertColor,
      fontSize: smFont,
      fontWeight: 100,
      marginTop: 0,
      marginBottom: 10,
    },
    visibilityHidden: {
      visibility: "hidden",
    },
    primaryLoading: {
      color: primaryColor,
    },
    bodyWithHeaderContainer: {
      minHeight: "100%",
      overflow: "auto",
      padding: 10,
    },

    // BUTTONS
    smButtonContainer: {
      width: smButton,
      fontSize: mdFont,
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    mdButtonContainer: {
      width: mdButton,
      fontSize: mdFont,
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    lgButtonContainer: {
      width: lgButton,
      fontSize: mdFont,
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    smContentContainer: {
      width: "100%",
      maxWidth: 450,
      padding: 5,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },

    // HEADER
    primaryHeader: {
      padding: 10,
      position: "fixed",
      zIndex: 3,
      top: 0,
      backgroundColor: primaryHeaderBackgroundColor,
      color: primaryHeaderFontColor,
      height: 50,
    },
    secondaryHeader: {
      padding: 10,
      position: "fixed",
      zIndex: 3,
      top: 0,
      backgroundColor: secondaryHeaderBackgroundColor,
      color: secondaryHeaderFontColor,
      height: 50,
    },
    headerLeftContent: {
      width: "20%",
    },
    headerCenterContent: {
      width: "55%",
    },
    headerRightContent: {
      width: "25%",
    },
    headerDefaultTitle: {
      fontSize: xlFont,
      fontWeight: 100,
      margin: 0,
    },
    headerSecColor: {
      color: secondaryColor,
      fontSize: mdFont,
    },
    headerDefaultText: {
      fontSize: smFont,
      fontWeight: 100,
      margin: 0,
    },
    headerDefaultTextSaba: {
      // fontSize: xsFont,
      fontSize: 15,
      fontWeight: "bold",
      margin: 0,
      backgroundColor: primaryColor,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 4,
    },
    containerWithHeader: {
      minHeight: "100%",
      paddingTop: 50,
    },
    headerSaba: {
      width: "100%",
    },
    titlePrimaryColor: {
      color: primaryColor,
      fontWeight: "400",
      fontSize: xlFont,
      textAlign: "center",
      padding: 0,
      marginTop: 8,
      marginBottom: 8,
    },
    generalTextSaba: {
      color: bodyFontColor,
      fontWeight: "300",
      fontSize: xlFont,
      textAlign: "center",
      margin: 0,
      padding: 0,
    },
    generalTextSabaBold: {
      color: bodyFontColor,
      fontWeight: "bold",
      fontSize: xxlFont,
      textAlign: "center",
      margin: 0,
      padding: 0,
    },
    generalTextSabaSmall: {
      color: bodyFontColor,
      fontWeight: "300",
      fontSize: mdFont,
      textAlign: "center",
      margin: 0,
      padding: 0,
    },
    bigTextSaba: {
      color: primaryColor,
      fontWeight: "bold",
      fontSize: 35,
      textAlign: "center",
      margin: 0,
      padding: 0,
    },
    generalSecondaryTextSaba: {
      color: bodyFontColor,
      fontWeight: "100",
      fontSize: mdFont,
      textAlign: "center",
      margin: 0,
      padding: 0,
    },
    generalSecondaryTextSabaNotCentered: {
      color: bodyFontColor,
      fontWeight: "500",
      fontSize: mdFont,
      // textAlign: "center",
      margin: 0,
      marginLeft: 30,
      padding: 0,
    },
    generalContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    generalContainerScanTicket: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    generalContainerScanTicketTwo: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    generalContainerPhoto: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mainPhotoContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
    },
    photoComponentContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
    },
    barcodeBorder: {
      width: windowWidth,
      height: windowWidth * 0.15,
      position: "relative",
      top: 0,
      left: 0,
      border: `4px solid ${primaryColor}`,
      zIndex: 99,
    },
    barcodeScanLine: {
      width: "96%",
      position: "fixed",
      height: 4,
      borderRadius: 2,
      backgroundColor: "rgba(175, 0, 0, 0.35)",
      zIndex: 102,
    },
    cameraIcon: {
      color: "white",
      // color: "rgba(0, 0, 0, 0.1)",
      fontSize: 50,
    },
    cameraIconContainer: {
      width: 80,
      height: 80,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      padding: 10,
      margin: 0,
      top: "37.5%",
      left: 0,
      zIndex: 100,
    },
    scanBarcodeUpperContentContainer: {
      width: "100%",
      height: windowHeight / 2 - (windowWidth * 0.16) / 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      position: "fixed",
      backgroundColor: "white",
      // padding: 10,
      margin: 0,
      top: 0,
      left: 0,
      zIndex: 99,
    },
    scanBarcodeLowerContentContainer: {
      width: "100%",
      height: windowHeight / 2 - (windowWidth * 0.16) / 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      backgroundColor: "white",
      // padding: 10,
      margin: 0,
      bottom: 0,
      left: 0,
      zIndex: 99,
    },
    width100: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    barcodeScanStyle: {
      // width: '100%',
      // height: 200,
      // borderRadius: 10,
      // border: `2px solid ${primaryColor}`,
      // backgroundColor: primaryColor,
      display: "flex",
      justifyContent: "center",
    },
    headerUpperContent: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
    headerLowerContent: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },

    // LEFT MENU
    menuContainer: {
      position: "fixed",
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.1)",
      zIndex: 4,
    },
    menu: {
      width: "20%",
      height: "100%",
      backgroundColor: bodyBackgroundColor,
      boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
    profileContainer: {
      height: 120,
      borderBottom: `1px solid ${primaryColor}`,
    },
    avatarContainer: {
      width: 60,
      height: 60,
      borderRadius: 60,
      border: `2px solid ${primaryColor}`,
    },
    avatarIcon: {
      color: primaryColor,
      fontSize: 40,
    },
    profileInfo: {
      width: "60%",
    },
    profileViewLink: {
      color: primaryColor,
      fontSize: xsFont,
      margin: 0,
      fontWeight: 100,
    },
    menuOptionsContainer: {
      padding: 15,
    },
    menuOption: {
      margin: "15px 0",
    },
    menuOptionIcon: {
      color: primaryColor,
      marginBottom: 10,
      marginRight: 10,
    },

    // PROFILE
    profileName: {
      color: bodyFontColor,
      fontSize: lgFont,
      fontWeight: 100,
      margin: 0,
    },
    profileGenderAndDate: {
      color: bodyFontColor,
      fontSize: mdFont,
      fontWeight: 100,
      margin: 0,
    },
    profileSmallText: {
      color: bodyFontColor,
      fontSize: xsFont,
      fontWeight: 100,
      margin: 0,
    },
    logoutContainer: {
      position: "absolute",
      bottom: 20,
    },
    logout: {
      color: primaryColor,
      fontSize: xsFont,
      fontWeight: 100,
      margin: 0,
    },
    linksSection: {
      padding: 10,
    },
    linkTitle: {
      color: bodyFontColor,
      fontSize: mdFont,
      fontWeight: 100,
      margin: 0,
    },
    viewHistoryLink: {
      color: primaryColor,
      fontSize: xsFont,
      fontWeight: 100,
      margin: 0,
    },
    resendMailVerificationContainer: {
      position: "absolute",
      bottom: 20,
      "& p": {
        color: primaryColor,
        fontSize: xsFont,
        fontWeight: 100,
        margin: 0,
      },
    },
    registerMainContainer: {
      width: "100%",
    },

    // LOGIN
    promisesText: {
      marginBottom: 20,
    },
    nimanicLogo: {
      width: 200,
      objectFit: "contain",
      marginBottom: 15,
      marginTop: 25,
    },
    nestleLogo: {
      width: 200,
      objectFit: "contain",
      marginBottom: 15,
    },
    automateLogo: {
      width: 250,
      objectFit: "contain",
      marginBottom: 15,
    },
    sabaLogo: {
      width: 250,
      objectFit: "contain",
      marginBottom: 15,
      marginTop: 30,
    },
    sabaticketPhoto: {
      width: 300,
    },
    ticketPhotoStyle: {
      maxWidth: "75%",
      maxHeight: "50vh",
      borderRadius: 15,
      border: `4px solid ${primaryColor}`,
    },
    sabaLogoObtainedPoints: {
      width: 250,
    },
    processingImageStyle: {
      width: 180,
    },
    sabaTicketIcon: {
      width: 180,
      marginRight: 30,
    },
    sabaThicketPhotoExample: {
      width: 300,
    },
    sabaTitleOne: {
      fontSize: xxlFont,
      color: primaryColor,
    },
    sabaTitleTwo: {
      fontSize: xxlFont,
      color: "#001E62",
    },
    sabaTitleTwoBold: {
      fontSize: xlFont,
      color: "#001E62",
      fontWeight: "bold",
      textAlign: "center",
    },
    countryCodeLabel: {
      width: "35%",
      color: primaryColor,
      fontSize: xsFont,
      textAlign: "center",
      fontWeight: 100,
    },
    phoneLabel: {
      width: "60%",
      color: primaryColor,
      fontSize: xsFont,
      textAlign: "center",
      fontWeight: 100,
    },
    countryCodeInput: {
      width: "35%",
    },
    phoneInput: {
      width: "60%",
    },
    nameInput: {
      width: "80%",
      maxWidth: "350px",
    },
    emailInput: {
      width: "80%",
      maxWidth: "350px",
    },
    checkContainer: {
      marginTop: 10,
      marginBottom: 10,
    },
    checkContainerModalVer: {
      marginTop: 40,
      marginBottom: 30,
    },
    checkText: {
      width: "78%",
      margin: 9,
    },
    checkTextModal: {
      width: "70%",
      margin: 9,
    },
    promiseTitle: {
      marginBottom: 0,
    },
    inputRoot: {
      borderBottom: `2px solid ${primaryColor}`,
    },
    inputRootError: {
      borderBottom: `2px solid ${alertColor}`,
    },
    checkBoxRoot: {
      "& .MuiSvgIcon-root": {
        fill: primaryColor,
      },
    },
    checkBoxRootError: {
      "& .MuiSvgIcon-root": {
        fill: alertColor,
      },
    },
    menuIconModal: {
      margin: 0,
      padding: 0,
      color: bodyFontColor,
      marginLeft: 8,
    },
    texScanModal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    // EDIT PROFILE
    twoColInput: {
      width: "90%",
    },
    textScanModalMenu: {
      color: bodyFontColor,
      fontSize: smFont,
      fontWeight: 100,
      textAlign: "center",
      margin: 0,
    },
    textScanModalSpan: {
      color: primaryColor,
      fontSize: smFont,
      fontWeight: 100,
      textAlign: "center",
      margin: 0,
    },
    textScanModalMenuFontSmall: {
      color: bodyFontColor,
      fontSize: xsFont,
      fontWeight: 400,
      textAlign: "center",
      margin: 0,
    },
    textScanModalMenuTwo: {
      display: "flex",
      alignItems: "center",
    },
    halfWidthInputLabel: {
      width: "45%",
      color: primaryColor,
      fontSize: xsFont,
      textAlign: "left",
      fontWeight: 100,
    },
    birthDateInput: {
      width: "80%",
      maxWidth: "350px",
    },
    birthDateInputEdit: {
      width: "45%",
    },
    genderInput: {
      width: "48%",
    },
    genderButton: {
      margin: 0,
    },
    genderSecContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    subtitlesProfile: {
      marginTop: 25,
      marginBottom: 0,
    },
    subtitleProfileTwo: {
      marginTop: 10,
      marginBottom: 0,
    },
    saveButtonProfile: {
      marginTop: 20,
      marginBottom: 0,
    },

    // PHONE VERIFICATION
    codeInputContainer: {
      width: 190,
      overflow: "hidden",
    },

    // MACHINE SELECTION
    orEnterID: {
      color: primaryColor,
    },
    recentMachinesContainer: {
      marginTop: 20,
      width: "100%",
    },
    recentVendingMachineContainer: {
      marginBottom: 10,
      height: 100,
    },
    vendingMachineImage: {
      width: 100,
      height: 100,
      objectFit: "contain",
    },
    vendingMachineInfo: {
      width: "calc(100% - 110px)",
    },
    vendingMachineName: {
      color: primaryColor,
      fontSize: smFont,
      fontWeight: 100,
      marginTop: 0,
      marginBottom: 10,
    },
    vendingMachineContact: {
      color: bodyFontColor,
      fontSize: xsFont,
      fontWeight: 100,
      marginTop: 0,
      marginBottom: 0,
    },
    qrImageAndButton: {
      height: 290,
    },
    scanQrImage: {
      objectFit: "contain",
      height: 181,
    },

    // MACHINE INVENTORY
    bannersContainer: {
      justifyContent: "space-around",
      paddingLeft: 5,
      maxWidth: "100%",
      overflowX: "auto",
      height: 130,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        height: 92.5,
      },
      "& img": {
        marginRight: 5,
      },
    },
    singleBannerContainer: {
      justifyContent: "space-around",
      maxWidth: "100%",
      overflowX: "auto",
      height: 130,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        height: 92.5,
      },
    },
    bannerImage: {
      objectFit: "contain",
      height: "90%",
      margin: 1,
      [theme.breakpoints.down("sm")]: {
        height: "90%",
        margin: 1,
      },
    },
    tabsAndContentContainer: {
      position: "relative",
      height: "calc(100% - 130px)",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100% - 92.5px)",
      },
    },
    NoBannerTabsAndContentContainer: {
      position: "relative",
      height: "100%",
    },
    tabsContainer: {
      position: "absolute",
      zIndex: 2,
    },
    selectedTab: {
      width: 130,
      padding: 5,
      height: 38,
      backgroundColor: inventoryBackgroundColor,
      border: `1px solid ${primaryColor}`,
      borderRadius: "5px 5px 0 0",
      borderBottom: `1px solid ${inventoryBackgroundColor}`,
      color: primaryColor,
      textAlign: "center",
      fontSize: 12,
    },
    tab: {
      width: 130,
      padding: 5,
      height: 38,
      color: primaryColor,
      textAlign: "center",
      fontSize: 12,
    },
    machineInventoryWithTabsContainer: {
      backgroundColor: inventoryBackgroundColor,
      borderTop: `1px solid ${secondaryColor}`,
      marginTop: 37,
      padding: "10px 0",
      minHeight: "100%",
    },
    machineInventoryNoBanners: {
      margin: 10,
    },
    machineInventoryContainer: {
      backgroundColor: inventoryBackgroundColor,
      borderTop: `1px solid ${secondaryColor}`,
      padding: "10px 0",
    },
    categoryTitle: {
      color: accentColor,
      fontWeight: "bold",
      margin: 0,
      marginTop: 14,
      marginLeft: 10,
    },
    categoryProductsContainer: {
      maxWidth: "100%",
      overflowX: "auto",
    },
    productContainer: {
      width: 115,
      cursor: "pointer",
      height: 175,
      marginTop: 5,
      marginRight: 8,
      borderRadius: 6,
      marginBottom: 5,
      paddingBottom: 3,
      justifyContent: "space-between",
      backgroundColor: bodyBackgroundColor,
      boxShadow: "2px 2px 3px 0px #0000004d",
      "&:first-child": {
        marginLeft: 10,
      },
    },
    // productContainer: {
    //   backgroundColor: bodyBackgroundColor,
    //   justifyContent: 'space-between',
    //   marginRight: 5,
    //   border: `1px solid ${secondaryColor}`,
    //   borderRadius: 5,
    //   width: 120,
    //   marginTop: 5,
    //   marginBottom: 5,
    //   padding: 3,
    //   height: 175,
    //   cursor: 'pointer',
    //   '&:first-child': {
    //     marginLeft: 10
    //   }
    // },
    // freeProductContainer: {
    //   backgroundColor: '#FFF',
    //   justifyContent: 'space-between',
    //   marginRight: 5,
    //   border: `2px solid ${accentColor}`,
    //   borderRadius: 5,
    //   width: 120,
    //   marginTop: 5,
    //   marginBottom: 5,
    //   padding: 3,
    //   height: 175,
    //   cursor: 'pointer',
    //   '&:first-child': {
    //     marginLeft: 10
    //   }
    // },
    thumbnail: {
      // width: 112,
      // height: 110,
      // objectFit: 'contain'
      width: 115,
      height: 115,
      objectFit: "contain",
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      top: 0,
    },

    // MACHINE READY
    machineReadyBalance: {
      color: bodyFontColor,
      fontSize: mdFont,
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 10,
    },
    selectionNumber: {
      color: bodyFontColor,
      fontSize: mdFont,
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 10,
    },
    connectingToMachineName: {
      color: bodyFontColor,
      fontSize: lgFont,
      fontWeight: "bold",
      marginTop: 10,
      marginBottom: 20,
    },

    // DOWNLOAD
    downloadFromMachineName: {
      color: bodyFontColor,
      fontSize: lgFont,
      fontWeight: "bold",
      marginTop: 10,
      marginBottom: 20,
    },

    whiteText: {
      color: "#fafafa",
    },
    bold: {
      fontWeight: "bold",
    },
    productCardName: {
      // height: 40,
      // width: '100%',
      // display: 'flex',
      // alignItems: 'center'
      width: "100%",
      height: 25,
      display: "flex",
      alignItems: "center",
      padding: 4,
      fontWeight: 100,
      fontSize: "0.7em",
    },
    productCardFree: {
      color: primaryColor,
      fontWeight: "bold",
      padding: "0px 4px",
    },
    productCardPrice: {
      color: primaryColor,
      fontWeight: "bold",
      padding: "0px 4px",
    },
    productPhoto: {
      objectFit: "contain",
      width: "80%",
      maxHeight: 400,
    },
    mediumButtonContainer: {
      width: 200,
      marginTop: 10,
      marginBottom: 20,
    },
    blueHeader: {
      padding: 10,
      position: "fixed",
      zIndex: 3,
      top: 0,
      backgroundColor: "#1258c4",
    },
    connectingImage: {
      width: "205",
    },
    divConnectingImage: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    downloadedImage: {
      maxHeight: "30vh",
    },
    whiteLoader: {
      color: "white",
    },
    priceWithDiscount: {
      color: "#4A4A4A",
      fontSize: xsFont,
      textDecoration: "line-through",
    },

    // KEYBOARD
    keyboardMainContainer: {
      height: "100%",
      position: "relative",
    },
    iframeContainer: {
      width: 400,
      height: 100,
      border: "none",
      marginTop: 20,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: `${(window.innerWidth * 0.9) / 4}px`,
      },
    },
    keyboardContainer: {
      width: 360,
      height: 480,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
        padding: "0 10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    keyboardButton: {
      width: 100,
      height: 60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      background: "white",
      border: "none",
      borderRadius: 5,
      color: "#0A2343",
      fontSize: 40,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      [theme.breakpoints.down("sm")]: {
        width: "29.5%",
        height: window.innerHeight / 15,
        fontSize: 30,
        margin: "1%",
      },
    },
    largeKeyboardButton: {
      width: 160,
      height: 60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      background: "white",
      border: "none",
      borderRadius: 5,
      color: "#020202",
      fontSize: 30,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      [theme.breakpoints.down("sm")]: {
        width: "45%",
        height: window.innerHeight / 15,
        fontSize: 20,
        margin: "1%",
      },
    },

    // BUTTONS
    primaryButton: {
      width: "100%",
      textTransform: "none",
      fontSize: "1.2rem",
      padding: "6px 12px",
      border: "none",
      borderRadius: 5,
      backgroundColor: primaryColor,
      color: "#ffffff",
      outline: "none",
      "&:hover": {
        backgroundColor: primaryColor,
      },
      "&:active": {
        backgroundColor: primaryColor,
        outline: "none",
      },
      "&:focus": {
        backgroundColor: primaryColor,
        outline: "none",
      },
    },

    // PAYMENT METHODS
    paymentMethodBrandImage: {
      width: 50,
      objectFit: "contain",
      marginBottom: 10,
    },

    // ADD PAYMENT METHOD
    expirationLabel: {
      width: "66%",
    },
    cvvLabel: {
      width: "33%",
    },
    expirationInputs: {
      width: "66%",
    },
    expirationInput: {
      width: "50%",
    },
    cvvInput: {
      width: "33%",
    },

    // SELECT PAYMENT METHOD
    paymentMethodButton: {
      width: "80%",
      height: 180,
      borderRadius: 5,
      border: "1px solid",
      position: "relative",
      marginTop: 10,
    },
    cardLine: {
      backgroundColor: bodyFontColor,
      width: "100%",
      height: 40,
      marginTop: 20,
    },
    cardName: {
      backgroundColor: bodyFontColor,
      width: "35%",
      height: 30,
      position: "absolute",
      bottom: 80,
      left: 15,
      borderRadius: 5,
    },
    paymentMethodImages: {
      position: "absolute",
      flexDirection: "row",
      bottom: 10,
      right: 10,
    },
    paymentMethodImage: {
      width: 40,
      height: 30,
      marginLeft: 10,
    },

    // DEAL INFO
    dealContainer: {
      padding: 10,
    },
    dealProductImage: {
      maxWidth: 50,
      maxHeight: 50,
      width: "auto",
      height: "auto",
    },
    dealCenterContainer: {
      width: "calc(100% - 130px)",
    },
    dealRightContainer: {
      width: 70,
    },
    dealBoldText: {
      color: bodyFontColor,
      fontSize: smFont,
      fontWeight: 400,
      marginTop: 0,
      marginBottom: 10,
    },
    dealSmallText: {
      color: bodyFontColor,
      fontSize: xsFont,
      fontWeight: 100,
      margin: 0,
    },

    // CUSTOM MESSAGE MODAL
    customMessageModalContainer: {
      width: 400,
      height: 600,
      border: "none",
      borderRadius: 4,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: bodyBackgroundColor,
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      outline: "none",
      padding: 10,
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        height: "90%",
      },
    },
    customModalMedia: {
      width: "95%",
      maxHeight: "70%",
      objectFit: "contain",
    },
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
    },

    // TOUCHLESS KEYBOARD TAB
    insertCoinContainer: {
      padding: 10,
      width: "90%",
    },
    insertCoinImage: {
      width: 45,
      marginRight: 10,
    },
    insertCoinText: {
      color: primaryColor,
      margin: 0,
    },
    payWithPhone: {
      margin: 0,
    },
    payWithPhoneLink: {
      color: primaryColor,
      textDecoration: "underline",
    },

    // CODE INPUT
    newCodeInputContainer: {
      position: "relative",
      display: "flex",
    },
    newCodeInputCell: {
      borderBottom: `2px solid ${primaryColor}`,
      width: 32,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: mdFont,
      position: "relative",
      margin: "0 8px",
    },
    newCodeInput: {
      position: "absolute",
      border: "none",
      fontSize: mdFont,
      textAlign: "center",
      backgroundColor: "transparent",
      outline: "none",
    },
    nimanicAppLogo: {
      height: 100,
    },
    selfie: {
      width: '100%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cam: {
      position: 'relative',
    },
    previewImg: {
      display: "block",
    },
    videoPlayer: {
      display: "block",
    },
    captureBtn: {
      width: '100%',
      position: 'absolute',
      bottom: 9,
      display: 'flex',
      justifyContent: 'center',
      background: 'none',
      border: 'none',
    }
  };
};

export default styles;
