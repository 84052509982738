import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';

const MachineSelectionPage = ({
  classes,
  vendingName,
  vendingImage,
  contactInfo,
  getVendingMachineByName
}) => (
  <Grid
    container
    justify="space-between"
    onClick={() => {
      getVendingMachineByName(vendingName);
    }}
    className={classes.recentVendingMachineContainer}
  >
    <img
      src={vendingImage}
      alt={vendingName}
      className={classes.vendingMachineImage}
    />
    <Grid
      container
      direction="column"
      justify="flex-start"
      className={classes.vendingMachineInfo}
    >
      <p className={classes.vendingMachineName}>{vendingName}</p>
      <p className={classes.vendingMachineContact}>{contactInfo}</p>
    </Grid>
  </Grid>
);

MachineSelectionPage.propTypes = {
  classes: shape({}).isRequired,
  getVendingMachineByName: func.isRequired,
  vendingName: string.isRequired,
  vendingImage: string.isRequired,
  contactInfo: string.isRequired
};

export default withStyles(styles)(MachineSelectionPage);
