import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';
import HeaderSabaTwo from '../../HeaderSabaTwo';

const sabaLogo = require('../../../assets/Saba_logo_02.png');

const PointsObtained = ({
    classes,
    closeAction,
    buttonAction,
    pointsObtained,
}) => {

    return (
        <div
            className={classes.generalContainerScanTicket}
        >
            <div className={classes.width100}>
                <HeaderSabaTwo
                    closeAction={closeAction}
                />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={sabaLogo} alt="logo saba" className={classes.sabaLogoObtainedPoints} />
            </div>
            <div style={{ maxWidth: 240 }}>
                <p className={classes.generalTextSabaBold}>¡Felicidades!</p>
            </div>
            <div style={{ maxWidth: 240 }}>
                <p className={classes.generalTextSaba}>Obtuviste</p>
            </div>
            <div style={{ maxWidth: 350 }}>
                <p className={classes.bigTextSaba} style={{ marginTop: 15, marginBottom: 15 }}>{pointsObtained} puntos</p>
            </div>
            <div style={{ maxWidth: 300 }}>
                <p className={classes.generalTextSaba} style={{ marginBottom: 15 }}>Canjéalos a continuación por un regalo en la máquina</p>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
                <Button size="md" type="primary" action={buttonAction}>
                    Continuar
                </Button>
            </div>
        </div>
    );
};

PointsObtained.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    buttonAction: func,
    pointsObtained: string,
};

PointsObtained.defaultProps = {
    closeAction: () => { },
    buttonAction: () => { },
    pointsObtained: "",
};

export default withStyles(styles)(PointsObtained);