import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { shape, bool, func, string, oneOfType } from 'prop-types';
import {
  Grid,
  Modal,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import classnames from 'classnames';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { API_KEY, MERCHANT_ID } from '../../config/api';
import sessionActions from '../../actions/session';
import {
  getVerificationCodeService,
  verifyCodeService,
  getUserCreditCardsService,
  updateUserForPaymentsService
} from '../../services/session';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import CodeInput from '../CodeInput';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const nimanicLogo = require('../../assets/logo-nimanic.png');
const nestleLogo = require('../../assets/Nescafe-Logo.png');
const automateLogo = require('../../assets/automate-iq-logo.png');
const sabaLogo = require('../../assets/Saba_logo_02.png');

const CODE_LENGTH = new Array(4).fill(0);

const countryCodeMenuOptions = [
  { menuOption: '+52 México', optionValue: '+52' },
  { menuOption: '+31 Netherlands', optionValue: '+31' },
  { menuOption: '+1 United States', optionValue: '+1' },
  { menuOption: '+972 Israel', optionValue: '+972' },
  { menuOption: '+81 日本', optionValue: '+81' },
  { menuOption: '+++', optionValue: '+++' }
];

const privacyPolicyLink =
  'https://nimanic.com/aviso-de-privacidad/?plain_text=true';

const termsAndConditionsLink =
  'https://nimanic.com/terminos-y-condiciones/';

class LoginModal extends Component {
  constructor(props) {
    super(props);
    const { country, phone, name = '', authToken = '' } = props;
    this.state = {
      country,
      phone,
      privacyCheck: false,
      phoneError: false,
      countryError: false,
      privacyCheckError: false,
      // step: authToken ? 2 : 0,
      step: 0,
      value: '',
      cardNumber: '',
      name,
      email: '',
      expirationMonth: 'MM',
      expirationYear: 'YYYY',
      cvv: '',
      expirationMonthError: false,
      expirationYearError: false,
      nameError: false,
      emailError: false,
      cardNumberError: false,
      cvvError: false,
      communityMember: {}
    };
    this.codeInput = null;
  }

  componentDidMount() {
    const { checkPaymentMethods } = this.props;
    if (checkPaymentMethods) {
      const openpayScript = document.createElement('script');
      openpayScript.src = 'https://js.openpay.mx/openpay.v1.min.js';
      openpayScript.async = true;
      document.head.appendChild(openpayScript);
      openpayScript.onload = () => this.openpayScriptLoaded();
    }
  }

  openpayScriptLoaded = () => {
    window.OpenPay.setId(MERCHANT_ID);
    window.OpenPay.setApiKey(API_KEY);
    const openpayDataScript = document.createElement('script');
    openpayDataScript.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
    openpayDataScript.async = true;
    document.head.appendChild(openpayDataScript);
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  handleCodeChange = (e) => {
    const { value } = e.target;
    this.setState((state) => {
      if (state.value.length >= CODE_LENGTH.length) return null;
      return {
        value: (state.value + value).slice(0, CODE_LENGTH.length)
      };
    });
  };

  handleKeyUp = (e) => {
    if (e.key === 'Backspace') {
      this.setState((state) => ({
        value: state.value.slice(0, state.value.length - 1)
      }));
    }
  };

  handleFocus = (id) => {
    this.setState({ [`${id}Error`]: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { country, phone, privacyCheck } = this.state;
    if (phone.length === 10 && country !== '+++' && privacyCheck) {
      getVerificationCodeService(phone, country)
        // .then((res) => res.json())
        // .then((json) => {
        //   const { error = '' } = json;
        //   if (!error) {
        //     this.goToNextStep();
        //   }
        // });
        .then((res) => {
          const responseStatus = res.status;
          if (responseStatus === 204 || responseStatus === 200) {
            this.goToNextStep();
          }
          return res.json();
        })
    } else {
      if (!phone || phone.length < 10) {
        this.setState({ phoneError: true });
      }
      if (country === '+++') {
        this.setState({ countryError: true });
      }
      if (!privacyCheck) {
        this.setState({ privacyCheckError: true });
      }
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  handleSubmitCode = () => {
    const { checkPaymentMethods } = this.props;
    const { value, country, phone } = this.state;
    verifyCodeService(phone, country, value)
      .then((res) => res.json())
      .then((json) => {
        const { error = '', community_member: communityMember } = json;
        if (!error) {
          this.setState({ communityMember });
          if (checkPaymentMethods) {
            this.getCrediCards(communityMember);
          } else {
            this.dispatchCommunityMember();
          }
          console.log('communityMemberName', communityMember.name)
          if (!communityMember.name) {
            this.goTo('/registration-page');
          }
        }
      });
  };

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      privacyCheck: !prevState.privacyCheck,
      privacyCheckError: false
    }));
  };

  goToNextStep = () => {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  };

  goToPrevStep = () => {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
    this.setState({ privacyCheck: false });
  };

  saveCard = (event) => {
    event.preventDefault();
    const { sessionActions: actionsFromSession } = this.props;
    const {
      cardNumber,
      name,
      expirationMonth,
      expirationYear,
      cvv,
      communityMember,
      email
    } = this.state;
    const {
      auth_token: authToken,
      id: communityMemberId,
      name: existingName,
      email: existingEmail
    } = communityMember;
    if (
      existingName &&
      existingEmail &&
      expirationMonth !== 'MM' &&
      expirationYear !== 'YYYY' &&
      cardNumber.length >= 15 &&
      cardNumber.length < 17 &&
      cvv.length >= 3 &&
      cvv.length < 5
    ) {
      const deviceSessionId = window.OpenPay.deviceData.setup();
      actionsFromSession.addCreditCard(
        communityMemberId,
        authToken,
        cardNumber,
        existingName,
        expirationYear.substring(2, 4),
        expirationMonth,
        cvv,
        deviceSessionId,
        this.getCrediCards,
        this.dispatchCommunityMember
      );
    } else if (
      expirationMonth !== 'MM' &&
      expirationYear !== 'YYYY' &&
      name.length > 0 &&
      email.length > 0 &&
      cardNumber.length >= 15 &&
      cardNumber.length < 17 &&
      cvv.length >= 3 &&
      cvv.length < 5
    ) {
      const deviceSessionId = window.OpenPay.deviceData.setup();
      updateUserForPaymentsService(
        communityMemberId,
        authToken,
        name,
        email
      ).then(() => {
        actionsFromSession.addCreditCard(
          communityMemberId,
          authToken,
          cardNumber,
          name,
          expirationYear.substring(2, 4),
          expirationMonth,
          cvv,
          deviceSessionId,
          this.getCrediCards,
          this.dispatchCommunityMember()
        );
      });
    } else {
      if (expirationMonth === 'MM') {
        this.setState({ expirationMonthError: true });
      }
      if (expirationYear === 'YYYY') {
        this.setState({ expirationYearError: true });
      }
      if (name.length === 0) {
        this.setState({ nameError: true });
      }
      if (email.length === 0) {
        this.setState({ emailError: true });
      }
      if (cardNumber.length < 15) {
        this.setState({ cardNumberError: true });
      }
      if (cvv.length < 3) {
        this.setState({ cvvError: true });
      }
    }
  };

  getCrediCards = (communityMember) => {
    const { dispatch } = this.props;
    const { auth_token: authToken, id: communityMemberId } = communityMember;
    getUserCreditCardsService(communityMemberId, authToken)
      .then((res) => res.json())
      .then((json) => {
        const { errors = [], credit_cards: creditCards } = json;
        if (errors.length === 0) {
          dispatch(sessionActions.getUserCreditCardsSuccess(creditCards));
          if (creditCards.length === 0) {
            this.dispatchCommunityMember();
          } else {
            this.dispatchCommunityMember();
          }
        }
      });
  };

  dispatchCommunityMember = () => {
    const { cookies, closeLoginModal, dispatch } = this.props;
    const { communityMember } = this.state;
    dispatch(sessionActions.verifyCodeSuccess(communityMember));
    const {
      auth_token: authToken,
      id: communityMemberId,
      name
    } = communityMember;
    console.log('communityMember', communityMember);
    cookies.set('authToken', authToken, {
      path: '/',
      maxAge: 31536000
    });
    cookies.set('communityMemberId', communityMemberId, {
      path: '/',
      maxAge: 31536000
    });
    cookies.set('name', name, {
      path: '/',
      maxAge: 31536000
    });
    sessionStorage.setItem('authToken', authToken, {
      path: '/',
      maxAge: 31536000
    });
    sessionStorage.setItem('communityMemberId', communityMemberId, {
      path: '/',
      maxAge: 31536000
    });
    sessionStorage.setItem('name', name, {
      path: '/',
      maxAge: 31536000
    });
    closeLoginModal();
  };

  render() {
    const { classes, loginModal, closeLoginModal, loading, error } = this.props;
    const {
      sabaRewardsTitleTwo,
      sabaRewardsTitleOne,
      signInTitle,
      countryCodeLabel,
      enterPhoneLabel,
      enterPhonePlaceholder,
      checkText,
      checkTextTwo,
      privacyPolicy,
      termsConditions,
      continueLabel,
      verificationTitle,
      messageSent,
      enterCodeLabel,
      verifyButton,
      saveNewCard,
      cardNumberLabel,
      cvvLabel,
      nameOnCard,
      expirationLabel,
      emailLabel
    } = strings;
    const {
      country,
      phone,
      privacyCheck,
      phoneError,
      countryError,
      privacyCheckError,
      step,
      cardNumber,
      name,
      expirationMonth,
      expirationYear,
      cvv,
      expirationMonthError,
      expirationYearError,
      nameError,
      cardNumberError,
      cvvError,
      email,
      emailError,
      communityMember,
      value
    } = this.state;
    const { name: existingName, email: existingEmail } = communityMember;
    const expirationMonthClass = expirationMonthError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const expirationYearClass = expirationYearError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const nameClass = nameError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const emailClass = emailError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const cardNumberClass = cardNumberError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const cvvClass = cvvError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    let actualYear = moment().format('YYYY');
    actualYear = moment().year();
    const monthOptions = [
      'MM',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ];
    const yearOptions = [
      'YYYY',
      actualYear.toString(),
      (actualYear + 1).toString(),
      (actualYear + 2).toString(),
      (actualYear + 3).toString(),
      (actualYear + 4).toString(),
      (actualYear + 5).toString(),
      (actualYear + 6).toString(),
      (actualYear + 7).toString()
    ];
    const checkBoxClass = privacyCheckError
      ? { root: classes.checkBoxRootError }
      : { root: classes.checkBoxRoot };
    const phoneClass = phoneError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const countryClass = countryError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    return (
      // <Modal open={loginModal} onClose={closeLoginModal}>
      <Modal open={loginModal}>
        <Grid
          container
          direction="column"
          justify={step === 2 ? 'flex-start' : 'space-around'}
          alignItems="center"
          wrap="nowrap"
          className={classes.loginModalContainer}
        >
          {loading ? (
            <CircularProgress className={classes.primaryLoading} />
          ) : (
            <>
              {step === 0 && (
                <>
                  {/* <img
                    src={sabaLogo}
                    alt="Nimanic"
                    className={classes.nimanicLogo}
                  />
                  <h1 className={classes.sabaTitleOne}>{sabaRewardsTitleOne}&reg; <span className={classes.sabaTitleTwo}>{sabaRewardsTitleTwo}</span></h1> */}
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.smContentContainer}
                  >
                    <h1 className={classes.sabaTitleModalVer}>{signInTitle}</h1>
                    <Grid container justify="space-between" alignItems="center">
                      <p className={classes.countryCodeLabel}>
                        {countryCodeLabel}
                      </p>
                      <p className={classes.phoneLabel}>{enterPhoneLabel}</p>
                    </Grid>
                    <Grid container justify="space-between" alignItems="center">
                      <FormControl className={classes.countryCodeInput}>
                        <Select
                          value={country}
                          onChange={(e) => this.handleChange(e, 'country')}
                          onFocus={() => this.handleFocus('country')}
                          error={countryError}
                          input={<CustomSelect />}
                          classes={countryClass}
                        >
                          {countryCodeMenuOptions.map(
                            ({ menuOption, optionValue }) => (
                              <MenuItem key={optionValue} value={optionValue}>
                                {menuOption}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.phoneInput}>
                        <CustomInput
                          placeholder={enterPhonePlaceholder}
                          value={phone}
                          onChange={(e) => this.handleChange(e, 'phone')}
                          onFocus={() => this.handleFocus('phone')}
                          error={phoneError}
                          className={classes.select}
                          classes={phoneClass}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="flex-start"
                      className={classes.checkContainerModalVer}
                    >
                      <Checkbox
                        value={privacyCheck}
                        color="primary"
                        onChange={this.toggleCheckbox}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircleOutline />}
                        classes={checkBoxClass}
                      />
                      <p className={classnames(classes.bodySmallText, classes.checkTextModal)}>
                        {`${checkText} `}
                        <a
                          href={privacyPolicyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.bodySmallLink}
                        >
                          {privacyPolicy}
                        </a>
                        {` `}
                        {`${checkTextTwo}`}
                        {` `}
                        <a
                          href={termsAndConditionsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.bodySmallLink}
                        >
                          {termsConditions}
                        </a>
                      </p>
                      {/* <p
                        className={classnames(
                          classes.bodySmallText,
                          classes.checkTextModal
                        )}
                      >
                        {`${checkText} `}
                        <a
                          href={privacyPolicyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.bodySmallLink}
                        >
                          {privacyPolicy}
                        </a>
                        {`, `}
                        <a
                          href={termsAndConditionsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.bodySmallLink}
                        >
                          {termsConditions}
                        </a>
                      </p> */}
                    </Grid>
                    <Button size="md" type="primary" action={this.handleSubmit}>
                      {continueLabel}
                    </Button>
                  </Grid>
                </>
              )}
              {step === 1 && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <ChevronLeft
                      onClick={this.goToPrevStep}
                      className={classes.modalHeaderLeft}
                    />
                    <h1
                      className={classnames(
                        classes.bodyDefaultTitle,
                        classes.modalHeaderCenter
                      )}
                    >
                      {verificationTitle}
                    </h1>
                    <Grid className={classes.modalHeaderRight} />
                  </Grid>
                  <p
                    className={classnames(
                      classes.bodyDefaultText,
                      classes.centered
                    )}
                  >
                    {`${messageSent} ${phone}`}
                  </p>
                  <p
                    className={classnames(
                      classes.bodyDefaultText,
                      classes.centered
                    )}
                  >
                    {enterCodeLabel}
                  </p>
                  <p
                    className={classnames(
                      classes.bodyDefaultError,
                      classes.centered,
                      !error && classes.visibilityHidden
                    )}
                  >
                    {error.status}
                  </p>
                  <CodeInput
                    value={value}
                    handleChange={this.handleCodeChange}
                    handleKeyUp={this.handleKeyUp}
                  />
                  <Grid className={classes.mdButtonContainer}>
                    <Button
                      size="md"
                      type="primary"
                      action={this.handleSubmitCode}
                    >
                      {verifyButton}
                    </Button>
                  </Grid>
                </>
              )}
              {/* {step === 2 && (
                <>
                  <p className={classes.fullWidthInputLabel}>
                    {cardNumberLabel}
                  </p>
                  <FormControl className={classes.fullWidthInput}>
                    <CustomInput
                      value={cardNumber}
                      onChange={(e) => this.handleChange(e, 'cardNumber')}
                      className={classes.select}
                      onFocus={() => this.handleFocus('cardNumber')}
                      error={cardNumberError}
                      classes={cardNumberClass}
                    />
                  </FormControl>
                  {!existingName && (
                    <>
                      <p className={classes.fullWidthInputLabel}>
                        {nameOnCard}
                      </p>
                      <FormControl className={classes.fullWidthInput}>
                        <CustomInput
                          value={name}
                          onChange={(e) => this.handleChange(e, 'name')}
                          className={classes.select}
                          onFocus={() => this.handleFocus('name')}
                          error={nameError}
                          classes={nameClass}
                        />
                      </FormControl>
                    </>
                  )}
                  {!existingEmail && (
                    <>
                      <p className={classes.fullWidthInputLabel}>
                        {emailLabel}
                      </p>
                      <FormControl className={classes.fullWidthInput}>
                        <CustomInput
                          value={email}
                          onChange={(e) => this.handleChange(e, 'email')}
                          className={classes.select}
                          onFocus={() => this.handleFocus('email')}
                          error={emailError}
                          classes={emailClass}
                        />
                      </FormControl>
                    </>
                  )}
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    direction="row"
                    wrap="nowrap"
                    className={classes.fullWidthInputLabel}
                  >
                    <p className={classes.expirationLabel}>{expirationLabel}</p>
                    <p className={classes.cvvLabel}>{cvvLabel}</p>
                  </Grid>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    direction="row"
                    wrap="nowrap"
                    className={classes.fullWidthInputLabel}
                  >
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      direction="row"
                      wrap="nowrap"
                      className={classes.expirationInputs}
                    >
                      <FormControl className={classes.expirationInput}>
                        <Select
                          placeholder="MM"
                          value={expirationMonth}
                          onChange={(e) => {
                            this.handleChange(e, 'expirationMonth');
                          }}
                          input={<CustomSelect />}
                          onFocus={() => this.handleFocus('expirationMonth')}
                          error={expirationMonthError}
                          classes={expirationMonthClass}
                        >
                          {monthOptions.map((monthOption) => (
                            <MenuItem key={monthOption} value={monthOption}>
                              {monthOption}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.expirationInput}>
                        <Select
                          placeholder="YYYY"
                          value={expirationYear}
                          onChange={(e) => {
                            this.handleChange(e, 'expirationYear');
                          }}
                          input={<CustomSelect />}
                          onFocus={() => this.handleFocus('expirationYear')}
                          error={expirationYearError}
                          classes={expirationYearClass}
                        >
                          {yearOptions.map((yearOption) => (
                            <MenuItem key={yearOption} value={yearOption}>
                              {yearOption}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <FormControl className={classes.cvvInput}>
                      <CustomInput
                        value={cvv}
                        onChange={(e) => this.handleChange(e, 'cvv')}
                        className={classes.select}
                        onFocus={() => this.handleFocus('cvv')}
                        error={cvvError}
                        classes={cvvClass}
                      />
                    </FormControl>
                  </Grid>
                  <Button size="md" type="primary" action={this.saveCard}>
                    {saveNewCard}
                  </Button>
                </>
              )} */}
            </>
          )}
        </Grid>
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  history: shape({}).isRequired,
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  dispatch: func.isRequired,
  loginModal: bool.isRequired,
  closeLoginModal: func.isRequired,
  sessionActions: shape({}).isRequired,
  country: string.isRequired,
  phone: string.isRequired,
  loading: bool.isRequired,
  error: oneOfType([string, shape({})]).isRequired,
  checkPaymentMethods: bool,
  name: string,
  authToken: string
};

LoginModal.defaultProps = {
  checkPaymentMethods: false,
  name: '',
  authToken: ''
};

const mapStateoProps = (state) => {
  const {
    country,
    phone,
    loading: sessionLoading,
    error,
    communityMember = {}
  } = state.session;
  const { name, auth_token: authToken } = communityMember;
  const { loading: vendingMachineLoading } = state.vendingMachine;
  const loading = sessionLoading || vendingMachineLoading;
  return {
    country,
    phone,
    loading,
    error,
    name,
    authToken
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  dispatch
});

export default connect(
  mapStateoProps,
  mapDispatchToProps
)(withStyles(styles)(LoginModal));
