import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';
import { shape, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';

const DealInfo = ({ classes, deal, language }) => {
  const {
    cash_paid: cashPaid,
    deal_date: dealDate,
    image,
    name,
    product_server_name: productServerName,
    type,
    user_code: userCode
  } = deal;
  moment.locale(language);
  const dealDateWithFormat = moment(dealDate).format('ddd D HH:mm');
  if (!name) {
    return null;
  }
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      direction="row"
      className={classes.dealContainer}
    >
      <img src={image} alt={name} className={classes.dealProductImage} />
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        direction="column"
        className={classes.dealCenterContainer}
      >
        <p className={classes.dealBoldText}>{name}</p>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
        >
          <p className={classes.dealSmallText}>{dealDateWithFormat}</p>
          <p className={classes.dealSmallText}>{`@${productServerName}`}</p>
          <p className={classes.dealSmallText}>{`sel.${userCode}`}</p>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        alignItems="flex-end"
        direction="column"
        className={classes.dealRightContainer}
      >
        <p className={classes.dealBoldText}>{`${cashPaid}pts`}</p>
        {/* <p className={classes.dealSmallText}>{type}</p> */}
      </Grid>
    </Grid>
  );
};

DealInfo.propTypes = {
  classes: shape({}).isRequired,
  deal: shape({}).isRequired,
  language: string.isRequired
};

export default withStyles(styles)(DealInfo);
