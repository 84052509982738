import {
  SEND_TICKET_PHOTO_FAIL,
  SEND_TICKET_PHOTO_REQUEST,
  SEND_TICKET_PHOTO_SUCCESS,
  VERIFY_TICKET_FAIL,
  VERIFY_TICKET_REQUEST,
  VERIFY_TICKET_SUCCESS,
  SEND_REVISION_REQUEST,
  SEND_REVISION_SUCCESS,
  SEND_REVISION_FAIL,
} from "../actions/tickets/types";

const initialState = {
  ticket: {},
  error: "",
  processingPhoto: false,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_TICKET_REQUEST:
      return {
        ...state,
        ticket: {},
      };
    case VERIFY_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
      };
    case VERIFY_TICKET_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case SEND_TICKET_PHOTO_REQUEST:
      return {
        ...state,
        processingPhoto: true,
      };
    case SEND_TICKET_PHOTO_SUCCESS:
      return {
        ...state,
        processingPhoto: false,
        ticket: action.ticket,
      };
    case SEND_TICKET_PHOTO_FAIL:
      return {
        ...state,
        error: action.error,
        processingPhoto: false,
      };
      case SEND_REVISION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_REVISION_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
        isLoading: false,
      };
    case SEND_REVISION_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
