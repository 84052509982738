import React from 'react';
import { Grid } from '@material-ui/core';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';

const PageWrapper = ({
  RouteComponent,
  cookies,
  classes,
  history,
  location,
  match
}) => (
  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    className={classes.bodyContainer}
  >
    <RouteComponent
      cookies={cookies}
      history={history}
      location={location}
      match={match}
    />
  </Grid>
);

PageWrapper.propTypes = {
  RouteComponent: shape({}).isRequired,
  cookies: shape({}).isRequired,
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  location: shape({}).isRequired,
  match: shape({}).isRequired
};

export default withStyles(styles)(PageWrapper);
