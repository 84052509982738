import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

/* eslint-disable jsx-a11y/media-has-caption */

class RefreshPageModal extends Component {
  handleAction = () => {
    window.location.reload(false);
  };

  render() {
    const { classes, refreshPageModal, closeRefreshPageModal } = this.props;
    const { connectionLostToKeyboard, reconnectToKeyboard } = strings;
    return (
      <Modal open={refreshPageModal} onClose={closeRefreshPageModal}>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          className={classes.customMessageModalContainer}
        >
          <h1 className={classes.bodyDefaultTitle}>
            {connectionLostToKeyboard}
          </h1>
          <p className={classes.bodyDefaultText}>{reconnectToKeyboard}</p>

          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <Button size="md" type="primary" action={this.handleAction}>
              <Refresh />
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

RefreshPageModal.propTypes = {
  classes: shape({}).isRequired,
  refreshPageModal: bool.isRequired,
  closeRefreshPageModal: func.isRequired
};

export default withStyles(styles)(RefreshPageModal);
