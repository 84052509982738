import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, func, bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import Profile from '../Profile';
import MenuOptions from '../MenuOptions';

const LeftMenu = ({ classes, toggleMenu, isOpen, goTo }) => {
  if (isOpen) {
    return (
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        className={classes.menuContainer}
        onClick={toggleMenu}
      >
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          direction="column"
          className={classes.menu}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Profile goTo={goTo} />
          <MenuOptions goTo={goTo} />
        </Grid>
      </Grid>
    );
  }
  return null;
};

LeftMenu.propTypes = {
  classes: shape({}).isRequired,
  toggleMenu: func.isRequired,
  isOpen: bool.isRequired,
  goTo: func.isRequired
};

export default withStyles(styles)(LeftMenu);
