export const STAGING_API = 'http://staging.nimanic.io';
export const PRODUCTION_API = 'https://apis.nimanic.io';

export const STAGING_OPENPAY = 'https://sandbox-api.openpay.mx/v1';
export const PRODUCTION_OPENPAY = 'https://api.openpay.mx/v1';

export const STAGING_MERCHANT_ID = 'm58exb7xsxc2cazbujgn';
export const PRODUCTION_MERCHANT_ID = 'mrhwaxrzja4rucqx7kht';

export const STAGING_API_KEY = 'pk_bc80ebd8a0084ef58ce3962ac1aad463';
export const PRODUCTION_API_KEY = 'pk_b01dab9b2fb84f1abcd7c40c9f134c3f';

const api = {
  staging: STAGING_API,
  production: PRODUCTION_API
};
export const API = api[process.env.REACT_APP_ENV];

const openpay = {
  staging: STAGING_OPENPAY,
  production: PRODUCTION_OPENPAY
};
export const OPENPAY = openpay[process.env.REACT_APP_ENV];

const merchantId = {
  staging: STAGING_MERCHANT_ID,
  production: PRODUCTION_MERCHANT_ID
};
export const MERCHANT_ID = merchantId[process.env.REACT_APP_ENV];

const apiKey = {
  staging: STAGING_API_KEY,
  production: PRODUCTION_API_KEY
};
export const API_KEY = apiKey[process.env.REACT_APP_ENV];
