import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number } from 'prop-types';
import { Grid } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Header from '../../Header';
import sessionActions from '../../../actions/session';
import styles from '../../../styles';
import strings from '../../../language';

// const retrieve = require('../../../assets/cafe_final.png');
const retrieve = require('../../../assets/retrieve.png');

class DownloadPage extends Component {
  componentDidMount() {
    const {
      authToken,
      communityMemberId,
      sessionActions: actionsFromSession
    } = this.props;
    if (!authToken) {
      this.goTo('/');
    } else {
      actionsFromSession.getMemberNewBalance(communityMemberId, authToken);
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, selectedVendingMachineName } = this.props;
    const { downloadSuccessful, retrieveProduct } = strings;
    return (
      <Grid
        className={classes.containerWithHeader}
        container
        justify="flex-start"
        alignItems="flex-start"
      >
        <Header
          leftContent={<ChevronLeft />}
          leftAction={() => this.goTo('/machine-inventory')}
          type="primary"
        />
        <Grid
          container
          className={classes.bodyWithHeaderContainer}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <div
            className={classes.bodyDownloadText}
            style={{ marginTop: '10%' }}
          >
            <h1 className={classes.titleSecColor}>{downloadSuccessful}</h1>
            <p className={classes.bodyDefaultText}>{retrieveProduct}</p>
            <p className={classes.downloadFromMachineName}>
              {`@${selectedVendingMachineName}`}
            </p>
          </div>
          <img src={retrieve} alt="" className={classes.connectingImage} />
        </Grid>
      </Grid>
    );
  }
}

DownloadPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  selectedVendingMachineName: string,
  authToken: string,
  communityMemberId: number
};

DownloadPage.defaultProps = {
  selectedVendingMachineName: '',
  authToken: '',
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const { selectedVendingMachine } = state.vendingMachine;
  const { name: selectedVendingMachineName } = selectedVendingMachine;
  return {
    selectedVendingMachineName,
    authToken,
    communityMemberId
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DownloadPage));
