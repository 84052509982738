import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func, string } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

/* eslint-disable jsx-a11y/media-has-caption */

const nimanicLogo = require('../../assets/icon.png');
const nestleLogo = require('../../assets/Nescafe-Logo.png');

class NativeAppModal extends Component {
  handleAction = () => {
    const { os } = this.props;
    if (os === 'iOS') {
      window.open('https://apps.apple.com/mx/app/nimanic/id983702352');
    } else if (os === 'Android') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.nimanic.nimanic'
      );
    }
  };

  secondHandleAction = () => {
    const { cookies } = this.props;
    cookies.set('dontShowNativeAppModal', true, {
      path: '/',
      maxAge: 31536000
    });
  };

  render() {
    const { classes, nativeAppModal, closeNativeAppModal } = this.props;
    const { downloadApp, downloadLabel, dontShowAgain } = strings;
    return (
      <Modal open={nativeAppModal} onClose={closeNativeAppModal}>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          className={classes.customMessageModalContainer}
        >
          <h1 className={classes.bodyDefaultTitle}>{downloadApp}</h1>
          <img
            src={nimanicLogo}
            alt="Nimanic"
            className={classes.nimanicAppLogo}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <Button size="md" type="primary" action={this.handleAction}>
              {downloadLabel}
            </Button>
            <Button size="md" type="secondary" action={this.secondHandleAction}>
              {dontShowAgain}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

NativeAppModal.propTypes = {
  classes: shape({}).isRequired,
  os: string,
  nativeAppModal: bool.isRequired,
  closeNativeAppModal: func.isRequired,
  cookies: shape({}).isRequired
};

NativeAppModal.defaultProps = {
  os: ''
};

export default withStyles(styles)(NativeAppModal);
