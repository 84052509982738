import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, bool, number } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/es';
import {
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CompleteVerificationModal from '../../Modals/CompleteVerificationModal';
import sessionActions from '../../../actions/session';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import styles from '../../../styles';
import strings from '../../../language';
import Button from '../../Button';
import OutlinedButton from '../../Button/OutlinedButton';

const genderOptions = [
  { menuOption: 'Hombre', optionValue: 'Hombre' },
  { menuOption: 'Mujer', optionValue: 'Mujer' },
  { menuOption: 'Otro', optionValue: 'Otro' }
];

class RegistrationPage extends Component {
  constructor(props) {
    super(props);
    const { name, email, birthDate, gender } = this.props;
    this.state = {
      name,
      email,
      birthDate: this.formatDate(birthDate) || '',
      gender: gender || '',
      nameError: false,
      emailError: false,
      birthDateError: false,
      genderError: false,
      completeVerificationModal: false,
      male: false,
      female: false,
      otherGender: false,
    };
  }

  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  showCompleteVerificationModal = () => {
    this.setState({ completeVerificationModal: true });
  };

  hideCompleteVerificationModal = () => {
    this.getMemberInfo();
    this.setState({ completeVerificationModal: false });
  };

  getMemberInfo = () => {
    const {
      selectedProduct,
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken,
      cookies
    } = this.props;
    let goTo = () => {
      this.goTo("/machine-inventory");
    };
    if (JSON.stringify(selectedProduct) !== '{}') {
      goTo = () => {
        this.goTo(`/product/${selectedProduct.id}`);
      };
    }
    actionsFromSession.getMemberInfo(
      communityMemberId,
      authToken,
      cookies,
      goTo
    );
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  handleGenderChange = (gender) => {
      this.setState({gender});
  }

  handleDateChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    if (value.length === 2 || value.length === 5) {
      this.setState({ [id]: `${value}/` });
    } else {
      this.setState({ [id]: value });
    }
  };

  handleFocus = (id) => {
    this.setState({ [`${id}Error`]: false });
  };

  resendMailVerification = () => {
    const {
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken
    } = this.props;
    actionsFromSession.resendEmailVerification(
      communityMemberId,
      authToken,
      this.showCompleteVerificationModal
    );
  };

  formatDate = (date) => {
    if (date) {
      const newDate = new Date(date);
      const formatedDate = newDate.toISOString().split('T')[0];
      console.log('date', formatedDate);
      return formatedDate;
    }
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   const {
  //     sessionActions: actionsFromSession,
  //     communityMemberId,
  //     authToken,
  //     cookies,
  //     email: emailFromProps
  //   } = this.props;
  //   const { email, birthDate, gender } = this.state;
  //   let callback = this.getMemberInfo;
  //   // Check later
  //   if (emailFromProps !== email) {
  //     callback = this.showCompleteVerificationModal;
  //   }
  //   if (email && birthDate && gender) {
  //     actionsFromSession.updateUser(
  //       communityMemberId,
  //       authToken,
  //       "user",
  //       birthDate,
  //       gender,
  //       email,
  //       cookies,
  //       callback
  //     );
  //   } else {
  //     if (!email) {
  //       this.setState({ emailError: true });
  //     }
  //   }
  // };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken,
      cookies,
      // email: emailFromProps
    } = this.props;
    const { birthDate, gender, name, email } = this.state;
    let callback = this.getMemberInfo;
    // Check later
    // if (emailFromProps !== email) {
    //   callback = this.showCompleteVerificationModal;
    // }
    if (birthDate && gender) {
      actionsFromSession.updateUser(
        communityMemberId,
        authToken,
        name ? name : "user",
        birthDate,
        gender,
        email ? email : null,
        cookies,
        callback
      );
    } 
    // else {
    //   if (!email) {
    //     this.setState({ emailError: true });
    //   }
    // }
    this.getMemberInfo();
  };

  render() {
    const { classes, loading, email: emailFromProps } = this.props;
    const {
      name,
      email,
      birthDate,
      gender,
      nameError,
      emailError,
      birthDateError,
      genderError,
      completeVerificationModal,
      male,
      female,
      otherGender,
    } = this.state;
    const {
      saveLabel,
      enterNamePlaceholder,
      birthDatePlaceholder,
      enterEmailPlaceholder,
      nameLabel,
      emailLabel,
      birthDateLabel,
      genderLabel,
      resendMailVerification,
      signUp,
      selectYourGender,
      man,
      woman,
      other,
    } = strings;
    const headerRightContent = (
      <p className={classes.headerDefaultText}>{saveLabel.toUpperCase()}</p>
    );
    const nameClass = nameError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const emailClass = emailError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const birthDateClass = birthDateError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const genderClass = genderError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <div className={classes.registerMainContainer}>
        <CompleteVerificationModal
          completeVerificationModal={completeVerificationModal}
          hideCompleteVerificationModal={this.hideCompleteVerificationModal}
        />
        {/* <div
          className={classes.registerMainContainer}
        > */}
        {/* <Header
            leftContent={<Clear />}
            leftAction={() => {
              this.goTo('/profile');
            }}
            rightContent={headerRightContent}
            rightAction={this.handleSubmit}
            type="secondary"
          /> */}
        <div className={classes.genderSecContainer}>
          <h1 className={classes.sabaTitleRegistration}>{signUp}</h1>
          <div className={classes.genderContainer}>
            <h4 className={classes.noMargin}>{selectYourGender}*</h4>
            <Button className={classes.noMargin} size="md" type={gender === 'Hombre' ? "primary" : "outlined"} action={() => this.handleGenderChange('Hombre')}>
              {man}
            </Button>
            <Button className={classes.noMargin} size="md" type={gender === 'Mujer' ? "primary" : "outlined"} action={() => this.handleGenderChange('Mujer')}>
              {woman}
            </Button>
            <Button className={classes.noMargin} size="md" type={gender === 'Otro' ? "primary" : "outlined"} action={() => this.handleGenderChange('Otro')}>
              {other}
            </Button>
          </div>
          {/* <h4 className={classes.subtitleProfileTwo}>{nameLabel}</h4> */}
          {/* <p
            className={classnames(
              classes.fullWidthInputLabel,
              classes.noMargin
            )}
          >
            {nameLabel}
          </p> */}
          {/* <FormControl className={classes.nameInput}>
            <CustomInput
              placeholder={enterNamePlaceholder}
              value={name}
              onChange={(e) => this.handleChange(e, 'name')}
              onFocus={() => this.handleFocus('name')}
              error={nameError}
              className={classes.select}
              classes={nameClass}
            />
          </FormControl> */}
          {/* <Grid
            container
            justify="space-between"
            className={classes.twoColInput}
          > */}
          <h4 className={classes.subtitlesProfile}>{birthDateLabel}*</h4>
          {/* <p className={classes.halfWidthInputLabel}>{birthDateLabel}</p> */}
          {/* <p className={classes.halfWidthInputLabel}>{genderLabel}</p> */}
          {/* </Grid>
          <Grid
            container
            justify="space-between"
            className={classes.twoColInput}
          > */}
          <FormControl className={classes.birthDateInput}>
            <CustomInput
              placeholder={birthDatePlaceholder}
              value={birthDate}
              type="date"
              onChange={(e) => this.handleDateChange(e, 'birthDate')}
              onFocus={() => this.handleFocus('birthDate')}
              error={birthDateError}
              className={classes.select}
              classes={birthDateClass}
            />
          </FormControl>
          {/* <FormControl className={classes.genderInput}>
              <Select
                value={gender}
                onChange={(e) => this.handleChange(e, 'gender')}
                onFocus={() => this.handleFocus('gender')}
                error={genderError}
                input={<CustomSelect />}
                classes={genderClass}
              >
                {genderOptions.map(({ menuOption, optionValue }) => (
                  <MenuItem key={optionValue} value={optionValue}>
                    {menuOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          {/* </Grid> */}
          {/* <h4 className={classes.subtitlesProfile}>{emailLabel}</h4> */}
          {/* <p className={classes.fullWidthInputLabel}>{emailLabel}</p> */}
          {/* <FormControl className={classes.emailInput}>
            <CustomInput
              placeholder={enterEmailPlaceholder}
              value={email}
              onChange={(e) => this.handleChange(e, 'email')}
              onFocus={() => this.handleFocus('email')}
              error={emailError}
              className={classes.select}
              classes={emailClass}
            />
          </FormControl> */}
          <div className={classes.saveButtonProfile}>
            <Button size="md" type="primary" action={this.handleSubmit}>
              {saveLabel}
            </Button>
          </div>
        </div>
        {/* {emailFromProps ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.resendMailVerificationContainer}
          >
            <p onClick={this.resendMailVerification}>
              {resendMailVerification}
            </p>
          </Grid>
        ) : <></>} */}
        {/* </div> */}
      </div>
    );
  }
}

RegistrationPage.propTypes = {
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  loading: bool.isRequired,
  name: string,
  email: string | null,
  birthDate: string,
  gender: string,
  communityMemberId: number,
  selectedProduct: shape({}).isRequired
};

RegistrationPage.defaultProps = {
  authToken: '',
  name: '',
  email: null,
  birthDate: '',
  gender: '',
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { communityMember, loading } = state.session;
  const {
    id: communityMemberId,
    auth_token: authToken,
    name,
    email,
    birth_date: birthDate,
    balance,
    gender,
    last_transaction: lastTransaction
  } = communityMember;
  const { selectedProduct } = state.vendingMachine;
  return {
    authToken,
    communityMemberId,
    loading,
    name,
    email,
    birthDate,
    balance,
    gender,
    lastTransaction,
    selectedProduct
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RegistrationPage));
