import * as types from "./types";
import {
  getVerificationCodeService,
  getMemberInfoService,
  verifyCodeService,
  getMemberBalanceService,
  getUserCreditCardsService,
  rechargeBalanceService,
  deletePaymentMethodService,
  addCreditCardOpenpayService,
  addCreditCardService,
  addEdenredCardService,
  getDealsHistoryService,
  updateUserService,
  resendEmailVerificationService,
  updateTopUpInfoService,
  getMemberConfigurationsService,
  updateMemberConfigurationsService,
  sendRatingService,
  getCommunityMemberMessagesService,
} from "../../services/session";

const getVerificationCodeRequest = () => ({
  type: types.GET_VERIFICATION_CODE_REQUEST,
});

const getVerificationCodeSuccess = (phone, country) => ({
  type: types.GET_VERIFICATION_CODE_SUCCESS,
  phone,
  country,
});

const getVerificationCodeFail = (error) => ({
  type: types.GET_VERIFICATION_CODE_FAIL,
  error,
});

const getVerificationCode = (phone, country, goTo) => (dispatch) => {
  dispatch(getVerificationCodeRequest());
  getVerificationCodeService(phone, country)
    .then((res) => {
      const responseStatus = res.status;
      if (responseStatus === 204 || responseStatus === 200) {
        dispatch(getVerificationCodeSuccess(phone, country));
        goTo("/phone-verification");
      }
      return res.json();
    })
    .then((json) => {
      const { error = "" } = json;
      if (error) {
        dispatch(getVerificationCodeFail(error));
      }
    })
    .catch((error) => {
      dispatch(getVerificationCodeFail(error));
    });
};

const verifyCodeRequest = () => ({
  type: types.VERIFY_CODE_REQUEST,
});

const verifyCodeSuccess = (communityMember) => ({
  type: types.VERIFY_CODE_SUCCESS,
  communityMember,
});

const verifyCodeFail = (error) => ({
  type: types.VERIFY_CODE_FAIL,
  error,
});

const verifyCode =
  (phone, country, code, cookies, goTo = () => {}) =>
  (dispatch) => {
    dispatch(verifyCodeRequest());
    verifyCodeService(phone, country, code)
      .then((res) => res.json())
      .then((json) => {
        const { error = "", community_member: communityMember } = json;
        if (!error) {
          const {
            auth_token: authToken,
            id: communityMemberId,
            name,
          } = communityMember;
          dispatch(verifyCodeSuccess(communityMember));
          cookies.set("authToken", authToken, { path: "/", maxAge: 31536000 });
          cookies.set("communityMemberId", communityMemberId, {
            path: "/",
            maxAge: 31536000,
          });
          cookies.set("name", name, {
            path: "/",
            maxAge: 31536000,
          });
          sessionStorage.setItem("authToken", authToken, {
            path: "/",
            maxAge: 31536000,
          });
          sessionStorage.setItem("communityMemberId", communityMemberId, {
            path: "/",
            maxAge: 31536000,
          });
          sessionStorage.setItem("name", name, {
            path: "/",
            maxAge: 31536000,
          });
          goTo("machine-selection");
        } else {
          dispatch(verifyCodeFail(error));
        }
      })
      .catch((error) => {
        dispatch(verifyCodeFail(error));
      });
  };

const getMemberInfoRequest = () => ({
  type: types.GET_MEMBER_INFO_REQUEST,
});

const getMemberInfoSuccess = (communityMember) => ({
  type: types.GET_MEMBER_INFO_SUCCESS,
  communityMember,
});

const getMemberInfoFail = (error) => ({
  type: types.GET_MEMBER_INFO_FAIL,
  error,
});

const getMemberInfo =
  (communityMemberId, authToken, cookies, callback = () => {}) =>
  (dispatch) => {
    dispatch(getMemberInfoRequest());
    getMemberInfoService(communityMemberId, authToken)
      .then((res) => res.json())
      .then((json) => {
        const { error = "", community_member: communityMember } = json;
        if (!error) {
          dispatch(getMemberInfoSuccess(communityMember));
          cookies.set("authToken", authToken);
          cookies.set("communityMemberId", communityMemberId);
          sessionStorage.setItem("authToken", authToken);
          sessionStorage.setItem("communityMemberId", communityMemberId);
          callback();
        } else {
          dispatch(getMemberInfoFail(error));
        }
      })
      .catch((error) => {
        dispatch(getMemberInfoFail(error));
      });
  };

const getMemberBalanceRequest = () => ({
  type: types.GET_MEMBER_BALANCE_REQUEST,
});

const getMemberBalanceSuccess = (balances) => ({
  type: types.GET_MEMBER_BALANCE_SUCCESS,
  balances,
});

const getMemberBalanceFail = (error) => ({
  type: types.GET_MEMBER_BALANCE_FAIL,
  error,
});

const getMemberBalance = (communityMemberId, authToken) => (dispatch) => {
  dispatch(getMemberBalanceRequest());
  getMemberBalanceService(communityMemberId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { errors = [], balances } = json;
      if (errors.length === 0) {
        dispatch(getMemberBalanceSuccess(balances));
        console.log("balancesEndPoint", balances);
      } else {
        dispatch(getMemberBalanceFail(errors[0]));
      }
    })
    .catch((error) => {
      dispatch(getMemberBalanceFail(error));
    });
};

const getUserCreditCardsRequest = () => ({
  type: types.GET_USER_CREDIT_CARDS_REQUEST,
});

const getUserCreditCardsSuccess = (creditCards) => ({
  type: types.GET_USER_CREDIT_CARDS_SUCCESS,
  creditCards,
});

const getUserCreditCardsFail = (error) => ({
  type: types.GET_USER_CREDIT_CARDS_FAIL,
  error,
});

const getUserCreditCards =
  (communityMemberId, authToken, callback = () => {}) =>
  (dispatch) => {
    dispatch(getUserCreditCardsRequest());
    getUserCreditCardsService(communityMemberId, authToken)
      .then((res) => res.json())
      .then((json) => {
        const { errors = [], credit_cards: creditCards } = json;
        if (errors.length === 0) {
          dispatch(getUserCreditCardsSuccess(creditCards));
          callback();
        } else {
          dispatch(getUserCreditCardsFail(errors[0]));
        }
      })
      .catch((error) => {
        dispatch(getUserCreditCardsFail(error));
      });
  };

const getMemberNewBalanceRequest = () => ({
  type: types.GET_MEMBER_NEW_BALANCE_REQUEST,
});

const getMemberNewBalanceSuccess = (newBalance) => ({
  type: types.GET_MEMBER_NEW_BALANCE_SUCCESS,
  newBalance,
});

const getMemberNewBalanceFail = (error) => ({
  type: types.GET_MEMBER_NEW_BALANCE_FAIL,
  error,
});

const getMemberNewBalance = (communityMemberId, authToken) => (dispatch) => {
  dispatch(getMemberNewBalanceRequest());
  getMemberInfoService(communityMemberId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { error = "", community_member: communityMember } = json;
      const { balance: newBalance } = communityMember;
      if (!error) {
        dispatch(getMemberNewBalanceSuccess(newBalance));
      } else {
        dispatch(getMemberNewBalanceFail(error));
      }
    })
    .catch((error) => {
      dispatch(getMemberInfoFail(error));
    });
};

const rechargeBalanceRequest = () => ({
  type: types.RECHARGE_BALANCE_REQUEST,
});

const rechargeBalanceSuccess = (rechargedBalance) => ({
  type: types.RECHARGE_BALANCE_SUCCESS,
  rechargedBalance,
});

const rechargeBalanceFail = (error) => ({
  type: types.RECHARGE_BALANCE_FAIL,
  error,
});

const rechargeBalance =
  (
    communityMemberId,
    authToken,
    creditCard,
    amount,
    currency,
    getNewBalance,
    goTo
  ) =>
  (dispatch) => {
    dispatch(rechargeBalanceRequest());
    rechargeBalanceService(
      communityMemberId,
      authToken,
      creditCard,
      amount,
      currency
    )
      .then((res) => res.json())
      .then((json) => {
        const { error = "", top_up: rechargedBalance } = json;
        if (!error) {
          dispatch(rechargeBalanceSuccess(rechargedBalance));
          getNewBalance();
          goTo("/machine-inventory/true");
        } else {
          dispatch(rechargeBalanceFail(error));
        }
      })
      .catch((error) => {
        dispatch(rechargeBalanceFail(error));
      });
  };

const deletePaymentMethodRequest = () => ({
  type: types.DELETE_PAYMENT_METHOD_REQUEST,
});

const deletePaymentMethodSuccess = () => ({
  type: types.DELETE_PAYMENT_METHOD_SUCCESS,
});

const deletePaymentMethodFail = (error) => ({
  type: types.DELETE_PAYMENT_METHOD_FAIL,
  error,
});

const deletePaymentMethod =
  (communityMemberId, authToken, cardId, deletedCard, getCrediCards) =>
  (dispatch) => {
    dispatch(deletePaymentMethodRequest());
    deletePaymentMethodService(communityMemberId, authToken, cardId)
      .then((res) => res.json())
      .then((json) => {
        const { error = "" } = json;
        if (!error) {
          dispatch(deletePaymentMethodSuccess());
          getCrediCards();
        } else {
          dispatch(deletePaymentMethodFail(error));
        }
      })
      .catch((error) => {
        dispatch(deletePaymentMethodFail(error));
      });
  };

const addCreditCardRequest = () => ({
  type: types.ADD_CREDIT_CARD_REQUEST,
});

const addCreditCardSuccess = (addedCreditCard) => ({
  type: types.ADD_CREDIT_CARD_SUCCESS,
  addedCreditCard,
});

const addCreditCardFail = (error) => ({
  type: types.ADD_CREDIT_CARD_FAIL,
  error,
});

const addCreditCard =
  (
    memberId,
    authToken,
    cardNumber,
    holderName,
    expirationYear,
    expirationMonth,
    cvv2,
    deviceSessionId,
    getCrediCards,
    openCreatedCardModal
  ) =>
  (dispatch) => {
    dispatch(addCreditCardRequest());
    addCreditCardOpenpayService(
      cardNumber,
      holderName,
      expirationYear,
      expirationMonth,
      cvv2
    )
      .then((res) => res.json())
      .then((json) => {
        const { error = "", id: token, card } = json;
        const { brand } = card;
        if (!error) {
          addCreditCardService(
            memberId,
            authToken,
            token,
            deviceSessionId,
            holderName,
            brand
          )
            .then((res) => res.json())
            .then((json2) => {
              const { error: error2 = "", credit_card: creditCard } = json2;
              if (!error2) {
                dispatch(addCreditCardSuccess(creditCard));
                getCrediCards();
                openCreatedCardModal();
              } else {
                dispatch(addCreditCardFail(error));
              }
            })
            .catch((error2) => {
              dispatch(addCreditCardFail(error2));
            });
        } else {
          dispatch(addCreditCardFail(error));
        }
      })
      .catch((error) => {
        dispatch(addCreditCardFail(error));
      });
  };

const addEdenredCardRequest = () => ({
  type: types.ADD_EDENRED_CARD_REQUEST,
});

const addEdenredCardSuccess = (addedCreditCard) => ({
  type: types.ADD_EDENRED_CARD_SUCCESS,
  addedCreditCard,
});

const addEdenredCardFail = (error) => ({
  type: types.ADD_EDENRED_CARD_FAIL,
  error,
});

const addEdenredCard =
  (code, memberId, authToken, name, deviceSessionId, callback) =>
  (dispatch) => {
    dispatch(addEdenredCardRequest());
    addEdenredCardService(authToken, memberId, code, deviceSessionId, name)
      .then((res) => res.json())
      .then((json) => {
        const { credit_card: creditCard = {}, error = {} } = json;
        if (JSON.stringify(creditCard) !== "{}") {
          dispatch(addEdenredCardSuccess(creditCard));
          callback();
        }
        if (JSON.stringify(error) !== "{}") {
          dispatch(addEdenredCardFail(error));
        }
      })
      .catch((error) => {
        dispatch(addEdenredCardFail(error));
      });
  };

const getDealsHistoryRequest = () => ({
  type: types.GET_DEALS_HISTORY_REQUEST,
});

const getDealsHistorySuccess = (deals) => ({
  type: types.GET_DEALS_HISTORY_SUCCESS,
  deals,
});

const getDealsHistoryFail = (error) => ({
  type: types.GET_DEALS_HISTORY_FAIL,
  error,
});

const getDealsHistory = (communityMemberId, authToken) => (dispatch) => {
  dispatch(getDealsHistoryRequest());
  getDealsHistoryService(communityMemberId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { error = "", deals } = json;
      if (!error) {
        dispatch(getDealsHistorySuccess(deals));
      } else {
        dispatch(getDealsHistoryFail(error));
      }
    })
    .catch((error) => {
      dispatch(getDealsHistoryFail(error));
    });
};

const updateUserRequest = () => ({
  type: types.UPDATE_USER_REQUEST,
});

const updateUserSuccess = () => ({
  type: types.UPDATE_USER_SUCCESS,
});

const updateUserFail = (error) => ({
  type: types.UPDATE_USER_FAIL,
  error,
});

const updateUser =
  (
    communityMemberId,
    authToken,
    name,
    birthDate,
    gender,
    email,
    cookies,
    callback = () => {}
  ) =>
  (dispatch) => {
    dispatch(updateUserRequest());
    updateUserService(
      communityMemberId,
      authToken,
      name,
      birthDate,
      gender,
      email
    )
      .then((res) => {
        if (res.status === 204) {
          dispatch(updateUserSuccess());
          callback();
        } else {
          dispatch(updateUserFail());
        }
      })
      .catch((error) => {
        dispatch(updateUserFail(error));
      });
  };

const resendEmailVerification =
  (communityMemberId, authToken, callback = () => {}) =>
  (dispatch) => {
    dispatch(updateUserRequest());
    resendEmailVerificationService(communityMemberId, authToken)
      .then((res) => {
        if (res.status === 204) {
          dispatch(updateUserSuccess());
          callback();
        } else {
          dispatch(updateUserFail());
        }
      })
      .catch((error) => {
        dispatch(updateUserFail(error));
      });
  };

const updateTopUpInfoRequest = () => ({
  type: types.UPDATE_TOP_UP_INFO_REQUEST,
});

const updateTopUpInfoSuccess = () => ({
  type: types.UPDATE_TOP_UP_INFO_SUCCESS,
});

const updateTopUpInfoFail = (error) => ({
  type: types.UPDATE_TOP_UP_INFO_FAIL,
  error,
});

const updateTopUpInfo = (communityMemberId, authToken, body) => (dispatch) => {
  dispatch(updateTopUpInfoRequest());
  updateTopUpInfoService(communityMemberId, authToken, body)
    .then((res) => res.json())
    .then((json) => {
      const { error = "" } = json;
      if (!error) {
        dispatch(updateTopUpInfoSuccess());
      } else {
        dispatch(updateTopUpInfoFail(error));
      }
    })
    .catch((error) => {
      dispatch(updateTopUpInfoFail(error));
    });
};

const getMemberConfigurationsRequest = () => ({
  type: types.GET_MEMBER_CONFIGURATIONS_REQUEST,
});

const getMemberConfigurationsSuccess = () => ({
  type: types.GET_MEMBER_CONFIGURATIONS_SUCCESS,
});

const getMemberConfigurationsFail = (error) => ({
  type: types.GET_MEMBER_CONFIGURATIONS_FAIL,
  error,
});

const getMemberConfigurations =
  (communityMemberId, authToken) => (dispatch) => {
    dispatch(getMemberConfigurationsRequest());
    getMemberConfigurationsService(communityMemberId, authToken)
      .then((res) => res.json())
      .then((json) => {
        const { error = "" } = json;
        if (!error) {
          dispatch(getMemberConfigurationsSuccess());
        } else {
          dispatch(getMemberConfigurationsFail(error));
        }
      })
      .catch((error) => {
        dispatch(getMemberConfigurationsFail(error));
      });
  };

const updateMemberConfigurationsRequest = () => ({
  type: types.UPDATE_MEMBER_CONFIGURATIONS_REQUEST,
});

const updateMemberConfigurationsSuccess = () => ({
  type: types.UPDATE_MEMBER_CONFIGURATIONS_SUCCESS,
});

const updateMemberConfigurationsFail = (error) => ({
  type: types.UPDATE_MEMBER_CONFIGURATIONS_FAIL,
  error,
});

const updateMemberConfigurations =
  (communityMemberId, authToken, displayRatingModal) => (dispatch) => {
    dispatch(updateMemberConfigurationsRequest());
    updateMemberConfigurationsService(
      communityMemberId,
      authToken,
      displayRatingModal
    )
      .then((res) => res.json())
      .then((json) => {
        const { error = "" } = json;
        if (!error) {
          dispatch(updateMemberConfigurationsSuccess());
        } else {
          dispatch(updateMemberConfigurationsFail(error));
        }
      })
      .catch((error) => {
        dispatch(updateMemberConfigurationsFail(error));
      });
  };

const sendRatingRequest = () => ({
  type: types.SEND_RATING_REQUEST,
});

const sendRatingSuccess = () => ({
  type: types.SEND_RATING_SUCCESS,
});

const sendRatingFail = (error) => ({
  type: types.SEND_RATING_FAIL,
  error,
});

const sendRating = (communityMemberId, authToken, rating) => (dispatch) => {
  dispatch(sendRatingRequest());
  sendRatingService(communityMemberId, authToken, rating)
    .then((res) => res.json())
    .then((json) => {
      const { error = "" } = json;
      if (!error) {
        dispatch(sendRatingSuccess());
      } else {
        dispatch(sendRatingFail(error));
      }
    })
    .catch((error) => {
      dispatch(sendRatingFail(error));
    });
};

const getCommunityMemberMessagesRequest = () => ({
  type: types.GET_COMMUNITY_MEMBER_MESSAGES_REQUEST,
});

const getCommunityMemberMessagesSuccess = (customMessages) => ({
  type: types.GET_COMMUNITY_MEMBER_MESSAGES_SUCCESS,
  customMessages,
});

const getCommunityMemberMessagesFail = (error) => ({
  type: types.GET_COMMUNITY_MEMBER_MESSAGES_FAIL,
  error,
});

const getCommunityMemberMessages =
  (communityMemberId, authToken) => (dispatch) => {
    dispatch(getCommunityMemberMessagesRequest());
    getCommunityMemberMessagesService(communityMemberId, authToken)
      .then((res) => res.json())
      .then((json) => {
        const { error = "", community_member_messages: customMessages } = json;
        if (customMessages.length >= 0) {
          dispatch(getCommunityMemberMessagesSuccess(customMessages));
        } else {
          dispatch(getCommunityMemberMessagesFail(error));
        }
      })
      .catch((error) => {
        dispatch(getCommunityMemberMessagesFail(error));
      });
  };

const logout = (cookies) => (dispatch) => {
  sessionStorage.clear();
  cookies.remove("authToken");
  cookies.remove("communityMemberId");
  cookies.remove("selectedVendingMachineId");
  dispatch({
    type: types.LOGOUT,
  });
};

export default {
  getVerificationCode,
  getMemberInfo,
  verifyCode,
  verifyCodeSuccess,
  getMemberBalance,
  getUserCreditCards,
  getUserCreditCardsSuccess,
  getMemberNewBalance,
  rechargeBalance,
  deletePaymentMethod,
  addCreditCard,
  addEdenredCard,
  getDealsHistory,
  updateUser,
  resendEmailVerification,
  updateTopUpInfo,
  getMemberConfigurations,
  updateMemberConfigurations,
  sendRating,
  getCommunityMemberMessages,
  logout,
};
