import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, arrayOf, func } from 'prop-types';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import strings from '../../language';
import { produceCategories, trimmString } from '../../config/utils';

const MachineInventory = ({
  classes,
  productServerInventory,
  selectProduct
}) => {
  const { free } = strings;
  const vendingCategories = produceCategories(productServerInventory);
  return (
    <>
      {vendingCategories.map((category) => (
        <Grid container key={category.title} direction="column">
          <h3 className={classes.categoryTitle}>{category.title}</h3>
          <Grid
            container
            className={classes.categoryProductsContainer}
            wrap="nowrap"
          >
            {category.contents.map((product) => {
              const {
                id,
                thumbnail_url: thumbnailUrl,
                sampling,
                sell_options: sellOptions,
                name,
                price
              } = product;
              // let priceValue = `$${Number(price).toFixed(2)}`;
              let priceValue = `${Number(price).toFixed()} puntos`;
              if (sellOptions.includes('sampleable')) {
                priceValue = free;
              }
              if (sampling && sampling.price) {
                priceValue = (
                  <>
                    <span className={classes.priceWithDiscount}>
                      {`${Number(price).toFixed()} puntos`}
                    </span>
                    {`${Number(sampling.price).toFixed()} puntos`}
                  </>
                );
              }
              return (
                <Grid
                  container
                  key={id}
                  className={classes.productContainer}
                  onClick={() => selectProduct(product)}
                >
                  <img
                    src={thumbnailUrl}
                    alt={name}
                    className={classes.thumbnail}
                  />
                  <h5
                    className={classnames(
                      classes.noMargin,
                      classes.productCardName
                    )}
                  >
                    {trimmString(name, 30)}
                  </h5>
                  <p
                    className={classnames(
                      classes.noMargin,
                      sellOptions.includes('sampleable')
                        ? classes.productCardFree
                        : classes.productCardPrice
                    )}
                    style={{ marginBottom: 6 }}
                  >
                    {priceValue}
                  </p>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

MachineInventory.propTypes = {
  classes: shape({}).isRequired,
  productServerInventory: arrayOf(shape({})).isRequired,
  selectProduct: func.isRequired
};

export default withStyles(styles)(MachineInventory);
