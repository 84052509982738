import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool, arrayOf } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import sessionActions from '../../../actions/session';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import DealInfo from '../../DealInfo';
import styles from '../../../styles';
import strings from '../../../language';
import moment from 'moment';

class HistoryPage extends Component {

  componentDidMount() {
    const {
      authToken,
      communityMemberId,
      sessionActions: actionsFromSession
    } = this.props;
    if (!authToken) {
      this.goTo('/');
    } else {
      actionsFromSession.getDealsHistory(communityMemberId, authToken);
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, language, deals, loading } = this.props;
    const { historyLabel } = strings;
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{historyLabel}</h1>
    );

    const sabaDeals = ["saba1", "saba2", "saba3", "saba4", "saba5"];
    const newDeal = deals?.filter(({ product_server_name }) => sabaDeals.includes(product_server_name)) || [];

    newDeal.sort(
      (objA, objB) => moment(objB.deal_date).format('YYYYMMDD') - moment(objA.deal_date).format('YYYYMMDD'),
    );

    return (
      <>
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => {
              this.goTo('/machine-inventory');
            }}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            direction="column"
          >
            {/* {deals.map((deal) => (
              <DealInfo deal={deal} language={language} />
            ))} */}
            {newDeal.map((deal) => (
              <DealInfo deal={deal} language={language} />
            ))}
          </Grid>
        </Grid>
      </>
    );
  }
}

HistoryPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  language: string.isRequired,
  authToken: string,
  loading: bool.isRequired,
  communityMemberId: number,
  deals: arrayOf(shape({})).isRequired
};

HistoryPage.defaultProps = {
  authToken: '',
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { communityMember, loading, deals } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  return {
    language,
    authToken,
    loading,
    communityMemberId,
    deals
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HistoryPage));
