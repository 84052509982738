import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, arrayOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import vendingMachineActions from '../../../actions/vendingMachine';
import Keyboard from '../../Keyboard';
import styles from '../../../styles';
import strings from '../../../language';

const machineReadyImage = require('../../../assets/machine-ready.png')
// const machineReadyImage = require('../../../assets/cafe1.gif');

class MachineReadyPage extends Component {
  constructor() {
    super();
    this.state = {
      showKeyboard: false
    };
  }

  componentDidMount() {
    const { userTypeTimeout, authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
    const timerFunc = setInterval(() => {
      this.checkStatusChange(timerFunc);
    }, 1000);
    setTimeout(() => {
      clearInterval(timerFunc);
      this.goToError();
    }, userTypeTimeout * 1000 + 10000);
    const { vendingMachineKeyboardInfo } = this.props;
    const { mobile_keyboard_type: keyboardType } = vendingMachineKeyboardInfo;
    if (keyboardType === 'numeric') {
      this.setState({ showKeyboard: true });
    }
  }

  checkStatusChange = (timerFunc) => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      selectedVendingMachineId,
      dealId,
      authToken
    } = this.props;
    actionsFromVendingMachine.getDealStatus(
      dealId,
      selectedVendingMachineId,
      authToken,
      timerFunc,
      false,
      this.goTo,
      2
    );
  };

  goToError = () => {
    const { deliveryStatus } = this.props;
    if (deliveryStatus === 2) {
      this.goTo('/connection-error');
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const {
      classes,
      selectedVendingMachineName,
      balances,
      userCode,
      saleType
    } = this.props;
    const {
      machineReady,
      credit,
      makeSelection,
      selectLabel,
      onLabel
    } = strings;
    const { showKeyboard } = this.state;
    const sabaBalance = balances.find(({ balance_type }) => balance_type.id === 12);
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        wrap="nowrap"
      >
        <div className={classes.bodyDownloadText} style={{ marginTop: '10%' }}>
          <h1 className={classes.titleSecColor}>{machineReady}</h1>
          {saleType === 2 && (
            <p className={classes.bodyDefaultText}>
              {`${credit} `}
              <span className={classes.machineReadyBalance}>
                {`${sabaBalance ? sabaBalance?.amount?.toFixed() : '0'
                  } puntos`}
              </span>
            </p>
          )}
          {saleType === 2 ? (
            <h1 className={classes.titleSecColor} style={{ maxWidth: 300, textAlign: 'center' }}>{makeSelection}</h1>
          ) : (
            <p className={classes.bodyDefaultText}>
              {`${selectLabel} `}
              <span className={classes.selectionNumber}>{userCode}</span>
              {` ${onLabel}`}
            </p>
          )}
          <p className={classes.connectingToMachineName}>
            {`@${selectedVendingMachineName}`}
          </p>
        </div>
        {showKeyboard ? (
          <Keyboard vendingMachineName={selectedVendingMachineName} />
        ) : (
          <img
            src={machineReadyImage}
            alt=""
            className={classes.connectingImage}
          />
        )}
      </Grid>
    );
  }
}

MachineReadyPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  authToken: string.isRequired,
  selectedVendingMachineId: number,
  selectedVendingMachineName: string,
  userTypeTimeout: number,
  balances: arrayOf(shape({})).isRequired,
  deliveryStatus: number,
  saleType: number,
  userCode: string,
  dealId: number,
  vendingMachineKeyboardInfo: shape({}).isRequired
};

MachineReadyPage.defaultProps = {
  selectedVendingMachineId: null,
  selectedVendingMachineName: '',
  userTypeTimeout: null,
  deliveryStatus: null,
  saleType: null,
  userCode: '',
  dealId: null
};

const mapStateToProps = (state) => {
  const { communityMember, balances } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    selectedVendingMachine,
    deal: {
      id: dealId,
      delivery_status: deliveryStatus,
      payment_type: { code: saleType } = {},
      user_code: userCode
    } = {},
    vendingMachineKeyboardInfo
  } = state.vendingMachine;
  const {
    id: selectedVendingMachineId,
    name: selectedVendingMachineName,
    user_type_timeout: userTypeTimeout
  } = selectedVendingMachine;
  return {
    authToken,
    communityMemberId,
    balances,
    selectedVendingMachineId,
    selectedVendingMachineName,
    userTypeTimeout,
    deliveryStatus,
    saleType,
    userCode,
    dealId,
    vendingMachineKeyboardInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MachineReadyPage));
