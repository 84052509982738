import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import styles from '../../styles';
import classnames from 'classnames';

const NotConectedMachineModal = ({
    classes,
    notConectedModal,
    closeNotConectedModal,
}) => {

    return (
        <Modal open={notConectedModal} onClose={closeNotConectedModal}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.notConectedModalContainer}
                wrap="nowrap"
            >
                <p className={classes.bodyDefaultTextCenterNoMargin}>En este momento no puedo conectarme a la máquina. Por favor intenta más tarde.</p>
            </Grid>
        </Modal>
    );
};

NotConectedMachineModal.propTypes = {
    classes: shape({}).isRequired,
};

export default connect(
    null
)(withStyles(styles)(NotConectedMachineModal));