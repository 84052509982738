import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func, string, arrayOf, number } from 'prop-types';
import { Grid, Modal, CircularProgress } from '@material-ui/core';
import vendingMachineActions from '../../actions/vendingMachine';
import Button from '../Button';
import styles from '../../styles';

class DirectPaymentModal extends Component {
  constructor(props) {
    super(props);
    const { creditCards } = props;
    this.state = {
      defaultCard: creditCards[0] && creditCards[0].card_number,
      defaultCardId: creditCards[0] && creditCards[0].id,
      createdDeal: false
    };
  }

  handleChangeCard = () => {};

  handleSubmit = () => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      authToken,
      selectedVendingMachineId,
      selectedProduct,
      language,
      anyProduct
    } = this.props;
    const { defaultCardId } = this.state;
    const { sell_options: sellOptions = [] } = selectedProduct;
    const isSellable = sellOptions.includes('sellable');
    const isServiceable = sellOptions.includes('serviceable');
    const isSampleable = sellOptions.includes('sampleable');
    let saleType = null;
    if (isSellable) {
      saleType = 2;
    } else if (isServiceable) {
      saleType = 4;
    } else if (isSampleable) {
      saleType = 3;
    }
    const origin = 'web';
    let selectedProductUserCode =
      saleType === 4 ? selectedProduct.upc : selectedProduct.user_code;
    if (anyProduct) {
      saleType = 2;
      selectedProductUserCode = undefined;
    }
    if (authToken) {
      actionsFromVendingMachine.createNewDealDirectPayment(
        authToken,
        selectedVendingMachineId,
        selectedProductUserCode,
        saleType,
        origin,
        anyProduct,
        defaultCardId,
        language,
        this.goTo
      );
      this.setState({ createdDeal: true });
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const {
      classes,
      directPaymentModal,
      closeDirectPaymentModal,
      loading,
      balance,
      selectedProduct,
      anyProduct
    } = this.props;
    const { defaultCard, createdDeal } = this.state;
    const amountToCharge = Math.abs(balance - selectedProduct.price);
    if (!defaultCard) {
      return null;
    }
    return (
      <Modal open={directPaymentModal} onClose={closeDirectPaymentModal}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          wrap="nowrap"
          className={classes.directPaymentModalContainer}
        >
          {loading || createdDeal ? (
            <CircularProgress className={classes.primaryLoading} />
          ) : (
            <>
              {anyProduct ? (
                <p className={classes.bodyDefaultTitle}>
                  {`¿Quieres pagar con tu tarjeta terminación ${defaultCard.slice(
                    defaultCard.length - 4
                  )}?`}
                </p>
              ) : (
                <p className={classes.bodyDefaultTitle}>
                  {`Cargar $${amountToCharge.toFixed(
                    2
                  )} a tu tarjeta terminación ${defaultCard.slice(
                    defaultCard.length - 4
                  )}?`}
                </p>
              )}
              <Grid container justify="space-around">
                {/* {creditCards.length > 1 && (
                  <Button
                    size="sm"
                    type="outlined"
                    action={this.handleChangeCard}
                  >
                    Usar otra
                  </Button>
                )} */}
                <Button size="sm" type="primary" action={this.handleSubmit}>
                  Aceptar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    );
  }
}

DirectPaymentModal.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  directPaymentModal: bool.isRequired,
  closeDirectPaymentModal: func.isRequired,
  vendingMachineActions: shape({}).isRequired,
  loading: bool.isRequired,
  balance: number,
  authToken: string,
  selectedProduct: shape({}).isRequired,
  creditCards: arrayOf(shape({})).isRequired,
  selectedVendingMachineId: number,
  language: string.isRequired,
  anyProduct: bool
};

DirectPaymentModal.defaultProps = {
  authToken: '',
  balance: 0,
  selectedVendingMachineId: null,
  anyProduct: false
};

const mapStateoProps = (state) => {
  const {
    country,
    loading: sessionLoading,
    error,
    communityMember = {},
    creditCards,
    newBalance
  } = state.session;
  const { language } = state.language;
  const { auth_token: authToken, balance: cmBalance } = communityMember;
  const {
    loading: vendingMachineLoading,
    selectedProduct,
    selectedVendingMachine
  } = state.vendingMachine;
  const { id: selectedVendingMachineId } = selectedVendingMachine;
  const loading = sessionLoading || vendingMachineLoading;
  let balance = cmBalance;
  if (newBalance) {
    balance = newBalance;
  }
  return {
    language,
    country,
    loading,
    error,
    authToken,
    balance,
    selectedProduct,
    creditCards,
    selectedVendingMachineId
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch),
  dispatch
});

export default connect(
  mapStateoProps,
  mapDispatchToProps
)(withStyles(styles)(DirectPaymentModal));
