import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, arrayOf, bool } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import Menu from '@material-ui/icons/Menu';
import ContactSupport from '@material-ui/icons/ContactSupport';
import AccountCircle from '@material-ui/icons/AccountCircle';
import vendingMachineActions from '../../../actions/vendingMachine';
import sessionActions from '../../../actions/session';
import Header from '../../Header';
import LeftMenu from '../../LeftMenu';
import MachineInventory from '../../MachineInventory';
import Tabs from '../../Tabs';
import BalanceRechargedModal from '../../Modals/BalanceRechargedModal';
import CustomMessageModal from '../../Modals/CustomMessageModal';
import DirectPaymentModal from '../../Modals/DirectPaymentModal';
import LoginModal from '../../Modals/LoginModal';
import NativeAppModal from '../../Modals/NativeAppModal';
import Banners from '../../Banners';
import Keyboard from '../../Keyboard';
import styles from '../../../styles';
import { getOperatingSystem } from '../../../config/utils';
import InstructionsQrModal from '../../Modals/InstructionsQrModal';
import ScanTicketModal from '../../Modals/ScanTicketModal';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const insertCoinImage = require('../../../assets/insert-coin.png');
const mastercardImage = require('../../../assets/mastercard-logo.png');
const visaImage = require('../../../assets/visa-logo.png');
const amexImage = require('../../../assets/american-express-logo.png');
const sabaLink =
  'https://www.saba.com.mx/';

class MachineInventoryPage extends Component {
  constructor(props) {
    super(props);
    const { match, cookies } = props;
    const { params } = match;
    const { balanceRecharged = null } = params;
    const os = getOperatingSystem();
    const cookiesVars = cookies.getAll();
    const {
      dontShowNativeAppModal = true,
      dontShowCustomMessageModal = false
    } = cookiesVars;
    this.state = {
      selectedTab: 0,
      menuIsOpen: false,
      balanceRechargedModal: !!balanceRecharged,
      customMessagedModal: !dontShowCustomMessageModal,
      loginModal: false,
      checkPaymentMethods: false,
      directPaymentModal: false,
      os,
      // instructionsQrModalIsOpen: !sessionStorage.getItem('authToken') || sessionStorage.getItem('instructionsModalState') !== 'true',
      instructionsQrModalIsOpen: false,
      // scanTicketModal: sessionStorage.getItem('authToken') && sessionStorage.getItem('instructionsModalState') === 'true' && sessionStorage.getItem('scanModal') !== 'true',
      scanTicketModal: false,
      nativeAppModal:
        (os === 'iOS' || os === 'Android') && !dontShowNativeAppModal
    };
  }

  componentDidMount() {
    const {
      authToken,
      vendingMachineActions: actionsFromVendingMachine,
      sessionActions: actionsFromSession,
      selectedVendingMachineId,
      selectedVendingMachineName,
      communityMemberId,
      communityMemberName,
      match
    } = this.props;
    const { params } = match;
    const { vendingMachineName = '' } = params;
    actionsFromVendingMachine.getVendingMachineByNameKeyboardInfo(
      vendingMachineName || selectedVendingMachineName,
      this.goTo
    );
    if (authToken) {
      actionsFromVendingMachine.getVendingMachineItems(
        selectedVendingMachineId
      );
      actionsFromVendingMachine.getVendingMachineServices(
        selectedVendingMachineId,
        authToken
      );
      actionsFromSession.getMemberBalance(communityMemberId, authToken);
      actionsFromSession.getCommunityMemberMessages(
        communityMemberId,
        authToken
      );
      actionsFromSession.getUserCreditCards(communityMemberId, authToken);
    }
    sessionStorage.getItem('authToken')
    sessionStorage.getItem('instructionsModalState')
    sessionStorage.getItem('scanModal')
    if (!authToken || sessionStorage.getItem('instructionsModalState') !== 'true') {
      this.setState({ instructionsQrModalIsOpen: true });
    }
    if (authToken && sessionStorage.getItem('instructionsModalState') === 'true' && sessionStorage.getItem('scanModal') !== 'true') {
      this.setState({ scanTicketModal: true });
    }
    if (sessionStorage.getItem('instructionsModalState') === 'true' && authToken) {
      if (!communityMemberName) {
        this.goTo('/registration-page');
      }
    }
    console.log("cmid", communityMemberId);
    console.log("cmName", communityMemberName);
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  selectProduct = (product) => {
    const { vendingMachineActions: actionsFromVendingMachine } = this.props;
    actionsFromVendingMachine.selectProduct(product, this.goTo);
  };

  changeTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ menuIsOpen: !prevState.menuIsOpen }));
  };

  closeBalanceRechargedModal = () => {
    this.setState({ balanceRechargedModal: false });
  };

  closeCustomMessagedModal = () => {
    this.setState({ customMessagedModal: false });
  };

  toggleLogin = () => {
    this.setState((prevState) => ({ loginModal: !prevState.loginModal }));
  };

  toggleNativeAppModal = () => {
    this.setState((prevState) => ({
      nativeAppModal: !prevState.nativeAppModal
    }));
  };

  openLoginModal = () => {
    this.setState({ loginModal: true });
  };

  openScanModal = () => {
    this.setState({ scanTicketModal: true });
  }

  payWithCellphone = () => {
    this.setState({
      checkPaymentMethods: true,
      loginModal: true
    });
  };

  anyProductDeal = () => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      authToken,
      selectedVendingMachineId,
      language,
      directPayment,
      creditCards
    } = this.props;
    const origin = 'web';
    const selectedProductUserCode = undefined;
    const saleType = 2;
    const anyProduct = true;
    if (authToken) {
      if (creditCards.length > 0) {
        if (directPayment) {
          this.setState({ directPaymentModal: true });
        } else {
          actionsFromVendingMachine.createNewDeal(
            authToken,
            selectedVendingMachineId,
            selectedProductUserCode,
            saleType,
            origin,
            anyProduct,
            language,
            this.goTo
          );
        }
      } else {
        this.openLoginModal();
      }
    } else {
      this.openLoginModal();
    }
  };

  closeDirectPaymentModal = () => {
    this.setState({ directPaymentModal: false });
  };

  closeInstructionsQrModal = () => {
    const isVerified = sessionStorage.getItem('authToken');
    if (!isVerified) {
      sessionStorage.setItem('instructionsModalState', 'true');
      this.setState({ instructionsQrModalIsOpen: false });
      this.openLoginModal();
      // } else if (isVerified && communityMember.name) {
    } else if (isVerified && sessionStorage.getItem('scanModal') !== 'true') {
      sessionStorage.setItem('instructionsModalState', 'true');
      this.setState({ instructionsQrModalIsOpen: false });
      this.setState({ scanTicketModal: true });
    }
  };

  closeScanModal = () => {
    sessionStorage.setItem('scanModal', 'true');
    this.setState({ scanTicketModal: false });
  }

  closeScanModalGoTo = () => {
    sessionStorage.setItem('scanModal', 'true');
    this.setState({ scanTicketModal: false });
    this.goTo('/ticket-scan');
  }

  render() {
    const {
      classes,
      selectedVendingMachineName,
      productServerItems,
      balance,
      newBalance,
      loading,
      customMessages,
      hasKeyboard,
      authToken,
      cookies,
      history,
      banners,
      balances
    } = this.props;
    const {
      selectedTab,
      menuIsOpen,
      balanceRechargedModal,
      customMessagedModal,
      loginModal,
      checkPaymentMethods,
      directPaymentModal,
      os,
      nativeAppModal,
      instructionsQrModalIsOpen,
      scanTicketModal
    } = this.state;
    let machineInventoryContainerClass = hasKeyboard
      ? classes.machineInventoryWithTabsContainer
      : classes.machineInventoryContainer;
    if (banners.length === 0) {
      machineInventoryContainerClass = classnames(
        machineInventoryContainerClass,
        hasKeyboard && classes.machineInventoryNoBanners
      );
    }

    const sabaBalance = balances.find(({ balance_type }) => balance_type.id === 12);

    console.log("balanceId", sabaBalance);
    console.log("balances", balances);

    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>
        {`@${selectedVendingMachineName}`}
      </h1>
    );
    const headerRightContent = (
      <p className={classes.headerDefaultTextSaba}>
        {/* {`$${newBalance ? newBalance.toFixed(2) : balance.toFixed(2)}`} */}
        {`${sabaBalance ? sabaBalance?.amount?.toFixed() : "0"} pts`}
      </p>
    );
    if (loading && productServerItems.length === 0) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <ScanTicketModal
          scanTicketModal={scanTicketModal}
          closeScanTicketModal={this.closeScanModal}
          scanTicketModalGoTo={this.closeScanModalGoTo}
        />
        <InstructionsQrModal
          instructionsQrModal={instructionsQrModalIsOpen}
          closeInstructionsQrModal={this.closeInstructionsQrModal}
        />
        {customMessages.length > 0 && (
          <CustomMessageModal
            customMessagedModal={customMessagedModal}
            closeCustomMessagedModal={this.closeCustomMessagedModal}
            content={customMessages[0]}
            cookies={cookies}
          />
        )}
        <LoginModal
          loginModal={loginModal}
          closeLoginModal={this.toggleLogin}
          cookies={cookies}
          checkPaymentMethods={checkPaymentMethods}
          history={history}
          openScanModal={this.openScanModal}
        />
        <NativeAppModal
          os={os}
          nativeAppModal={nativeAppModal}
          closeNativeAppModal={this.toggleNativeAppModal}
          cookies={cookies}
        />
        <DirectPaymentModal
          directPaymentModal={directPaymentModal}
          closeDirectPaymentModal={this.closeDirectPaymentModal}
          anyProduct
          history={history}
        />
        <BalanceRechargedModal
          balanceRechargedModal={balanceRechargedModal}
          closeBalanceRechargedModal={this.closeBalanceRechargedModal}
        />
        <LeftMenu
          toggleMenu={this.toggleMenu}
          isOpen={menuIsOpen}
          goTo={this.goTo}
        />
        <div className={classes.lowerContainer}>
          <p style={{ marginRight: 10 }}>Acumula puntos:</p>
          <div className={classes.floatingButton} onClick={() => this.goTo('/ticket-scan')}>
            {/* <FullscreenIcon className={classes.floatingButtonIcon} /> */}
            <p style={{ color: "white", paddingRight: 8, paddingLeft: 8, marginTop: 4, marginBottom: 4 }}>Escanear ticket</p>
          </div>
        </div>
        <Grid
          container
          className={classes.containerWithHeader}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {authToken ? (
            <Header
              leftContent={<Menu />}
              leftAction={this.toggleMenu}
              centerContent={headerCenterContent}
              centerAction={() => this.goTo('/machine-selection/new')}
              rightContent={headerRightContent}
              rightAction={() => this.goTo('/ticket-scan')}
              type="primary"
            />
          ) : (
            <Header
              leftContent={<AccountCircle />}
              leftAction={this.toggleLogin}
              centerContent={headerCenterContent}
              centerAction={() => this.goTo('/machine-selection/new')}
              rightContent={<ContactSupport />}
              rightAction={() => {
                window.open(
                  'https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm'
                );
              }}
              type="primary"
            />
          )}
          <Banners />
          <Grid
            container
            className={
              banners.length === 0
                ? classes.NoBannerTabsAndContentContainer
                : classes.tabsAndContentContainer
            }
          >
            <Tabs
              selectedTab={selectedTab}
              goTo={this.goTo}
              changeTab={this.changeTab}
            />
            <Grid
              container
              className={machineInventoryContainerClass}
              direction="column"
            >
              {selectedTab === 0 && (
                <MachineInventory
                  selectProduct={this.selectProduct}
                  productServerInventory={productServerItems}
                />
              )}
              {selectedTab === 0 && (
                <div style={{ width: '100%', height: 50 }} />
              )}
              {selectedTab === 1 && hasKeyboard && (
                <Grid container justify="center" alignItems="center">
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    className={classes.insertCoinContainer}
                  >
                    <img
                      src={insertCoinImage}
                      alt="Insert coin"
                      className={classes.insertCoinImage}
                    />
                    <p className={classes.insertCoinText}>
                      Inserta billetes y monedas y haz tu selección aquí
                    </p>
                  </Grid>
                  <Keyboard vendingMachineName={selectedVendingMachineName} />
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.insertCoinContainer}
                  >
                    <p className={classes.payWithPhone}>
                      {`o `}
                      <span
                        className={classes.payWithPhoneLink}
                        onClick={
                          authToken
                            ? this.anyProductDeal
                            : this.payWithCellphone
                        }
                      >
                        paga con tarjeta
                      </span>
                    </p>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      className={classes.insertCoinContainer}
                    >
                      <img
                        src={amexImage}
                        alt="American Express"
                        className={classes.insertCoinImage}
                      />
                      <img
                        src={mastercardImage}
                        alt="Master card"
                        className={classes.insertCoinImage}
                      />
                      <img
                        src={visaImage}
                        alt="Visa"
                        className={classes.insertCoinImage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

MachineInventoryPage.propTypes = {
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  match: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  history: shape({}).isRequired,
  authToken: string,
  selectedVendingMachineId: number,
  selectedVendingMachineName: string,
  productServerItems: arrayOf(shape({})).isRequired,
  balance: number,
  communityMemberId: number,
  loading: bool.isRequired,
  newBalance: number.isRequired,
  customMessages: arrayOf(shape({})).isRequired,
  hasKeyboard: bool.isRequired,
  directPayment: bool,
  creditCards: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  banners: arrayOf(shape({})).isRequired,
  balances: arrayOf(shape({})).isRequired,
  communityMemberName: string,
};

MachineInventoryPage.defaultProps = {
  authToken: '',
  selectedVendingMachineId: null,
  selectedVendingMachineName: '',
  communityMemberId: null,
  communityMemberName: null,
  balance: 0,
  directPayment: false
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const {
    communityMember = {},
    loading: sessionLoading,
    newBalance,
    customMessages,
    creditCards,
    balances,
  } = state.session;
  const {
    auth_token: authToken,
    id: communityMemberId,
    balance: cmBalance,
    name: communityMemberName,
  } = communityMember;
  const {
    selectedVendingMachine,
    productServerItems,
    productServerServices,
    loading: vendingMachineLoading,
    vendingMachineKeyboardInfo
  } = state.vendingMachine;
  const { mobile_keyboard_type: keyboardType } = vendingMachineKeyboardInfo;
  const hasKeyboard = keyboardType === 'numeric';
  const {
    name: selectedVendingMachineName,
    id: selectedVendingMachineId,
    payment_settings: paymentSettings = { direct_payment: false },
    banners = []
  } = selectedVendingMachine;
  const { direct_payment: directPayment = false } = paymentSettings;
  const loading = sessionLoading || vendingMachineLoading;
  let balance = cmBalance;
  if (newBalance) {
    balance = newBalance;
  }
  return {
    authToken,
    selectedVendingMachineName,
    selectedVendingMachineId,
    productServerItems,
    productServerServices,
    communityMemberId,
    loading,
    newBalance,
    customMessages,
    hasKeyboard,
    paymentSettings,
    directPayment,
    creditCards,
    language,
    balance,
    banners,
    balances,
    communityMemberName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MachineInventoryPage));
