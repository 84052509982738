import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const MachineNotFoundModal = ({
  classes,
  machineNotFoundModal,
  closeMachineNotFoundModal
}) => {
  const { machineNotFound, okLabel } = strings;
  return (
    <Modal open={machineNotFoundModal} onClose={closeMachineNotFoundModal}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.modalContainer}
      >
        <p className={classes.bodyDefaultText}>{machineNotFound}</p>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="md" type="primary" action={closeMachineNotFoundModal}>
            {okLabel}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

MachineNotFoundModal.propTypes = {
  classes: shape({}).isRequired,
  machineNotFoundModal: bool.isRequired,
  closeMachineNotFoundModal: func.isRequired
};

export default withStyles(styles)(MachineNotFoundModal);
