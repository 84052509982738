import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  shape,
  string,
  number,
  bool,
  arrayOf,
} from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Grid, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import sessionActions from '../../../actions/session';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import DealInfo from '../../DealInfo';
import styles from '../../../styles';
import strings from '../../../language';

class ProfilePage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { authToken, communityMemberId, sessionActions: actionsFromSession } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
    actionsFromSession.getDealsHistory(communityMemberId, authToken);
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  logout = () => {
    const {
      cookies,
      sessionActions: actionsFromSession,
      vendingMachineActions: actionsFromVendingMachine
    } = this.props;
    actionsFromSession.logout(cookies);
    actionsFromVendingMachine.reset();
    this.goTo('/');
  };

  render() {
    const {
      classes,
      language,
      loading,
      name,
      phone,
      email,
      birthDate,
      // balance,
      gender,
      lastTransaction,
      deals,
    } = this.props;
    const { editLabel, logOut, lastTransactionLabel, viewHistory } = strings;
    const headerRightContent = (
      <p className={classes.headerDefaultText}>{editLabel.toUpperCase()}</p>
    );
    moment.locale(language);
    const birthDateWithFormat = moment(birthDate).format('MMM YYYY');
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    let deal = null;
    console.log("lastTransaction", lastTransaction);
    if (lastTransaction && lastTransaction.product) {
      deal = {
        cash_paid: lastTransaction.cash_paid,
        deal_date: lastTransaction.deal_date,
        image: lastTransaction.product.photo_url,
        name: lastTransaction.product.name,
        product_server_name: lastTransaction.product_server_name,
        type: lastTransaction.payment_type.name,
        user_code: lastTransaction.user_code
      };
    }
    const sabaDeals = ["saba1", "saba2", "saba3", "saba4", "saba5"];
    const newDeal = deals?.filter(({ product_server_name }) => sabaDeals.includes(product_server_name)) || [];

    newDeal.sort(
      (objA, objB) => moment(objB.deal_date).format('YYYYMMDD') - moment(objA.deal_date).format('YYYYMMDD'),
    );
    console.log("deals", deals);
    const lastSabaDeal = newDeal[0];
    console.log("lastSabaDeal", lastSabaDeal);
    return (
      <>
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => {
              this.goTo('/machine-inventory');
            }}
            rightContent={headerRightContent}
            rightAction={() => {
              this.goTo('/edit-profile');
            }}
            type="secondary"
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            {/* <p className={classes.profileName}>{name}</p> */}
            {gender && birthDate && (
              <p className={classes.profileGenderAndDate}>
                {`${gender}, ${birthDateWithFormat}`}
              </p>
            )}
            {/* <p className={classes.profileSmallText}>{email}</p> */}
            <p className={classes.profileSmallText}>{phone}</p>
          </Grid>
          {lastSabaDeal && (
            <Grid container direction="column" className={classes.linksSection}>
              <Grid justify="space-between" alignItems="center" container>
                <h2 className={classes.linkTitle}>{lastTransactionLabel}</h2>
                <p
                  className={classes.viewHistoryLink}
                  onClick={() => {
                    this.goTo('/history');
                  }}
                >
                  {viewHistory}
                </p>
              </Grid>
              {/* <DealInfo deal={deal} language={language} /> */}
              <DealInfo deal={lastSabaDeal} language={language} />
            </Grid>
          )}
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.logoutContainer}
          >
            <p className={classes.logout} onClick={this.logout}>
              {logOut}
            </p>
          </Grid>
        </Grid>
      </>
    );
  }
}

ProfilePage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  cookies: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  language: string.isRequired,
  authToken: string,
  loading: bool.isRequired,
  name: string,
  phone: string,
  email: string,
  birthDate: string,
  // balance: number,
  gender: string,
  lastTransaction: shape({}),
  deals: arrayOf(shape({})).isRequired,
  communityMemberId: number,
};

ProfilePage.defaultProps = {
  authToken: '',
  name: '',
  phone: '',
  email: '',
  birthDate: '',
  // balance: 0,
  gender: '',
  lastTransaction: {},
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { communityMember, loading, deals } = state.session;
  const {
    auth_token: authToken,
    name,
    phone,
    email,
    birth_date: birthDate,
    balance,
    gender,
    last_transaction: lastTransaction,
    id: communityMemberId,
  } = communityMember;
  return {
    language,
    authToken,
    loading,
    name,
    phone,
    email,
    birthDate,
    balance,
    gender,
    lastTransaction,
    deals,
    communityMemberId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfilePage));
