import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import styles from '../../../styles';
import { bool } from 'prop-types';

const ticketIcon = require('../../../assets/ticketIcon.png');

const BarcodeScan = ({
    classes,
    closeAction,
    data,
    setData,
    stopStream,
}) => {



    const videoConstrains = {
        aspectRatio: 1.6,
    }

    //VIDEOCONSTRANITS FOR IOS
    // const videoConstrainsMobile = {
    //     aspectRatio: 0.5,
    //     facingMode: 'environment',
    // }

    //VIDEOCONSTRINTS FOR ANDROID
    const videoConstrainsMobile = {
        // aspectRatio: 1,
        facingMode: 'environment',
        // focusMode: "continuos",
        // exposureMode: "continuous",
        // torch: true,
    }

    return (
        <div
            className={classes.generalContainerScanTicket}
        >
            <div className={classes.width100}>
                <HeaderSaba
                    closeAction={closeAction}
                    title={"Escanea un ticket"}
                />
            </div>
            <div style={{ maxWidth: 280, marginTop: 15, marginBottom: 30 }}>
                <p className={classes.generalTextSaba}>Acerca el código de barras de tu ticket</p>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '95%', maxWidth: 350, background: 'white', borderColor: "#DF1995", borderStyle: 'solid', borderWidth: 4, borderRadius: 4, paddingLeft: 2, paddingRight: 2 }}>
                    <BarcodeScannerComponent
                        width={"100%"}
                        // height={"auto"}
                        onUpdate={(err, result) => {
                            if (result) setData(result.text);
                            else setData();
                        }}
                        // videoConstraints={videoConstrainsMobile}
                        stopStream={stopStream}
                        facingMode={'environment'}
                    // autoFocus
                    />
                </div>
                {/* <p>{data}</p> */}
            </div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img src={ticketIcon} alt="scan-icon" className={classes.sabaTicketIcon} />
            </div>
        </div>
    );
};

BarcodeScan.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    data: string,
    setData: func,
    stopStream: bool,
};

BarcodeScan.defaultProps = {
    closeAction: () => { },
    data: null,
    setData: () => { },
    stopStream: false,
};

export default withStyles(styles)(BarcodeScan);