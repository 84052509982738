import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, number, bool, func, string } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const ConfirmTopUpModal = ({
  classes,
  confirmTopUpModal,
  closeConfirmTopUp,
  rechargeBalance,
  amount,
  currency,
  countryCode
}) => {
  const {
    beforeTopUpMessageTitle,
    beforeTopUpMessageStart,
    beforeTopUpMessageMiddle,
    beforeTopUpMessageEnd,
    cancelLabel,
    confirmLabel
  } = strings;
  return (
    <Modal open={confirmTopUpModal} onClose={closeConfirmTopUp}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.modalContainer}
      >
        <h1 className={classes.bodyDefaultTitle}>{beforeTopUpMessageTitle}</h1>
        <p className={classes.bodyDefaultText}>
          {`${beforeTopUpMessageStart} $${amount} ${currency} ${beforeTopUpMessageMiddle} ${countryCode} ${beforeTopUpMessageEnd}`}
        </p>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="sm" type="outlined" action={closeConfirmTopUp}>
            {cancelLabel}
          </Button>
          <Button size="sm" type="primary" action={rechargeBalance}>
            {confirmLabel}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmTopUpModal.propTypes = {
  classes: shape({}).isRequired,
  confirmTopUpModal: bool.isRequired,
  closeConfirmTopUp: func.isRequired,
  rechargeBalance: func.isRequired,
  amount: number.isRequired,
  currency: string.isRequired,
  countryCode: string.isRequired
};

export default withStyles(styles)(ConfirmTopUpModal);
