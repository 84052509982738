import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const ConfirmTopUpModal = ({
  classes,
  completeVerificationModal,
  hideCompleteVerificationModal
}) => {
  const { confirmLabel } = strings;
  return (
    <Modal
      open={completeVerificationModal}
      onClose={hideCompleteVerificationModal}
    >
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.modalContainer}
      >
        <h1 className={classes.bodyDefaultTitle}>Casi terminamos</h1>
        <p className={classes.bodyDefaultText}>
          Te acabamos de enviar un mensaje a tu dirección de correo electrónico.
        </p>
        <p className={classes.bodyDefaultText}>
          Por favor ábrelo y confirma tu email
        </p>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button
            size="sm"
            type="primary"
            action={hideCompleteVerificationModal}
          >
            {confirmLabel}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmTopUpModal.propTypes = {
  classes: shape({}).isRequired,
  completeVerificationModal: bool.isRequired,
  hideCompleteVerificationModal: func.isRequired
};

export default withStyles(styles)(ConfirmTopUpModal);
