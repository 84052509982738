import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, oneOfType, oneOf, node, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';

const Header = ({
  classes,
  leftContent,
  leftAction,
  centerContent,
  centerAction,
  rightContent,
  rightAction,
  type
}) => {
  const headerTypes = {
    primary: 'primaryHeader',
    secondary: 'secondaryHeader'
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes[headerTypes[type]]}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerLeftContent}
        onClick={leftAction}
      >
        {leftContent}
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerCenterContent}
        style={{ textAlign: 'center' }}
        onClick={centerAction}
      >
        {centerContent}
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerRightContent}
        onClick={rightAction}
      >
        {rightContent}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  classes: shape({}).isRequired,
  leftContent: oneOfType([string, node, shape({})]),
  leftAction: func,
  centerContent: oneOfType([string, node, shape({})]),
  centerAction: func,
  rightContent: oneOfType([string, node, shape({})]),
  rightAction: func,
  type: oneOf(['primary', 'secondary']).isRequired
};

Header.defaultProps = {
  leftContent: null,
  leftAction: () => {},
  centerContent: null,
  centerAction: () => {},
  rightContent: null,
  rightAction: () => {}
};

export default withStyles(styles)(Header);
