import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, number, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const BalanceRechargedModal = ({
  classes,
  balanceRechargedModal,
  closeBalanceRechargedModal,
  newBalance
}) => {
  const {
    afterTopUpMessageTitle,
    afterTopUpMessageStart,
    confirmLabel
  } = strings;
  return (
    <Modal open={balanceRechargedModal} onClose={closeBalanceRechargedModal}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.modalContainer}
      >
        <h1 className={classes.bodyDefaultTitle}>{afterTopUpMessageTitle}</h1>
        <p className={classes.bodyDefaultText}>
          {`${afterTopUpMessageStart} $${newBalance}`}
        </p>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="md" type="primary" action={closeBalanceRechargedModal}>
            {confirmLabel}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

BalanceRechargedModal.propTypes = {
  classes: shape({}).isRequired,
  balanceRechargedModal: bool.isRequired,
  closeBalanceRechargedModal: func.isRequired,
  newBalance: number.isRequired
};

const mapStateoProps = (state) => {
  const { newBalance } = state.session;
  return {
    newBalance
  };
};

export default connect(
  mapStateoProps,
  null
)(withStyles(styles)(BalanceRechargedModal));
