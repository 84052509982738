import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, string, number } from 'prop-types';
import {
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import { API_KEY, MERCHANT_ID } from '../../../config/api';
import sessionActions from '../../../actions/session';
import Header from '../../Header';
import Button from '../../Button';
import CustomSelect from '../../CustomSelect';
import CreatedCardModal from '../../Modals/CreatedCardModal';
import CustomInput from '../../CustomInput';
import strings from '../../../language';
import styles from '../../../styles';

class AddPaymentMethodPage extends Component {
  constructor(props) {
    super(props);
    const { name = '' } = props;
    this.state = {
      cardNumber: '',
      name,
      expirationMonth: 'MM',
      expirationYear: 'YYYY',
      cvv: '',
      expirationMonthError: false,
      expirationYearError: false,
      nameError: false,
      cardNumberError: false,
      cvvError: false,
      createdCardModal: false
    };
  }

  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    } else {
      const openpayScript = document.createElement('script');
      openpayScript.src = 'https://js.openpay.mx/openpay.v1.min.js';
      openpayScript.async = true;
      document.head.appendChild(openpayScript);
      openpayScript.onload = () => this.openpayScriptLoaded();
    }
  }

  openpayScriptLoaded = () => {
    window.OpenPay.setId(MERCHANT_ID);
    window.OpenPay.setApiKey(API_KEY);
    const openpayDataScript = document.createElement('script');
    openpayDataScript.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
    openpayDataScript.async = true;
    document.head.appendChild(openpayDataScript);
  };

  handleFocus = (id) => {
    this.setState({ [`${id}Error`]: false });
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  saveCard = (event) => {
    event.preventDefault();
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId
    } = this.props;
    const {
      cardNumber,
      name,
      expirationMonth,
      expirationYear,
      cvv
    } = this.state;
    if (
      expirationMonth !== 'MM' &&
      expirationYear !== 'YYYY' &&
      name.length > 0 &&
      cardNumber.length >= 15 &&
      cardNumber.length < 17 &&
      cvv.length >= 3 &&
      cvv.length < 5
    ) {
      const deviceSessionId = window.OpenPay.deviceData.setup();
      actionsFromSession.addCreditCard(
        communityMemberId,
        authToken,
        cardNumber,
        name,
        expirationYear.substring(2, 4),
        expirationMonth,
        cvv,
        deviceSessionId,
        this.getCrediCards,
        this.openCreatedCardModal
      );
    } else {
      if (expirationMonth === 'MM') {
        this.setState({ expirationMonthError: true });
      }
      if (expirationYear === 'YYYY') {
        this.setState({ expirationYearError: true });
      }
      if (name.length === 0) {
        this.setState({ nameError: true });
      }
      if (cardNumber.length < 15) {
        this.setState({ cardNumberError: true });
      }
      if (cvv.length < 3) {
        this.setState({ cvvError: true });
      }
    }
  };

  getCrediCards = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId
    } = this.props;
    actionsFromSession.getUserCreditCards(communityMemberId, authToken);
  };

  openCreatedCardModal = () => {
    this.setState({
      createdCardModal: true
    });
  };

  render() {
    const { classes, loading } = this.props;
    const {
      paymentMethodsLabel,
      saveNewCard,
      cardNumberLabel,
      cvvLabel,
      nameOnCard,
      expirationLabel
    } = strings;
    const {
      cardNumber,
      name,
      expirationMonth,
      expirationYear,
      cvv,
      expirationMonthError,
      expirationYearError,
      nameError,
      cardNumberError,
      cvvError,
      createdCardModal
    } = this.state;
    const expirationMonthClass = expirationMonthError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const expirationYearClass = expirationYearError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const nameClass = nameError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const cardNumberClass = cardNumberError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const cvvClass = cvvError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    let actualYear = moment().format('YYYY');
    actualYear = moment().year();
    const monthOptions = [
      'MM',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ];
    const yearOptions = [
      'YYYY',
      actualYear.toString(),
      (actualYear + 1).toString(),
      (actualYear + 2).toString(),
      (actualYear + 3).toString(),
      (actualYear + 4).toString(),
      (actualYear + 5).toString(),
      (actualYear + 6).toString(),
      (actualYear + 7).toString()
    ];
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{paymentMethodsLabel}</h1>
    );
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <CreatedCardModal
          createdCardModal={createdCardModal}
          closeCreatedCardModal={() => this.goTo('/top-up')}
          cardNumber={cardNumber}
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => this.goTo('/machine-inventory')}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          >
            <p className={classes.fullWidthInputLabel}>{cardNumberLabel}</p>
            <FormControl className={classes.fullWidthInput}>
              <CustomInput
                value={cardNumber}
                onChange={(e) => this.handleChange(e, 'cardNumber')}
                className={classes.select}
                onFocus={() => this.handleFocus('cardNumber')}
                error={cardNumberError}
                classes={cardNumberClass}
              />
            </FormControl>
            <p className={classes.fullWidthInputLabel}>{nameOnCard}</p>
            <FormControl className={classes.fullWidthInput}>
              <CustomInput
                value={name}
                onChange={(e) => this.handleChange(e, 'name')}
                className={classes.select}
                onFocus={() => this.handleFocus('name')}
                error={nameError}
                classes={nameClass}
              />
            </FormControl>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              direction="row"
              wrap="nowrap"
              className={classes.fullWidthInputLabel}
            >
              <p className={classes.expirationLabel}>{expirationLabel}</p>
              <p className={classes.cvvLabel}>{cvvLabel}</p>
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              direction="row"
              wrap="nowrap"
              className={classes.fullWidthInputLabel}
            >
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                direction="row"
                wrap="nowrap"
                className={classes.expirationInputs}
              >
                <FormControl className={classes.expirationInput}>
                  <Select
                    placeholder="MM"
                    value={expirationMonth}
                    onChange={(e) => this.handleChange(e, 'expirationMonth')}
                    input={<CustomSelect />}
                    onFocus={() => this.handleFocus('expirationMonth')}
                    error={expirationMonthError}
                    classes={expirationMonthClass}
                  >
                    {monthOptions.map((monthOption) => (
                      <MenuItem key={monthOption} value={monthOption}>
                        {monthOption}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.expirationInput}>
                  <Select
                    placeholder="YYYY"
                    value={expirationYear}
                    onChange={(e) => this.handleChange(e, 'expirationYear')}
                    input={<CustomSelect />}
                    onFocus={() => this.handleFocus('expirationYear')}
                    error={expirationYearError}
                    classes={expirationYearClass}
                  >
                    {yearOptions.map((yearOption) => (
                      <MenuItem key={yearOption} value={yearOption}>
                        {yearOption}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <FormControl className={classes.cvvInput}>
                <CustomInput
                  value={cvv}
                  onChange={(e) => this.handleChange(e, 'cvv')}
                  className={classes.select}
                  onFocus={() => this.handleFocus('cvv')}
                  error={cvvError}
                  classes={cvvClass}
                />
              </FormControl>
            </Grid>
            <Button size="md" type="primary" action={this.saveCard}>
              {saveNewCard}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

AddPaymentMethodPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  loading: bool.isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  communityMemberId: number,
  name: string
};

AddPaymentMethodPage.defaultProps = {
  authToken: '',
  communityMemberId: null,
  name: ''
};

const mapStateToProps = (state) => {
  const { loading, communityMember } = state.session;
  const {
    auth_token: authToken,
    id: communityMemberId,
    name
  } = communityMember;
  return {
    loading,
    authToken,
    communityMemberId,
    name
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddPaymentMethodPage));
