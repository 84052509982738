import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { shape, arrayOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';

const Banners = ({ classes, banners }) => {

  const sabaLink =
  'https://www.saba.com.mx/';

  if (banners.length === 0) {
    return null;
  }
  return (
    <Grid
      container
      alignItems="center"
      className={
        banners.length === 1
          ? classes.singleBannerContainer
          : classes.bannersContainer
      }
      wrap="nowrap"
    >
      {banners.map((banner) => {
        if (banner.banner_type === 'image') {
          return (
            <a
              href={sabaLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.bannerImage}
            >
              <img
                key={banner.id}
                className={classes.bannerImage}
                src={banner.url}
                alt={banner.url}
              />
            </a>
          );
        }
        return null;
      })}
    </Grid>
  );
};

Banners.propTypes = {
  classes: shape({}).isRequired,
  banners: arrayOf(shape({}))
};

Banners.defaultProps = {
  banners: []
};

const mapStateToProps = (state) => {
  const { selectedVendingMachine } = state.vendingMachine;
  const { banners } = selectedVendingMachine;
  return {
    banners
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Banners));
