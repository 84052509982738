export const GET_VENDING_MACHINE_REQUEST = 'GET_VENDING_MACHINE_REQUEST';
export const GET_VENDING_MACHINE_SUCCESS = 'GET_VENDING_MACHINE_SUCCESS';
export const GET_VENDING_MACHINE_FAIL = 'GET_VENDING_MACHINE_FAIL';
export const GET_VENDING_MACHINE_KEYBOARD_INFO_REQUEST =
  'GET_VENDING_MACHINE_KEYBOARD_INFO_REQUEST';
export const GET_VENDING_MACHINE_KEYBOARD_INFO_SUCCESS =
  'GET_VENDING_MACHINE_KEYBOARD_INFO_SUCCESS';
export const GET_VENDING_MACHINE_KEYBOARD_INFO_FAIL =
  'GET_VENDING_MACHINE_KEYBOARD_INFO_FAIL';
export const GET_RECENT_VENDING_MACHINES_REQUEST =
  'GET_RECENT_VENDING_MACHINES_REQUEST';
export const GET_RECENT_VENDING_MACHINES_SUCCESS =
  'GET_RECENT_VENDING_MACHINES_SUCCESS';
export const GET_RECENT_VENDING_MACHINES_FAIL =
  'GET_RECENT_VENDING_MACHINES_FAIL';
export const GET_INVENTORY_REQUEST = 'GET_INVENTORY_REQUEST';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAIL = 'GET_INVENTORY_FAIL';
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL';
export const SELECT_PRODUCT_SUCCESS = 'SELECT_PRODUCT_SUCCESS';
export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAIL = 'GET_PRODUCT_BY_ID_FAIL';
export const CREATE_NEW_DEAL_REQUEST = 'CREATE_NEW_DEAL_REQUEST';
export const CREATE_NEW_DEAL_SUCCESS = 'CREATE_NEW_DEAL_SUCCESS';
export const CREATE_NEW_DEAL_FAIL = 'CREATE_NEW_DEAL_FAIL';
export const GET_DEAL_STATUS_REQUEST = 'GET_DEAL_STATUS_REQUEST';
export const GET_DEAL_STATUS_SUCCESS = 'GET_DEAL_STATUS_SUCCESS';
export const GET_DEAL_STATUS_FAIL = 'GET_DEAL_STATUS_FAIL';
export const RESET = 'RESET';
