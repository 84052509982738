import { Button } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomArrowButton = withStyles(() =>
  createStyles({
    root: {
      minWidth: 36,
      height: 40,
      textTransform: 'none',
      fontSize: '1.2rem',
      padding: 0,
      border: 'none',
      borderRadius: 5,
      marginLeft: 5,
      backgroundColor: '#DF1995',
      color: '#ffffff',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#DF1995'
      },
      '&:active': {
        backgroundColor: '#DF1995',
        outline: 'none'
      },
      '&:focus': {
        backgroundColor: '#DF1995',
        outline: 'none'
      }
    }
  })
)(Button);

export default CustomArrowButton;
