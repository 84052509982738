import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number } from 'prop-types';
import { Grid } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Header from '../../Header';
import sessionActions from '../../../actions/session';
import styles from '../../../styles';
import strings from '../../../language';
import PrimaryButton from '../../Button/PrimaryButton';

class DownloadWithCampaignPage extends Component {
  componentDidMount() {
    const {
      authToken,
      communityMemberId,
      sessionActions: actionsFromSession
    } = this.props;
    if (!authToken) {
      this.goTo('/');
    } else {
      actionsFromSession.getMemberNewBalance(communityMemberId, authToken);
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, ctaText, ctaImageUrl, ctaUrl, ctaButtonText } = this.props;

    const onClick = () => {
      window.location.href = ctaUrl;
    };
    const { downloadSuccessful } = strings;
    return (
      <Grid
        className={classes.containerWithHeader}
        container
        justify="flex-start"
        alignItems="flex-start"
      >
        <Header
          leftContent={<ChevronLeft />}
          leftAction={() => this.goTo('/machine-inventory')}
          type="primary"
        />
        <Grid
          container
          className={classes.bodyWithHeaderContainer}
          justify="space-evenly"
          alignItems="center"
          direction="column"
        >
          <h1
            className={classes.bodyDefaultTitle}
            style={{ marginTop: '20px' }}
          >
            {downloadSuccessful}
          </h1>
          {ctaImageUrl && (
            <img
              src={ctaImageUrl}
              alt={ctaText}
              className={classes.centeredImage}
            />
          )}
          <p className={classes.bodyDefaultText}>{ctaText}</p>
          <Grid container className={classes.mediumButtonContainer}>
            <PrimaryButton color="primary" onClick={onClick}>
              {ctaButtonText}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

DownloadWithCampaignPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  selectedVendingMachineName: string,
  authToken: string,
  communityMemberId: number,
  ctaImageUrl: string,
  ctaButtonText: string,
  ctaUrl: string,
  ctaText: string
};

DownloadWithCampaignPage.defaultProps = {
  selectedVendingMachineName: '',
  authToken: '',
  communityMemberId: null,
  ctaImageUrl: '',
  ctaButtonText: null,
  ctaUrl: null,
  ctaText: null
};

const mapStateToProps = (state) => {
  console.log('state', state);
  const { communityMember } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const { selectedVendingMachine, deal } = state.vendingMachine;
  const {
    cta_button_text: ctaButtonText,
    cta_image_url: ctaImageUrl,
    cta_text: ctaText,
    cta_url: ctaUrl
  } = deal;
  const { name: selectedVendingMachineName } = selectedVendingMachine;
  return {
    selectedVendingMachineName,
    authToken,
    communityMemberId,
    ctaButtonText,
    ctaImageUrl,
    ctaText,
    ctaUrl
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DownloadWithCampaignPage));
