export const VERIFY_TICKET_REQUEST = "VERIFY_TICKET_REQUEST";
export const VERIFY_TICKET_SUCCESS = "VERIFY_TICKET_SUCCESS";
export const VERIFY_TICKET_FAIL = "VERIFY_TICKET_FAIL";

export const SEND_TICKET_PHOTO_REQUEST = "SEND_TICKET_PHOTO_REQUEST";
export const SEND_TICKET_PHOTO_SUCCESS = "SEND_TICKET_PHOTO_SUCCESS";
export const SEND_TICKET_PHOTO_FAIL = "SEND_TICKET_PHOTO_FAIL";

export const SEND_REVISION_REQUEST = "SEND_REVISION_REQUEST";
export const SEND_REVISION_SUCCESS = "SEND_REVISION_SUCCESS";
export const SEND_REVISION_FAIL = "SEND_REVISION_FAIL";
