import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, func, arrayOf, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';
import History from '@material-ui/icons/History';
import ContactSupport from '@material-ui/icons/ContactSupport';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
// import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import Payment from '@material-ui/icons/Payment';
import classnames from 'classnames';
import languageActions from '../../actions/language';
import styles from '../../styles';
import strings from '../../language';

const MenuOptions = ({ classes, goTo, creditCards, language, languageActions }) => {
  const {
    scanTicketLabel,
    topUpLabel,
    historyLabel,
    contactLabel,
    // myWalletsLabel,
    paymentMethodsLabel,
    changeLanguage
  } = strings;

  const menuOptions = [
    // {
    //   icon: <AttachMoney className={classes.menuOptionIcon} />,
    //   label: topUpLabel,
    //   action: () => {
    //     goTo(creditCards.length > 0 ? '/top-up' : '/select-payment-method');
    //   }
    // },
    {
      icon: <FullscreenIcon className={classes.menuOptionIcon} />,
      label: scanTicketLabel,
      action: () => {
        goTo('/ticket-scan');
        // goTo('/registration-page');
      }
    },
    {
      icon: <History className={classes.menuOptionIcon} />,
      label: historyLabel,
      action: () => {
        goTo('/history');
      }
    },
    {
      icon: <ContactSupport className={classes.menuOptionIcon} />,
      label: contactLabel,
      action: () => {
        // window.open('https://tawk.to/chat/5913b9fa64f23d19a89b1a00/default');
        window.open('https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm');
      }
    }
    // {
    //   icon: <AccountBalanceWallet className={classes.menuOptionIcon} />,
    //   label: myWalletsLabel,
    //   action: () => {}
    // },
    // {
    //   icon: <Payment className={classes.menuOptionIcon} />,
    //   label: paymentMethodsLabel,
    //   action: () => {
    //     goTo('/payment-methods');
    //   }
    // }
  ];
  return (
    <Grid
      container
      alignItems="flex-start"
      direction="column"
      className={classes.menuOptionsContainer}
      style={{ flexGrow: 1 }}
    >
      {menuOptions.map(({ icon, label, action }) => (
        <Grid
          key={label}
          container
          justify="flex-start"
          alignItems="center"
          className={classes.menuOption}
          onClick={action}
        >
          {icon}
          <p className={classes.bodyDefaultText}>{label}</p>
        </Grid>
      ))}
      {/* <Grid
        container
        justify="center"
        alignItems="flex-end"
        className={classes.menuOption}
        style={{
          flexGrow: 1
        }}
      >
        <p
          className={classnames(
            classes.bodyDefaultText,
            classes.centered,
            classes.bodyDefaultLink
          )}
          onClick={() => {
            const actionsFromLanguage = languageActions;
            const newLanguage = language === 'es' ? 'en' : 'es';
            strings.setLanguage(newLanguage);
            actionsFromLanguage.setLanguage(newLanguage);
          }}
        >
          {changeLanguage}
        </p>
      </Grid> */}
    </Grid>
  );
};

MenuOptions.propTypes = {
  classes: shape({}).isRequired,
  goTo: func.isRequired,
  creditCards: arrayOf(shape({})).isRequired,
  language: string,
  languageActions: shape({}).isRequired
};

const mapStateToProps = (state) => {
  const { creditCards } = state.session;
  const { language } = state.language;
  return {
    creditCards,
    language
  };
};

const mapDispatchToProps = (dispatch) => ({
  languageActions: bindActionCreators(languageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MenuOptions));
