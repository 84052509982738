import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

/* eslint-disable jsx-a11y/media-has-caption */

class CustomMessageModal extends Component {
  handleAction = (url) => {
    const { closeCustomMessagedModal } = this.props;
    window.open(url);
    closeCustomMessagedModal();
  };

  secondHandleAction = () => {
    const { cookies } = this.props;
    cookies.set('dontShowCustomMessageModal', true, {
      path: '/',
      maxAge: 31536000
    });
  };

  render() {
    const {
      classes,
      customMessagedModal,
      closeCustomMessagedModal,
      content
    } = this.props;
    const {
      action_button_text: actionButtonText,
      action_url: actionUrl,
      description,
      image_url: imageUrl,
      summary,
      title
    } = content;
    const { dontShowAgain } = strings;
    return (
      <Modal open={customMessagedModal} onClose={closeCustomMessagedModal}>
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          className={classes.customMessageModalContainer}
        >
          <Clear
            className={classes.closeButton}
            onClick={closeCustomMessagedModal}
          />
          <p className={classes.bodyDefaultText}>{summary && summary.trim()}</p>
          {imageUrl.includes('mp4') ? (
            <video
              className={classes.customModalMedia}
              src={imageUrl}
              autoPlay
              loop
            />
          ) : (
            <img
              className={classes.customModalMedia}
              src={imageUrl}
              alt={title}
            />
          )}
          <p className={classes.bodySmallText}>
            {description && description.trim()}
          </p>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <div style={{ width: '45%' }}>
              <Button
                type="primary"
                action={() => this.handleAction(actionUrl)}
              >
                {actionButtonText}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <Button type="outlined" action={this.secondHandleAction}>
                {dontShowAgain}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

CustomMessageModal.propTypes = {
  classes: shape({}).isRequired,
  customMessagedModal: bool.isRequired,
  closeCustomMessagedModal: func.isRequired,
  content: shape({}),
  cookies: shape({}).isRequired
};

CustomMessageModal.defaultProps = {
  content: {}
};

export default withStyles(styles)(CustomMessageModal);
