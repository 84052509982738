import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, number, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';
import classnames from 'classnames';

const sabaLogo = require('../../assets/Saba_logo_02.png');

const chatLink = 'https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm';

const InstructionsModal = ({
  classes,
  instructionsModal,
  closeInstructionsModal,
  newBalance
}) => {

  const {
    afterTopUpMessageTitle,
    afterTopUpMessageStart,
    confirmLabel,
    signInTitle,
    sabaRewardsTitleOne,
    sabaRewardsTitleTwo,
    instructions,
    textHelp,
  } = strings;
  return (
    <Modal open={instructionsModal} onClose={closeInstructionsModal}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.loginModalContainer}
        wrap="nowrap"
      >
        <img
          src={sabaLogo}
          alt="Nimanic"
          className={classes.nimanicLogo}
        />
        <h1 className={classes.sabaTitleOne}>{sabaRewardsTitleOne}&reg; <span className={classes.sabaTitleTwo}>{sabaRewardsTitleTwo}</span></h1>
        <div className={classes.fullWidthInput}>
          <h1 className={classes.titledUnderlined}>{instructions}</h1>
          <ol>
            <li className={classes.bodyDefaultText}>Regístrate</li>
            <li className={classes.bodyDefaultText}>Escanea tu ticket y obtén puntos</li>
            <li className={classes.bodyDefaultText}>Canjea tus puntos por regalos</li>
          </ol>
        </div>
        <Grid
          item
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="md" type="primary" action={closeInstructionsModal}>
            Comenzar
          </Button>
        </Grid>
        <div className={classes.loginContactContainer}>
          <p className={classnames(classes.bodyDefaultText, classes.centered)}>
            <a
              href={chatLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.bodyDefaultLink}
            >
              {textHelp}
            </a>
          </p>
        </div>
      </Grid>
    </Modal>
  );
};

InstructionsModal.propTypes = {
  classes: shape({}).isRequired,
  balanceRechargedModal: bool.isRequired,
  closeBalanceRechargedModal: func.isRequired,
  newBalance: number.isRequired
};

const mapStateoProps = (state) => {
  const { newBalance } = state.session;
  return {
    newBalance
  };
};

export default connect(
  mapStateoProps,
  null
)(withStyles(styles)(InstructionsModal));