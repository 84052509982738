import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func, bool, number } from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';
import HeaderSabaTwo from '../../HeaderSabaTwo';

const sabaLogo = require('../../../assets/Saba_logo_02.png');

const TicketNoDetected = ({
    classes,
    closeAction,
    takeButtonAction,
    manualButtonAction,
    title,
    errorStatus,
    isLoading,
}) => {

    return (
        <div
            // className={classes.generalContainerScanTicket}
            className={errorStatus === 13 ? (classes.generalContainerScanTicketTwo) : (classes.generalContainerScanTicket)}
        >
            <div className={classes.width100}>
                <HeaderSabaTwo
                    closeAction={closeAction}
                />
            </div>
            <div style={{ maxWidth: 350 }}>
                {/* <p className={classes.sabaTitleTwoBold}>No pudimos detectar los productos Saba en tu ticket</p> */}
                <p className={classes.sabaTitleTwoBold} style={{ maxWidth: 310 }}>{title ? title : `No pudimos detectar los productos Saba en tu ticket`}</p>
            </div>
            {errorStatus === 13 ? (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <Button size="md" type="primary" action={closeAction}>
                        Continuar
                    </Button>
                </div>
            ) : (
                <>
                    <div style={{ maxWidth: 240 }}>
                        <p className={classes.generalTextSaba} style={{ marginBottom: 20 }}>¿Qué quieres hacer?</p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <Button size="md" type="primary" action={takeButtonAction}>
                            Reintentar foto
                        </Button>
                        <div style={{ maxWidth: 330 }}>
                            <p className={classes.generalTextSabaSmall}>Revisa que tu foto esté bien iluminada, enfocada, sin sombras y que salga tu ticket completo.</p>
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 60 }}>
                        <Button size="md" type="outlined" action={manualButtonAction}>
                            {isLoading}
                        </Button>
                        <div style={{ maxWidth: 330 }}>
                            <p className={classes.generalTextSabaSmall}>Si tu ticket es válido, agregaremos tus puntos manualmente. Podría tomar unas horas.</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

TicketNoDetected.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    takeButtonAction: func,
    manualButtonAction: func,
    title: string,
    errorStatus: number,
};

TicketNoDetected.defaultProps = {
    closeAction: () => { },
    takeButtonAction: () => { },
    manualButtonAction: () => { },
    title: "",
    errorStatus: 0,
};

export default withStyles(styles)(TicketNoDetected);