import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number } from 'prop-types';
import { Grid } from '@material-ui/core';
import vendingMachineActions from '../../../actions/vendingMachine';
import styles from '../../../styles';
import strings from '../../../language';

const machineReadyImage = require('../../../assets/machine-ready.png');
// const machineReadyImage = require('../../../assets/cafe1.gif');

class AutomaticDeliveryPage extends Component {
  componentDidMount() {
    const { userTypeTimeout, authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
    const timerFunc = setInterval(() => {
      this.checkStatusChange(timerFunc);
    }, 1000);
    setTimeout(() => {
      clearInterval(timerFunc);
      this.goToError();
    }, userTypeTimeout * 1000 + 10000);
  }

  checkStatusChange = (timerFunc) => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      selectedVendingMachineId,
      dealId,
      authToken
    } = this.props;
    actionsFromVendingMachine.getDealStatus(
      dealId,
      selectedVendingMachineId,
      authToken,
      timerFunc,
      false,
      this.goTo,
      2
    );
  };

  goToError = () => {
    const { deliveryStatus } = this.props;
    if (deliveryStatus === 2) {
      this.goTo('/connection-error');
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes } = this.props;
    const { machineReady, delivering } = strings;
    return (
      <Grid
        container
        justify="start"
        alignItems="center"
        direction="column"
        wrap="nowrap"
      >
        <div className={classes.bodyDownloadText} style={{ marginTop: '10%' }}>
          <h1 className={classes.bodyDefaultTitle}>{machineReady}</h1>
          <p className={classes.bodyDefaultText}>{delivering}</p>
        </div>
        <img
          src={machineReadyImage}
          alt=""
          className={classes.connectingImage}
        />
      </Grid>
    );
  }
}

AutomaticDeliveryPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  authToken: string.isRequired,
  selectedVendingMachineId: number,
  userTypeTimeout: number,
  deliveryStatus: number,
  dealId: number
};

AutomaticDeliveryPage.defaultProps = {
  selectedVendingMachineId: null,
  userTypeTimeout: null,
  deliveryStatus: null,
  dealId: null
};

const mapStateToProps = (state) => {
  const { communityMember, balances } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    selectedVendingMachine,
    deal: {
      id: dealId,
      delivery_status: deliveryStatus,
      payment_type: { code: saleType } = {},
      user_code: userCode
    } = {},
    vendingMachineKeyboardInfo
  } = state.vendingMachine;
  const {
    id: selectedVendingMachineId,
    name: selectedVendingMachineName,
    user_type_timeout: userTypeTimeout
  } = selectedVendingMachine;
  return {
    authToken,
    communityMemberId,
    balances,
    selectedVendingMachineId,
    selectedVendingMachineName,
    userTypeTimeout,
    deliveryStatus,
    saleType,
    userCode,
    dealId,
    vendingMachineKeyboardInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutomaticDeliveryPage));
