import { API } from "../config/api";

export const validateTicket = (authToken, ticket, productServerId) =>

  fetch(`${API}/api/v4/tickets`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Token token=${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_number: ticket,
      product_server_id: productServerId
    }),
  });

export const sendPhoto = (authToken, productServerId, photo) => {

  const formData = new FormData();
  formData.append("photo", photo);

  console.log("photo", photo);
  console.log("id", productServerId);
  console.log("authToken", authToken);

  return fetch(`${API}/api/v4/tickets/${productServerId}/validate_ticket`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      Authorization: `Token token=${authToken}`,
      // "Content-Type": "image/png",
    },
    body: formData,
  });
};

export const sendRevision = (authToken, productServerId, photo) => {

  const formData = new FormData();
  formData.append("photo", photo);

  console.log("photo", photo);
  console.log("id", productServerId);
  console.log("authToken", authToken);

  return fetch(`${API}/api/v4/tickets/${productServerId}/review_ticket`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      Authorization: `Token token=${authToken}`,
      // "Content-Type": "image/png",
    },
    body: formData,
  });
};
