import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { shape, string } from "prop-types";
import { withCookies } from "react-cookie";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import languageActions from "../actions/language";
import sessionActions from "../actions/session";
import vendingMachineActions from "../actions/vendingMachine";
import strings from "../language";
import LoginPage from "../components/Pages/LoginPage";
import PhoneVerificationPage from "../components/Pages/PhoneVerificationPage";
import MachineSelectionPage from "../components/Pages/MachineSelectionPage";
import MachineInventoryPage from "../components/Pages/MachineInventoryPage";
import ProfilePage from "../components/Pages/ProfilePage";
// import KeyboardPage from '../components/Pages/KeyboardPage';
import ProductDetailPage from "../components/Pages/ProductDetailPage";
import ConnectingToMachinePage from "../components/Pages/ConnectingToMachinePage";
import ConnectionErrorPage from "../components/Pages/ConnectionErrorPage";
import TransactionErrorPage from "../components/Pages/TransactionErrorPage";
import MachineReadyPage from "../components/Pages/MachineReadyPage";
import DownloadWithCampaignPage from "../components/Pages/DownloadWithCampaignPage";
import DownloadPage from "../components/Pages/DownloadPage";
import TopUpPage from "../components/Pages/TopUpPage";
import PaymentMethodsPage from "../components/Pages/PaymentMethodsPage";
import AddPaymentMethodPage from "../components/Pages/AddPaymentMethodPage";
import SelectPaymentMethodPage from "../components/Pages/SelectPaymentMethodPage";
import HistoryPage from "../components/Pages/HistoryPage";
import TicketScan from "../components/Pages/TicketScan";
import EditProfilePage from "../components/Pages/EditProfilePage";
import AutomaticDeliveryPage from "../components/Pages/AutomaticDeliveryPage";
import NotFoundPage from "../components/NotFoundPage";
import EdenredCallbackPage from "../components/Pages/EdenredCallbackPage";
import PageWrapper from "../components/PageWrapper";
import { createTheme } from "@material-ui/core/styles";
import InsufficientPoints from "../components/Pages/InsufficientPoints";
import RegistrationPage from "../components/Pages/RegistrationPage";

const history = createBrowserHistory();

const routes = [
  {
    RouteComponent: PhoneVerificationPage,
    path: "/phone-verification",
  },
  {
    RouteComponent: MachineSelectionPage,
    path: "/machine-selection",
  },
  {
    RouteComponent: MachineSelectionPage,
    path: "/machine-selection/:selectNew",
  },
  {
    RouteComponent: MachineInventoryPage,
    path: "/machine-inventory",
  },
  {
    RouteComponent: MachineInventoryPage,
    path: "/machine-inventory/:balanceRecharged",
  },
  {
    RouteComponent: ProfilePage,
    path: "/profile",
  },
  {
    RouteComponent: EditProfilePage,
    path: "/edit-profile",
  },
  {
    RouteComponent: MachineReadyPage,
    path: "/machine-ready",
  },
  {
    RouteComponent: AutomaticDeliveryPage,
    path: "/auto-delivery",
  },
  {
    RouteComponent: ProductDetailPage,
    path: "/product/:productId",
  },
  {
    RouteComponent: ConnectingToMachinePage,
    path: "/connecting",
  },
  {
    RouteComponent: ConnectionErrorPage,
    path: "/connection-error",
  },
  {
    RouteComponent: TransactionErrorPage,
    path: "/transaction-error",
  },
  {
    RouteComponent: DownloadWithCampaignPage,
    path: "/download_with_campaign",
  },
  {
    RouteComponent: DownloadPage,
    path: "/download",
  },
  {
    RouteComponent: TopUpPage,
    path: "/top-up",
  },
  {
    RouteComponent: PaymentMethodsPage,
    path: "/payment-methods",
  },
  {
    RouteComponent: SelectPaymentMethodPage,
    path: "/select-payment-method",
  },
  {
    RouteComponent: AddPaymentMethodPage,
    path: "/add-payment-method",
  },
  {
    RouteComponent: HistoryPage,
    path: "/history",
  },
  {
    RouteComponent: TicketScan,
    path: "/ticket-scan",
  },
  {
    RouteComponent: RegistrationPage,
    path: "/registration-page",
  },
  {
    RouteComponent: InsufficientPoints,
    path: "/insufficient-points",
  },
  {
    RouteComponent: MachineInventoryPage,
    path: "/:vendingMachineName",
  },
  {
    RouteComponent: EdenredCallbackPage,
    path: "/edenred/callback",
  },
  {
    RouteComponent: LoginPage,
    path: "/",
  },
];

class AppRouter extends Component {
  componentDidMount() {
    const { languageActions: actionsFromLanguage, language } = this.props;
    const newLanguage = language === "es" ? "en" : "es";
    strings.setLanguage(newLanguage);
    actionsFromLanguage.setLanguage(newLanguage);
    this.checkUserInSession();
  }

  checkUserInSession = () => {
    const {
      sessionActions: actionsFromSession,
      vendingMachineActions: actionsFromVendingMachine,
      cookies,
    } = this.props;
    const cookiesVars = cookies.getAll();
    const {
      authToken: authTokenFromCookies = null,
      communityMemberId: communityMemberIdFromCookies = null,
      selectedVendingMachineId: selectedVendingMachineIdFromCookies = null,
      country: countryFromCookies = null,
    } = cookiesVars;

    const authTokenFromSession = sessionStorage.getItem("authToken");
    const communityMemberIdFromSession =
      sessionStorage.getItem("communityMemberId");
    const selectedVendingMachineIdFromSession = sessionStorage.getItem(
      "selectedVendingMachineId"
    );
    console.log("authTokenFromCookies", authTokenFromCookies);
    console.log("countryFromCookies", countryFromCookies);
    console.log("communityMemberIdFromCookies", communityMemberIdFromCookies);
    console.log(
      "selectedVendingMachineIdFromCookies",
      selectedVendingMachineIdFromCookies
    );
    console.log("authTokenFromSession", authTokenFromSession);
    console.log("communityMemberIdFromSession", communityMemberIdFromSession);
    if (authTokenFromSession && communityMemberIdFromSession) {
      console.log("session");
      actionsFromSession.getMemberInfo(
        communityMemberIdFromSession,
        authTokenFromSession,
        cookies
      );
      actionsFromSession.getMemberBalance(
        communityMemberIdFromSession,
        authTokenFromSession
      );
      if (selectedVendingMachineIdFromSession) {
        actionsFromVendingMachine.getVendingMachineById(
          selectedVendingMachineIdFromSession,
          authTokenFromSession,
          cookies
        );
      }
    } else if (authTokenFromCookies && communityMemberIdFromCookies) {
      console.log("cookies");
      actionsFromSession.getMemberInfo(
        communityMemberIdFromCookies,
        authTokenFromCookies,
        cookies
      );
      actionsFromSession.getMemberBalance(
        communityMemberIdFromCookies,
        authTokenFromCookies
      );
      if (selectedVendingMachineIdFromCookies) {
        actionsFromVendingMachine.getVendingMachineById(
          selectedVendingMachineIdFromCookies,
          authTokenFromCookies,
          cookies
        );
      }
    }
  };

  setLanguage = (language) => {
    const { languageActions: actionsFromLanguage } = this.props;
    strings.setLanguage(language);
    actionsFromLanguage.setLanguage(language);
  };

  render() {
    const { cookies, language } = this.props;
    if (!language) {
      return null;
    }
    const projectTheme = createTheme({
      nimanic: {
        colors: {
          primaryHeaderBackgroundColor: "#1258C4",
          primaryHeaderFontColor: "#FFFFFF",
          secondaryHeaderBackgroundColor: "rgba(255, 255, 255, 0.9)",
          secondaryHeaderFontColor: "#1258C4",
          bodyBackgroundColor: "#FFFFFF",
          bodyFontColor: "#4A4A4A",
          inventoryBackgroundColor: "#F2F2F5",
          primaryButtonBackgroundColor: "#1258C4",
          primaryButtonFontColor: "#FFFFFF",
          secondaryButtonBackgroundColor: "#FF8000",
          secondaryButtonFontColor: "#FFFFFF",
          disabledButtonBackgroundColor: "#C2C2C2",
          disabledButtonFontColor: "#FFFFFF",
          footerBackgroundColor: "rgba(255, 255, 255, 0.9)",
          footerFontColor: "#4A4A4A",
          primaryColor: "#1258C4",
          secondaryColor: "#4A4A4A",
          accentColor: "#FF8000",
          alertColor: "#C02D28",
        },
        sizes: {
          font: {
            xs: "0.8rem",
            sm: "1rem",
            md: "1.2rem",
            lg: "1.4rem",
            xl: "1.5rem",
          },
          button: {
            sm: "8rem",
            md: "12.5rem",
            lg: "16.5rem",
          },
        },
      },
      nestle: {
        colors: {
          primaryHeaderBackgroundColor: "#020202",
          primaryHeaderFontColor: "#ffffff",
          secondaryHeaderBackgroundColor: "rgba(255, 255, 255, 0.9)",
          secondaryHeaderFontColor: "#001E62",
          bodyBackgroundColor: "#FFFFFF",
          bodyFontColor: "#4A4A4A",
          inventoryBackgroundColor: "#F2F2F5",
          primaryButtonBackgroundColor: "#E79115",
          primaryButtonFontColor: "#FFFFFF",
          secondaryButtonBackgroundColor: "#AA0132",
          secondaryButtonFontColor: "#FFFFFF",
          disabledButtonBackgroundColor: "#C2C2C2",
          disabledButtonFontColor: "#FFFFFF",
          footerBackgroundColor: "rgba(255, 255, 255, 0.9)",
          footerFontColor: "#4A4A4A",
          primaryColor: "#5C5C5C", // main text highligh
          secondaryColor: "#001E62",
          accentColor: "#001E62",
          alertColor: "#C02D28",
        },
        sizes: {
          font: {
            xs: "0.8rem",
            sm: "1rem",
            md: "1.2rem",
            lg: "1.4rem",
            xl: "1.5rem",
          },
          button: {
            sm: "8rem",
            md: "12.5rem",
            lg: "16.5rem",
          },
        },
      },
      automateIQ: {
        colors: {
          primaryHeaderBackgroundColor: "#0A2343",
          primaryHeaderFontColor: "#ffffff",
          secondaryHeaderBackgroundColor: "rgba(255, 255, 255, 0.9)",
          secondaryHeaderFontColor: "#F05123",
          bodyBackgroundColor: "#FFFFFF",
          bodyFontColor: "#4A4A4A",
          inventoryBackgroundColor: "#F2F2F5",
          primaryButtonBackgroundColor: "#E79115",
          primaryButtonFontColor: "#FFFFFF",
          secondaryButtonBackgroundColor: "#AA0132",
          secondaryButtonFontColor: "#FFFFFF",
          disabledButtonBackgroundColor: "#C2C2C2",
          disabledButtonFontColor: "#FFFFFF",
          footerBackgroundColor: "rgba(255, 255, 255, 0.9)",
          footerFontColor: "#0A2343",
          primaryColor: "#5C5C5C", // main text highligh
          secondaryColor: "#F05123",
          accentColor: "#F05123",
          alertColor: "#C02D28",
        },
        sizes: {
          font: {
            xs: "0.8rem",
            sm: "1rem",
            md: "1.2rem",
            lg: "1.4rem",
            xl: "1.5rem",
          },
          button: {
            sm: "8rem",
            md: "12.5rem",
            lg: "16.5rem",
          },
        },
      },
      saba: {
        colors: {
          primaryHeaderBackgroundColor: "#001E62",
          primaryHeaderFontColor: "#FFFFFF",
          secondaryHeaderBackgroundColor: "rgba(255, 255, 255, 0.9)",
          secondaryHeaderFontColor: "#DF1995",
          bodyBackgroundColor: "#FFFFFF",
          bodyFontColor: "#4A4A4A",
          inventoryBackgroundColor: "#F2F2F5",
          primaryButtonBackgroundColor: "#1258C4",
          primaryButtonFontColor: "#FFFFFF",
          secondaryButtonBackgroundColor: "#FF8000",
          secondaryButtonFontColor: "#FFFFFF",
          disabledButtonBackgroundColor: "#C2C2C2",
          disabledButtonFontColor: "#FFFFFF",
          footerBackgroundColor: "rgba(255, 255, 255, 0.9)",
          footerFontColor: "#4A4A4A",
          primaryColor: "#DF1995",
          secondaryColor: "#001E62",
          accentColor: "#DF1995",
          alertColor: "#C02D28",
        },
        sizes: {
          font: {
            xs: "0.8rem",
            sm: "1rem",
            md: "1.2rem",
            lg: "1.4rem",
            xl: "1.5rem",
            xxl: "1.7rem",
          },
          button: {
            sm: "8rem",
            md: "12.5rem",
            lg: "16.5rem",
          },
        },
      },
    });
    return (
      <ThemeProvider theme={projectTheme}>
        <Router history={history}>
          <Switch>
            {routes.map(({ RouteComponent, path }) => (
              <Route
                key={path}
                path={path}
                exact
                component={({ history: historyComp, location, match }) => (
                  <PageWrapper
                    cookies={cookies}
                    history={historyComp}
                    location={location}
                    match={match}
                    RouteComponent={RouteComponent}
                  />
                )}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}

AppRouter.propTypes = {
  cookies: shape({}).isRequired,
  language: string.isRequired,
  languageActions: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  languageActions: bindActionCreators(languageActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch),
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(AppRouter)
);
