export const produceCategories = (inventoryList = []) => {
  inventoryList.sort(
    (a, b) =>
      a.product_category.priority_order - b.product_category.priority_order
  );
  const categories = [];
  const several = 'Varios';
  inventoryList.map((product) => {
    const { product_category: productCategory = {} } = product;
    if (productCategory !== null && productCategory !== 'null') {
      const { name = '', display_name: displayName = '' } = productCategory;
      const categoryExists = categories.some(
        (category) => category.id === name
      );
      if (!categoryExists) {
        categories.push({ id: name, title: displayName });
      }
    } else {
      const categoryExists = categories.some(
        (category) => category.id === several
      );
      if (!categoryExists) {
        categories.push({ id: several, title: several });
      }
    }
    return null;
  });
  return categories.map((category) => ({
    title: category.title,
    contents: inventoryList.filter((inventoryItem) => {
      if (
        inventoryItem.product_category !== null &&
        inventoryItem.product_category !== 'null'
      ) {
        return inventoryItem.product_category.name.includes(category.id);
      }
      return several.includes(category.id);
    })
  }));
};

export const trimmString = (string, length) =>
  string.length > length
    ? string.substring(0, length - 3).concat('...')
    : string;

export const getOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }

  if (navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows';
  }

  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }

  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux';
  }

  return 'Unknown';
};

const getBrowser = () => {
  const { userAgent } = navigator;

  const isFirefoxIOS = userAgent.indexOf('FxiOS') > -1;

  if (isFirefoxIOS) {
    return 'Firefox';
  }

  const isFirefox = userAgent.indexOf('Firefox') > -1;

  if (isFirefox) {
    return 'Firefox';
  }

  const isChrome = userAgent.indexOf('Chrome') > -1;

  if (isChrome) {
    return 'Chrome';
  }

  if (userAgent.indexOf('Edge') > -1) {
    return 'Edge';
  }

  if (userAgent.indexOf('MSIE ') > 0) {
    return 'Explorer';
  }

  const isChromeIOS = userAgent.indexOf('CriOS') > -1;

  if (isChromeIOS) {
    return 'Chrome';
  }

  const isSafari = userAgent.indexOf('Safari') > -1;

  if (isSafari) {
    return 'Safari';
  }

  return 'Unknown';
};

export const isSupported = () => {
  const operatingStystem = getOperatingSystem();
  const browser = getBrowser();

  if (operatingStystem === 'iOS' && browser !== 'Safari') {
    return false;
  }

  if (operatingStystem === 'Unknown') {
    return false;
  }

  return true;
};
