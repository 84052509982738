import React, { Component, createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { func, shape, string } from 'prop-types';
import styles from '../../styles';

const CODE_LENGTH = new Array(4).fill(0);

class CodeInput extends Component {
  constructor() {
    super();
    this.input = createRef();
  }

  handleClick = () => {
    this.input.current.focus();
  };

  render() {
    const { classes, handleChange, handleKeyUp, value } = this.props;
    const values = value.split('');
    const selectedIndex =
      values.length < CODE_LENGTH.length
        ? values.length
        : CODE_LENGTH.length - 1;
    const hideInput = !(values.length < CODE_LENGTH.length);
    return (
      <div className={classes.newCodeInputContainer} onClick={this.handleClick}>
        {CODE_LENGTH.map((v, index) => (
          <div className={classes.newCodeInputCell}>{values[index]}</div>
        ))}
        <input
          value=""
          ref={this.input}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          className={classes.newCodeInput}
          style={{
            width: '32px',
            top: '0px',
            bottom: '0px',
            left: `${selectedIndex * 32}px`,
            opacity: hideInput ? 0 : 1
          }}
        />
      </div>
    );
  }
}

CodeInput.propTypes = {
  classes: shape({}).isRequired,
  handleChange: func.isRequired,
  handleKeyUp: func.isRequired,
  value: string
};

CodeInput.defaultProps = {
  value: ''
};

export default withStyles(styles)(CodeInput);
