import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';

const ticketPhotoExample = require('../../../assets/ticketPhotoExample.png');

const PhotoTakeExample = ({
    classes,
    closeAction,
    buttonAction,
}) => {

    return (
        <div
            className={classes.generalContainerScanTicket}
        >
            <div className={classes.width100}>
                <HeaderSaba
                    closeAction={closeAction}
                    title={"Toma una foto de tu ticket con productos Saba para acumular puntos"}
                />
            </div>
            {/* <div style={{ maxWidth: 320, marginTop: 15, marginBottom: 30 }}>
                <p className={classes.generalTextSaba}>Toma una foto de tu ticket completo con al menos un producto Saba</p>
            </div> */}
            <div style={{ marginTop: 30 }}>
                <img src={ticketPhotoExample} alt="scan-icon" className={classes.sabaThicketPhotoExample} />
            </div>
            <div style={{ maxWidth: 330, marginTop: 20, marginBottom: 2 }}>
                {/* <p className={classes.generalSecondaryTextSaba}>Revisa que tu foto esté bien iluminada, enfocada, sin sombras y que salga tu ticket completo</p> */}
                <p className={classes.generalSecondaryTextSabaNotCentered}>Tips:</p>
                <ul>
                    <li>Estira tu brazo para alejar el ticket.</li>
                    <li>Evita sombras y arrugas.</li>
                    <li>Céntralo vertical y horizontalmente.</li>
                    <li>Incluye desde el logo de Walmart hasta el código de barras.</li>
                </ul>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 60 }}>
                <Button size="md" type="primary" action={buttonAction}>
                    Tomar foto
                </Button>
            </div>
        </div>
    );
};

PhotoTakeExample.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    buttonAction: func,
};

PhotoTakeExample.defaultProps = {
    closeAction: () => { },
    buttonAction: () => { }
};

export default withStyles(styles)(PhotoTakeExample);