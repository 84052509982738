import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, func } from 'prop-types';
import styles from '../../../styles';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const TakePhotoTwo = ({
    classes,
    handleTakePhoto,
}) => {

    return (
        // <div
        //     className={classes.mainPhotoContainer}
        // >
        //     <div className={classes.photoComponentContainer}>
        <Camera
            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri) }}
            idealFacingMode="environment"
            imageType='jpg'
            isDisplayStartCameraError={true}
            isMaxResolution={true}
            // idealResolution={{ width: "auto", height: "90%" }}
            // isFullscreen={true}
            sizeFactor={1}
            // isSilentMode={true}
        />
        // </div>
        // </div>
    );
};

TakePhotoTwo.propTypes = {
    classes: shape({}).isRequired,
    handleTakePhoto: func,
};

TakePhotoTwo.defaultProps = {
    handleTakePhoto: (data) => { }
};

export default withStyles(styles)(TakePhotoTwo);