import { sendPhoto, sendRevision, validateTicket } from "../../services/tickets";
import {
  SEND_TICKET_PHOTO_FAIL,
  SEND_TICKET_PHOTO_REQUEST,
  SEND_TICKET_PHOTO_SUCCESS,
  VERIFY_TICKET_FAIL,
  VERIFY_TICKET_REQUEST,
  VERIFY_TICKET_SUCCESS,
  SEND_REVISION_REQUEST,
  SEND_REVISION_SUCCESS,
  SEND_REVISION_FAIL,
} from "./types";

const verifyTicketBarcodeRequest = () => ({
  type: VERIFY_TICKET_REQUEST,
});

const verifyTicketBarcodeSuccess = (ticket) => ({
  type: VERIFY_TICKET_SUCCESS,
  ticket,
});

const verifyTicketBarcodeFail = (error) => ({
  type: VERIFY_TICKET_FAIL,
  error,
});

const verifyTicketBarcode = (authToken, ticket, productServerId, callback) => (dispatch) => {
  dispatch(verifyTicketBarcodeRequest());
  validateTicket(authToken, ticket, productServerId)
    .then((res) => res.json())
    .then((json) => {
      const { error = "" } = json;
      callback();
      if (!error) {
        dispatch(verifyTicketBarcodeSuccess(json.ticket));
        // callback();
      } else {
        dispatch(verifyTicketBarcodeFail(error));
      }
    })
    .catch((error) => {
      dispatch(verifyTicketBarcodeFail(error));
    });
};

const sendTicketPhotoRequest = () => ({
  type: SEND_TICKET_PHOTO_REQUEST,
});

const sendTicketPhotoSuccess = (ticket) => ({
  type: SEND_TICKET_PHOTO_SUCCESS,
  ticket
});

const sendTicketPhotoFail = (error) => ({
  type: SEND_TICKET_PHOTO_FAIL,
  error,
});

const sendTicketPhoto =
  (authToken, id, photo, callback, callbackError) => (dispatch) => {
    dispatch(sendTicketPhotoRequest());
    sendPhoto(authToken, id, photo)
      .then((res) => res.json())
      .then((json) => {
        const { error = "" } = json;
        if (!error && json.ticket.points !== 0) {
          dispatch(sendTicketPhotoSuccess(json.ticket));
          callback(json.ticket.status);
        } else {
          dispatch(sendTicketPhotoFail(error));
          callbackError(error.message, error.code);
        }
      })
      .catch((error) => {
        dispatch(sendTicketPhotoFail(error));
        callbackError(error.message);
      });
  };

  const sendRevisionRequest = () => ({
    type: SEND_REVISION_REQUEST,
  });
  
  const sendRevisionSuccess = (ticket) => ({
    type: SEND_REVISION_SUCCESS,
    ticket
  });
  
  const sendRevisionFail = (error) => ({
    type: SEND_REVISION_FAIL,
    error,
  });
  
  const sendRevisionPhoto =
    (authToken, id, photo, callback, callbackError) => (dispatch) => {
      dispatch(sendRevisionRequest());
      sendRevision(authToken, id, photo)
        .then((res) => res.json())
        .then((json) => {
          const { error = "" } = json;
          if (!error) {
            dispatch(sendRevisionSuccess(json.ticket));
            callback(json.ticket.status);
          } else {
            dispatch(sendRevisionFail(error));
            callbackError();
          }
        })
        .catch((error) => {
          dispatch(sendRevisionFail(error));
          callbackError();
        });
    };

export default {
  verifyTicketBarcode,
  sendTicketPhoto,
  sendRevisionPhoto,
};
