import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import styles from '../../../styles';
import Button from '../../Button';

const processingImage = require('../../../assets/processing-saba.gif');

const Processing = ({
    classes,
}) => {

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.generalContainer}
        >
            <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p className={classes.sabaTitleTwoBold} style={{ maxWidth: 320 }}>Usando inteligencia artificial para analizar tu ticket</p>
                <p className={classes.generalTextSabaSmall} style={{ marginBottom: 40 }}>...podría tomar hasta 30 segundos</p>
                <img src={processingImage} alt="fotografía tomada" className={classes.processingImageStyle} />
                <p className={classes.generalTextSabaSmall} style={{ marginTop: 40 }}>no actualices ni cierres la ventana</p>
            </div>
        </Grid>
    );
};

Processing.propTypes = {
    classes: shape({}).isRequired,
};

Processing.defaultProps = {
    closeAction: () => { },
};

export default withStyles(styles)(Processing);