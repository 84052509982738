import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import RefreshPageModal from '../Modals/RefreshPageModal';
import styles from '../../styles';

class Keyboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshPageModal: false
    };
    const { vendingMachineName } = props;
    this.ws = new WebSocket(
      `wss://app.nimanic.com/api/v1/ws/${vendingMachineName}`
    );
  }

  componentDidMount() {
    this.ws.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    this.ws.onmessage = (message) => {
      console.log(message);
    };
    this.ws.onclose = () => {
      console.log('WebSocket Client Closed');
      this.showRefreshPageModal();
    };
  }

  showRefreshPageModal = () => {
    this.setState({ refreshPageModal: true });
  };

  closeRefreshPageModal = () => {
    this.setState({ refreshPageModal: false });
  };

  sendButtonValue = (e) => {
    e.preventDefault();
    this.ws.send(JSON.stringify({ key: e.target.value }));
  };

  render() {
    const { classes } = this.props;
    const { refreshPageModal } = this.state;
    const keyboardButtons = [
      {
        value: '1',
        text: 1,
        usedClass: 'keyboardButton'
      },
      {
        value: '2',
        text: 2,
        usedClass: 'keyboardButton'
      },
      {
        value: '3',
        text: 3,
        usedClass: 'keyboardButton'
      },
      {
        value: '4',
        text: 4,
        usedClass: 'keyboardButton'
      },
      {
        value: '5',
        text: 5,
        usedClass: 'keyboardButton'
      },
      {
        value: '6',
        text: 6,
        usedClass: 'keyboardButton'
      },
      {
        value: '7',
        text: 7,
        usedClass: 'keyboardButton'
      },
      {
        value: '8',
        text: 8,
        usedClass: 'keyboardButton'
      },
      {
        value: '9',
        text: 9,
        usedClass: 'keyboardButton'
      },
      {
        value: '*_',
        text: '*',
        usedClass: 'keyboardButton'
      },
      {
        value: '0',
        text: 0,
        usedClass: 'keyboardButton'
      },
      {
        value: '#_',
        text: '#',
        usedClass: 'keyboardButton'
      },
      {
        value: 'return',
        text: 'Return',
        usedClass: 'largeKeyboardButton'
      },
      {
        value: 'cancel',
        text: 'Cancel',
        usedClass: 'largeKeyboardButton'
      }
    ];
    return (
      <>
        <RefreshPageModal
          refreshPageModal={refreshPageModal}
          closeRefreshPageModal={this.closeRefreshPageModal}
        />
        <Grid container className={classes.keyboardContainer}>
          {keyboardButtons.map(({ value, text, usedClass }) => (
            <button
              key={value}
              onClick={this.sendButtonValue}
              value={value}
              type="button"
              className={classes[usedClass]}
            >
              {text}
            </button>
          ))}
        </Grid>
      </>
    );
  }
}

Keyboard.propTypes = {
  classes: shape({}).isRequired,
  vendingMachineName: string.isRequired
};

export default withStyles(styles)(Keyboard);
