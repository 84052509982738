import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, bool, number } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/es';
import {
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CompleteVerificationModal from '../../Modals/CompleteVerificationModal';
import sessionActions from '../../../actions/session';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import styles from '../../../styles';
import strings from '../../../language';

const genderOptions = [
  { menuOption: 'Hombre', optionValue: 'Hombre' },
  { menuOption: 'Mujer', optionValue: 'Mujer' },
  { menuOption: 'Otro', optionValue: 'Otro' }
];

class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    const { name, email, birthDate, gender } = this.props;
    this.state = {
      name,
      email,
      birthDate: this.formatDate(birthDate) || '',
      gender: gender || '',
      nameError: false,
      emailError: false,
      birthDateError: false,
      genderError: false,
      completeVerificationModal: false
    };
  }

  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  showCompleteVerificationModal = () => {
    this.setState({ completeVerificationModal: true });
  };

  hideCompleteVerificationModal = () => {
    this.getMemberInfo();
    this.setState({ completeVerificationModal: false });
  };

  getMemberInfo = () => {
    const {
      selectedProduct,
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken,
      cookies
    } = this.props;
    let goTo = () => {
      this.goTo('/profile');
    };
    if (JSON.stringify(selectedProduct) !== '{}') {
      goTo = () => {
        this.goTo(`/product/${selectedProduct.id}`);
      };
    }
    actionsFromSession.getMemberInfo(
      communityMemberId,
      authToken,
      cookies,
      goTo
    );
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  handleDateChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    if (value.length === 2 || value.length === 5) {
      this.setState({ [id]: `${value}/` });
    } else {
      this.setState({ [id]: value });
    }
  };

  handleFocus = (id) => {
    this.setState({ [`${id}Error`]: false });
  };

  resendMailVerification = () => {
    const {
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken
    } = this.props;
    actionsFromSession.resendEmailVerification(
      communityMemberId,
      authToken,
      this.showCompleteVerificationModal
    );
  };

  formatDate = (date) => {
    if (date) {
      const newDate = new Date(date);
      const formatedDate = newDate.toISOString().split('T')[0];
      console.log('date', formatedDate);
      return formatedDate;
    }
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   const {
  //     sessionActions: actionsFromSession,
  //     communityMemberId,
  //     authToken,
  //     cookies,
  //     email: emailFromProps
  //   } = this.props;
  //   const { email, birthDate, gender } = this.state;
  //   let callback = this.getMemberInfo;
  //   if (emailFromProps !== email) {
  //     callback = this.showCompleteVerificationModal;
  //   }
  //   if (email && birthDate && gender) {
  //     actionsFromSession.updateUser(
  //       communityMemberId,
  //       authToken,
  //       "user",
  //       birthDate,
  //       gender,
  //       email,
  //       cookies,
  //       callback
  //     );
  //   } else {
  //     // if (!name) {
  //     //   this.setState({ nameError: true });
  //     // }
  //     if (!email) {
  //       this.setState({ emailError: true });
  //     }
  //   }
  // };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      sessionActions: actionsFromSession,
      communityMemberId,
      authToken,
      cookies,
      // email: emailFromProps
    } = this.props;
    const { birthDate, gender, name, email } = this.state;
    let callback = this.getMemberInfo;
    // if (emailFromProps !== email) {
    //   callback = this.showCompleteVerificationModal;
    // }
    if (birthDate && gender) {
      actionsFromSession.updateUser(
        communityMemberId,
        authToken,
        name ? name : "user",
        birthDate,
        gender,
        email ? email : null,
        cookies,
        callback
      );
    } else {
      // if (!name) {
      //   this.setState({ nameError: true });
      // }
      // if (!email) {
      //   this.setState({ emailError: true });
      // }
    }
  };

  render() {
    const { classes, loading } = this.props;
    const {
      name,
      email,
      birthDate,
      gender,
      nameError,
      emailError,
      birthDateError,
      genderError,
      completeVerificationModal
    } = this.state;
    console.log("email", email);
    console.log("name", name);
    const {
      saveLabel,
      enterNamePlaceholder,
      birthDatePlaceholder,
      enterEmailPlaceholder,
      nameLabel,
      emailLabel,
      birthDateLabel,
      genderLabel,
      resendMailVerification
    } = strings;
    const headerRightContent = (
      <p className={classes.headerDefaultText}>{saveLabel.toUpperCase()}</p>
    );
    const nameClass = nameError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const emailClass = emailError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const birthDateClass = birthDateError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    const genderClass = genderError
      ? { root: classes.inputRootError }
      : { root: classes.inputRoot };
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <CompleteVerificationModal
          completeVerificationModal={completeVerificationModal}
          hideCompleteVerificationModal={this.hideCompleteVerificationModal}
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => {
              this.goTo('/profile');
            }}
            rightContent={headerRightContent}
            rightAction={this.handleSubmit}
            type="secondary"
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            {/* <p
              className={classnames(
                classes.fullWidthInputLabel,
                classes.noMargin
              )}
            >
              {nameLabel}
            </p>
            <FormControl className={classes.nameInput}>
              <CustomInput
                placeholder={enterNamePlaceholder}
                value={name}
                onChange={(e) => this.handleChange(e, 'name')}
                onFocus={() => this.handleFocus('name')}
                error={nameError}
                className={classes.select}
                classes={nameClass}
              />
            </FormControl> */}
            <Grid
              container
              justify="space-between"
              className={classes.twoColInput}
            >
              <p className={classes.halfWidthInputLabel}>{birthDateLabel}</p>
              <p className={classes.halfWidthInputLabel}>{genderLabel}</p>
            </Grid>
            <Grid
              container
              justify="space-between"
              className={classes.twoColInput}
            >
              <FormControl className={classes.birthDateInputEdit}>
                <CustomInput
                  placeholder={birthDatePlaceholder}
                  value={birthDate}
                  type="date"
                  onChange={(e) => this.handleDateChange(e, 'birthDate')}
                  onFocus={() => this.handleFocus('birthDate')}
                  error={birthDateError}
                  className={classes.select}
                  classes={birthDateClass}
                />
              </FormControl>
              <FormControl className={classes.genderInput}>
                <Select
                  value={gender}
                  onChange={(e) => this.handleChange(e, 'gender')}
                  onFocus={() => this.handleFocus('gender')}
                  error={genderError}
                  input={<CustomSelect />}
                  classes={genderClass}
                >
                  {genderOptions.map(({ menuOption, optionValue }) => (
                    <MenuItem key={optionValue} value={optionValue}>
                      {menuOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <p className={classes.fullWidthInputLabel}>{emailLabel}</p>
            <FormControl className={classes.emailInput}>
              <CustomInput
                placeholder={enterEmailPlaceholder}
                value={email}
                onChange={(e) => this.handleChange(e, 'email')}
                onFocus={() => this.handleFocus('email')}
                error={emailError}
                className={classes.select}
                classes={emailClass}
              />
            </FormControl> */}
          </Grid>
          {/* {emailFromProps && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.resendMailVerificationContainer}
            >
              <p onClick={this.resendMailVerification}>
                {resendMailVerification}
              </p>
            </Grid>
          )} */}
        </Grid>
      </>
    );
  }
}

EditProfilePage.propTypes = {
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  loading: bool.isRequired,
  name: string,
  email: string | null,
  birthDate: string,
  gender: string,
  communityMemberId: number,
  selectedProduct: shape({}).isRequired
};

EditProfilePage.defaultProps = {
  authToken: '',
  name: '',
  email: null,
  birthDate: '',
  gender: '',
  communityMemberId: null
};

const mapStateToProps = (state) => {
  const { communityMember, loading } = state.session;
  const {
    id: communityMemberId,
    auth_token: authToken,
    name,
    email,
    birth_date: birthDate,
    balance,
    gender,
    last_transaction: lastTransaction
  } = communityMember;
  const { selectedProduct } = state.vendingMachine;
  return {
    authToken,
    communityMemberId,
    loading,
    name,
    email,
    birthDate,
    balance,
    gender,
    lastTransaction,
    selectedProduct
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditProfilePage));
