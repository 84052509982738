import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import Clear from '@material-ui/icons/Clear';

const HeaderSaba = ({
    classes,
    closeAction,
    title,
}) => {
    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
                <div onClick={closeAction} style={{ padding: 10, marginLeft: 10, marginTop: 10 }}>
                    <Clear style={{ color: "#DF1995" }} onClick={closeAction} />
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: 300 }}>
                    <h2 className={classes.titlePrimaryColor}>{title}</h2>
                </div>
            </div>
        </div>
    );
};

HeaderSaba.propTypes = {
    classes: shape({}).isRequired,
    title: string,
    closeAction: func,
};

HeaderSaba.defaultProps = {
    title: null,
    closeAction: () => { },
};

export default withStyles(styles)(HeaderSaba);