import * as types from '../actions/session/types';

const initialState = {
  phone: '',
  country: '+52',
  communityMember: {},
  balances: [],
  error: '',
  loading: false,
  creditCards: [],
  newBalance: 0,
  rechargedBalance: 0,
  addedCreditCard: {},
  deals: [],
  customMessages: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        phone: action.phone,
        country: action.country,
        loading: false
      };
    case types.GET_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.VERIFY_CODE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        communityMember: action.communityMember,
        loading: false
      };
    case types.VERIFY_CODE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case types.GET_MEMBER_INFO_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        communityMember: action.communityMember,
        loading: false
      };
    case types.GET_MEMBER_INFO_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_MEMBER_BALANCE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_MEMBER_BALANCE_SUCCESS:
      return {
        ...state,
        balances: action.balances,
        loading: false
      };
    case types.GET_MEMBER_BALANCE_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_USER_CREDIT_CARDS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_USER_CREDIT_CARDS_SUCCESS:
      return {
        ...state,
        creditCards: action.creditCards,
        loading: false
      };
    case types.GET_USER_CREDIT_CARDS_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_MEMBER_NEW_BALANCE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_MEMBER_NEW_BALANCE_SUCCESS:
      return {
        ...state,
        newBalance: action.newBalance,
        loading: false
      };
    case types.GET_MEMBER_NEW_BALANCE_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.RECHARGE_BALANCE_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.RECHARGE_BALANCE_SUCCESS:
      return {
        ...state,
        rechargedBalance: action.rechargedBalance,
        loading: false
      };
    case types.RECHARGE_BALANCE_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.DELETE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.ADD_CREDIT_CARD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        addedCreditCard: action.addedCreditCard
      };
    case types.ADD_CREDIT_CARD_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_DEALS_HISTORY_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_DEALS_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        deals: action.deals
      };
    case types.GET_DEALS_HISTORY_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_TOP_UP_INFO_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.UPDATE_TOP_UP_INFO_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_TOP_UP_INFO_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_MEMBER_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.GET_MEMBER_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.GET_MEMBER_CONFIGURATIONS_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_MEMBER_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.UPDATE_MEMBER_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.UPDATE_MEMBER_CONFIGURATIONS_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.SEND_RATING_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.SEND_RATING_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.SEND_RATING_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.GET_COMMUNITY_MEMBER_MESSAGES_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        customMessages: []
      };
    case types.GET_COMMUNITY_MEMBER_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        customMessages: action.customMessages
      };
    case types.GET_COMMUNITY_MEMBER_MESSAGES_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.ADD_EDENRED_CARD_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };
    case types.ADD_EDENRED_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        addedCreditCard: action.addedCreditCard
      };
    case types.ADD_EDENRED_CARD_FAIL:
      return {
        ...state,
        loading: false
      };
    case types.LOGOUT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
