import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import sessionActions from '../../../actions/session';
import Header from '../../Header';
import strings from '../../../language';
import styles from '../../../styles';

const americanExpress = require('../../../assets/american-express-logo.png');
const masterCard = require('../../../assets/mastercard-logo.png');
const visa = require('../../../assets/visa-logo.png');
const edenred = require('../../../assets/edenred.png');

class SelectPaymentMethodPage extends Component {
  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, language } = this.props;
    const { paymentMethodsLabel, selectPaymentMethod } = strings;
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{paymentMethodsLabel}</h1>
    );
    return (
      <>
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => this.goTo('/machine-inventory')}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          >
            <p>{selectPaymentMethod}</p>
            <Grid
              container
              justify="center"
              onClick={() => {
                this.goTo('/add-payment-method');
              }}
            >
              <Grid className={classes.paymentMethodButton}>
                <Grid className={classes.cardLine} />
                <Grid className={classes.cardName} />
                <Grid className={classes.paymentMethodImages}>
                  <img
                    className={classes.paymentMethodImage}
                    src={masterCard}
                    alt="Master Card"
                  />
                  <img
                    className={classes.paymentMethodImage}
                    src={visa}
                    alt="Visa"
                  />
                  <img
                    className={classes.paymentMethodImage}
                    src={americanExpress}
                    alt="American Express"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              onClick={() => {
                if (language === 'es') {
                  window.open(
                    'https://sso.edenred.io/connect/authorize?response_type=code&client_id=77f9261abf54418c8238bf06a3d445c8&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=http://app.nimanic.com/edenred/callback&state=abc123&nonce=456azerty&acr_values=tenant:mx-ben&ui_locales=es-es'
                  );
                } else {
                  window.open(
                    'https://sso.edenred.io/connect/authorize?response_type=code&client_id=77f9261abf54418c8238bf06a3d445c8&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=http://app.nimanic.com/edenred/callback&state=abc123&nonce=456azerty&acr_values=tenant:mx-ben&ui_locales=en-en'
                  );
                }
              }}
            >
              <Grid className={classes.paymentMethodButton}>
                <Grid className={classes.cardLine} />
                <Grid className={classes.cardName} />
                <Grid className={classes.paymentMethodImages}>
                  <img
                    className={classes.paymentMethodImage}
                    src={edenred}
                    alt="Edenred"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

SelectPaymentMethodPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  authToken: string,
  language: string.isRequired
};

SelectPaymentMethodPage.defaultProps = {
  authToken: ''
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { language } = state.language;
  const { auth_token: authToken } = communityMember;
  return {
    language,
    authToken
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectPaymentMethodPage));
