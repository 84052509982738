import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool, arrayOf } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import ContactSupport from '@material-ui/icons/ContactSupport';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import classnames from 'classnames';
import vendingMachineActions from '../../../actions/vendingMachine';
import styles from '../../../styles';
import strings from '../../../language';
import Header from '../../Header';
import LoginModal from '../../Modals/LoginModal';
import DirectPaymentModal from '../../Modals/DirectPaymentModal';
import PrimaryButton from '../../Button/PrimaryButton';
import DisabledButton from '../../Button/DisabledButton';
import SecondaryButton from '../../Button/SecondaryButton';
import NotConectedMachineModal from '../../Modals/NotConectedMachineModal';

class ProductDetailPage extends Component {
  constructor() {
    super();
    this.state = {
      createdDeal: false,
      loginModal: false,
      directPaymentModal: false,
      notConectedModal: false
    };
    this.bodyWithHeaderContainer = null;
  }

  componentDidMount() {
    const { selectedProduct } = this.props;
    if (JSON.stringify(selectedProduct) === '{}') {
      this.goTo('/machine-inventory');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  handleBuyButtonPressed = (saleType) => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      authToken,
      selectedVendingMachineId,
      selectedProduct,
      language,
      balance,
      directPayment,
      creditCards,
      balances,
    } = this.props;
    const origin = 'web';
    const selectedProductUserCode =
      saleType === 4 ? selectedProduct.upc : selectedProduct.user_code;
    const hasDiscount =
      selectedProduct.sampling && selectedProduct.sampling.price;
    const useDirectPayment = saleType !== 3 || !!hasDiscount;
    const anyProduct = false;
    const sufficientBalance = hasDiscount
      ? selectedProduct.sampling.price > balance
      : selectedProduct.price > balance;
    const enoughBalance = hasDiscount
      ? selectedProduct.sampling.price < balance
      : selectedProduct.price < balance;
    const sabaBalance = balances.find(({ balance_type }) => balance_type.id === 12);
    const finalBalance = sabaBalance ? sabaBalance.amount : 0;
    const hasEnoughBalance = finalBalance >= selectedProduct.price;

    if (authToken) {
      if (hasEnoughBalance) {
        // if (creditCards.length > 0 || saleType === 3 || enoughBalance) {
        //   if (sufficientBalance && directPayment && useDirectPayment) {
        //     console.log('direct payment modal');
        //   } else {
        //     console.log('no direct payment modal');
        actionsFromVendingMachine.createNewDeal(
          authToken,
          selectedVendingMachineId,
          selectedProductUserCode,
          saleType,
          origin,
          anyProduct,
          language,
          this.goTo
        );
        this.setState({ createdDeal: true });
      } else {
        this.goTo('/insufficient-points');
      }
      // } else {
      //   this.openLoginModal();
      //   // this.goTo('/insufficient-points');
      // }
    } else {
      this.openLoginModal();
    }
  };

  openLoginModal = () => {
    this.setState({ loginModal: true });
  };

  closeLoginModal = () => {
    this.setState({ loginModal: false });
  };

  closeDirectPaymentModal = () => {
    this.setState({ directPaymentModal: false });
  };

  onPressNotConectedModal = () => {
    this.setState({ notConectedModal: true });
    this.closeNotConectedModal();
  };

  closeNotConectedModal = () => {
    setTimeout(() => {
      this.setState({ notConectedModal: false });
    }, 3000);
  };

  render() {
    const {
      classes,
      selectedVendingMachineName,
      selectedProduct,
      status,
      balance,
      cookies,
      authToken,
      history,
      balances,
      newBalance,
    } = this.props;
    const { createdDeal, loginModal, directPaymentModal, notConectedModal } = this.state;
    const { priceLabel, freeSample, buyFor, rechargeLabel, pay } = strings;
    const {
      name,
      photo_url: photo,
      price,
      sell_options: sellOptions = [],
      nutritional_information_image_url: nutritionalImageUrl,
      description,
      sampling
    } = selectedProduct;
    const isSellable = sellOptions.includes('sellable');
    const isServiceable = sellOptions.includes('serviceable');
    const isSampleable = sellOptions.includes('sampleable');
    if (JSON.stringify(selectedProduct) === '{}') {
      return null;
    }

    const sabaBalance = balances.find(({ balance_type }) => balance_type.id === 12);

    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>
        {`@${selectedVendingMachineName}`}
      </h1>
    );
    const headerRightContent = (
      // <p className={classes.headerDefaultText}>{`${balance.toFixed()} pts`}</p>
      <p className={classes.headerDefaultText}>
        {/* {`$${newBalance ? newBalance.toFixed(2) : balance.toFixed(2)}`} */}
        {`${sabaBalance ? sabaBalance?.amount?.toFixed() : '0'} pts`}
      </p>
    );
    let buyButtonLabel = `${priceLabel} ${Number(price).toFixed()} pts`;
    if (status !== 'online') {
      buyButtonLabel = `${priceLabel} ${Number(price).toFixed()} pts`;
    } else {
      if (isSellable) {
        buyButtonLabel = `${pay} ${Number(price).toFixed()} pts`;
      }
      if (isServiceable) {
        buyButtonLabel = `${rechargeLabel} ${Number(price).toFixed()} pts`;
      }
      if (isSampleable) {
        buyButtonLabel = freeSample;
      }
    }
    let buyButtonAction = () => { };
    if (status !== 'online') {
      buyButtonAction = () => { };
    } else {
      if (isSellable) {
        buyButtonAction = () => {
          this.handleBuyButtonPressed(2);
        };
      }
      if (isServiceable) {
        buyButtonAction = () => {
          this.handleBuyButtonPressed(4);
        };
      }
      if (isSampleable) {
        buyButtonAction = () => {
          this.handleBuyButtonPressed(3);
        };
      }
    }
    let buyButton = (
      <DisabledButton color="primary" variant="contained">
        {buyButtonLabel}
      </DisabledButton>
    );
    if (status !== 'online') {
      buyButton = (
        <div onClick={this.onPressNotConectedModal}>
          <DisabledButton color="primary" variant="contained">
            {buyButtonLabel}
          </DisabledButton>
        </div>
      );
    } else {
      if (isSellable) {
        buyButton = (
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={buyButtonAction}
          >
            {buyButtonLabel}
          </PrimaryButton>
        );
      }
      if (isServiceable) {
        buyButton = (
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={buyButtonAction}
          >
            {buyButtonLabel}
          </PrimaryButton>
        );
      }
      if (isSampleable) {
        buyButton = (
          <SecondaryButton
            color="primary"
            variant="contained"
            onClick={buyButtonAction}
          >
            {buyButtonLabel}
          </SecondaryButton>
        );
        if (sampling && sampling.price) {
          buyButton = (
            <SecondaryButton
              color="primary"
              variant="contained"
              onClick={buyButtonAction}
            >
              {`${Number(sampling.price).toFixed()} puntos`}
            </SecondaryButton>
          );
        }
      }
      if (createdDeal) {
        buyButton = (
          <DisabledButton color="primary" variant="contained">
            <CircularProgress className={classes.whiteLoader} />
          </DisabledButton>
        );
      }
    }
    return (
      <>
        <LoginModal
          loginModal={loginModal}
          closeLoginModal={this.closeLoginModal}
          cookies={cookies}
          checkPaymentMethods={!isSampleable}
          history={history}
        />
        <DirectPaymentModal
          directPaymentModal={directPaymentModal}
          closeDirectPaymentModal={this.closeDirectPaymentModal}
          history={history}
        />
        <NotConectedMachineModal
          notConectedModal={notConectedModal}
          closeNotConectedModal={this.closeNotConectedModal}
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          {authToken ? (
            <Header
              leftContent={<ChevronLeft />}
              leftAction={() => this.goTo('/machine-inventory')}
              centerContent={headerCenterContent}
              centerAction={() => this.goTo('/machine-inventory')}
              rightContent={headerRightContent}
              type="primary"
            />
          ) : (
            <Header
              leftContent={<ChevronLeft />}
              leftAction={() => this.goTo('/machine-inventory')}
              centerContent={headerCenterContent}
              rightContent={<ContactSupport />}
              rightAction={() => {
                window.open(
                  'https://tawk.to/chat/62fe730837898912e963ce4f/1gaov2mkm'
                );
              }}
              type="primary"
            />
          )}
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          >
            <Grid container>
              <h1 className={classnames(classes.bodyDefaultTitle)}>{name}</h1>
            </Grid>
            <img src={photo} alt={name} className={classes.productPhoto} />
            <Grid container className={classes.mediumButtonContainer}>
              {buyButton}
            </Grid>
            <Grid container>
              <p className={classes.bodyDefaultText}>{description}</p>
            </Grid>
            {nutritionalImageUrl.includes('http') && (
              <img
                src={nutritionalImageUrl}
                alt={name}
                className={classes.productPhoto}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

ProductDetailPage.propTypes = {
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  history: shape({}).isRequired,
  authToken: string,
  selectedProduct: shape({}).isRequired,
  selectedVendingMachineName: string,
  selectedVendingMachineId: number,
  language: string.isRequired,
  status: string,
  balance: number,
  directPayment: bool,
  creditCards: arrayOf(shape({})).isRequired,
  balances: arrayOf(shape({})).isRequired,
};

ProductDetailPage.defaultProps = {
  authToken: '',
  selectedVendingMachineName: '',
  selectedVendingMachineId: null,
  status: '',
  balance: 0,
  directPayment: false
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { communityMember = {}, newBalance, creditCards, balances } = state.session;
  const { auth_token: authToken, balance: cmBalance } = communityMember;
  const { selectedVendingMachine, selectedProduct } = state.vendingMachine;
  const {
    name: selectedVendingMachineName,
    id: selectedVendingMachineId,
    status,
    payment_settings: paymentSettings = { direct_payment: false }
  } = selectedVendingMachine;
  const { direct_payment: directPayment = false } = paymentSettings;
  let balance = cmBalance;
  if (newBalance) {
    balance = newBalance;
  }
  return {
    authToken,
    selectedProduct,
    selectedVendingMachineName,
    selectedVendingMachineId,
    status,
    balance,
    language,
    directPayment,
    creditCards,
    balances,
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductDetailPage));
