import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, oneOfType } from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import vendingMachineActions from '../../../actions/vendingMachine';
import Header from '../../Header';
import styles from '../../../styles';
import PrimaryButton from '../../Button/PrimaryButton';
import strings from '../../../language';
import { Clear } from '@material-ui/icons';
import Button from '../../Button';

class TransactionErrorPage extends Component {
  componentDidMount() {
    const { authToken, error = {} } = this.props;
    if (!authToken || !error) {
      this.goTo('/');
    }
    const { error_code: errorCode, redirect_url: redirectUrl } = error;
    if (errorCode === '-11' && redirectUrl) {
      this.goTo('/campaign-requirement');
    }
  }

  retry = () => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      authToken,
      selectedVendingMachineId,
      selectedProduct,
      language,
      saleType
    } = this.props;
    const origin = 'web';
    const selectedProductUserCode = selectedProduct.user_code;
    const anyProduct = saleType === 2;
    actionsFromVendingMachine.createNewDeal(
      authToken,
      selectedVendingMachineId,
      selectedProductUserCode,
      saleType,
      origin,
      anyProduct,
      language,
      this.goTo
    );
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, error, authToken } = this.props;
    if (!authToken || !error) {
      return null;
    }
    const { internalServerError, tryAgainLater, backLabel, insufficientPointsText, scanTicketLabel, insufficientPointsTitle } = strings;
    const headerCenterContent = (
      <h1 className={classes.headerSecColor}>{insufficientPointsTitle}</h1>
    );
    let messageText = internalServerError;
    let descriptionText = tryAgainLater;
    let additionalText = null;
    let buttonText = backLabel;
    let errorCodeNumber = null;
    let campaignRoute = null;
    let image = null;
    let domain = null;
    if (
      typeof error !== 'string' &&
      JSON.stringify(error) !== '{}' &&
      error !== null
    ) {
      const {
        error_code: errorCode,
        action_text: actionText,
        message,
        description,
        redirect_url: redirectUrl,
        cta_image_url: ctaImageUrl = '',
        email_domain: emailDomain = '',
        additional_information: additionalInfo = ''
      } = error;
      messageText = message;
      descriptionText = description;
      buttonText = actionText;
      errorCodeNumber = errorCode;
      campaignRoute = redirectUrl;
      image = ctaImageUrl;
      domain = emailDomain;
      additionalText = additionalInfo;
    }
    let onClick = () => {
      this.goTo('/machine-inventory');
    };
    if (errorCodeNumber === '-100') {
      onClick = this.retry;
    }
    if (errorCodeNumber === '-13') {
      onClick = () => {
        this.goTo(campaignRoute);
      };
    }
    return (
      // <Grid
      //   className={classes.containerWithHeader}
      //   container
      //   justify="flex-start"
      //   alignItems="flex-start"
      // >
      //   <Header
      //     leftContent={<ChevronLeft />}
      //     leftAction={() => this.goTo('/machine-inventory')}
      //     type="secondary"
      //   />
      //   <Grid
      //     container
      //     className={classes.bodyWithHeaderContainer}
      //     justify="center"
      //     alignItems="center"
      //     direction="column"
      //     wrap="nowrap"
      //   >
      //     <Grid
      //       container
      //       justify="center"
      //       alignItems="center"
      //       direction="column"
      //     >
      //       <h1 className={classes.bodyDefaultTitle}>{messageText}</h1>
      //       {image && (
      //         <img
      //           src={image}
      //           alt={messageText}
      //           className={classes.centeredImage}
      //         />
      //       )}
      //       <p
      //         className={classnames(classes.bodyDefaultText, classes.centered)}
      //       >
      //         {errorCodeNumber === '-13'
      //           ? `${descriptionText} @${domain}`
      //           : descriptionText}
      //       </p>
      //       {additionalText && (
      //         <p
      //           className={classnames(
      //             classes.bodyDefaultText,
      //             classes.centered
      //           )}
      //         >
      //           {additionalText}
      //         </p>
      //       )}
      //       <Grid container className={classes.mediumButtonContainer}>
      //         <PrimaryButton
      //           color="primary"
      //           variant="contained"
      //           onClick={onClick}
      //         >
      //           {buttonText}
      //         </PrimaryButton>
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // </Grid>
      <Grid
          className={classes.containerWithHeader}
          container
          justifyContent="space-between"
          alignItems="space-between"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => {
              this.goTo('/machine-inventory');
            }}
            centerContent={null}
            type="secondary"
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {/* {deals.map((deal) => (
              <DealInfo deal={deal} language={language} />
            ))} */}
            {headerCenterContent}
            <p className={classes.bodyDefaultTextCenter}>{insufficientPointsText}</p>
            <Button size="md" type="primary" action={() => {
              this.goTo('/ticket-scan');
            }}>
              {scanTicketLabel}
            </Button>
          </Grid>
          <div />
        </Grid>
    );
  }
}

TransactionErrorPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  selectedProduct: shape({}).isRequired,
  language: string.isRequired,
  authToken: string.isRequired,
  selectedVendingMachineId: number,
  saleType: number,
  error: oneOfType([shape({}), string])
};

TransactionErrorPage.defaultProps = {
  selectedVendingMachineId: null,
  saleType: null,
  error: {}
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { auth_token: authToken } = communityMember;
  const {
    selectedVendingMachine,
    error,
    selectedProduct,
    deal: { payment_type: { code: saleType } = {} } = {}
  } = state.vendingMachine;
  const { id: selectedVendingMachineId } = selectedVendingMachine;
  const { language } = state.language;
  return {
    authToken,
    selectedVendingMachineId,
    error,
    selectedProduct,
    language,
    saleType
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TransactionErrorPage));
