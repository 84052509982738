import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, func, number, bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import strings from '../../language';

const Tabs = ({ classes, selectedTab, goTo, changeTab, hasKeyboard }) => {
  if (!hasKeyboard) {
    return null;
  }
  const { dealsAndProducts, touchlessKeyboard, buy } = strings;
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.tabsContainer}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className={selectedTab === 0 ? classes.selectedTab : classes.tab}
          onClick={() => {
            if (selectedTab === 0) {
              goTo('/machine-selection/new');
            } else {
              changeTab(0);
            }
          }}
        >
          {dealsAndProducts}
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={selectedTab === 1 ? classes.selectedTab : classes.tab}
          onClick={() => changeTab(1)}
        >
          {hasKeyboard ? touchlessKeyboard : buy}
        </Grid>
      </Grid>
    </>
  );
};

Tabs.propTypes = {
  classes: shape({}).isRequired,
  selectedTab: number.isRequired,
  goTo: func.isRequired,
  changeTab: func.isRequired,
  hasKeyboard: bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    vendingMachineKeyboardInfo,
    selectedVendingMachine
  } = state.vendingMachine;
  const { payment_settings: paymentSettings = {} } = selectedVendingMachine;
  const { mobile_keyboard_type: keyboardType } = vendingMachineKeyboardInfo;
  const hasKeyboard = keyboardType === 'numeric';
  return {
    hasKeyboard,
    paymentSettings
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Tabs));
