import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { shape, string, number, arrayOf, bool } from 'prop-types';
import { Grid, FormControl, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Header from '../../Header';
import vendingMachineActions from '../../../actions/vendingMachine';
import CustomInput from '../../CustomInput';
import QrScanner from '../../QrScanner';
import CustomArrowButton from '../../Button/CustomArrowButton';
import RecentVendingMachine from '../../RecentVendingMachine';
import MachineNotFoundModal from '../../Modals/MachineNotFoundModal';
import styles from '../../../styles';
import strings from '../../../language';

class MachineSelectionPage extends Component {
  constructor() {
    super();
    this.state = {
      machineName: '',
      machineNotFoundModal: false
    };
  }

  async componentDidMount() {
    const {
      communityMemberId,
      authToken,
      selectedVendingMachineId,
      match,
      vendingMachineActions: actionsFromVendingMachine,
      vendingMachineKeyboardInfo
    } = this.props;
    const { params } = match;
    const { selectNew } = params;
    if (selectedVendingMachineId && !selectNew) {
      this.goTo('/machine-inventory');
    } else if (
      JSON.stringify(vendingMachineKeyboardInfo) !== '{}' &&
      !selectNew
    ) {
      await this.setState({ machineName: vendingMachineKeyboardInfo.name });
      this.handleSubmit();
    } else if (communityMemberId && authToken) {
      actionsFromVendingMachine.getRecentVendingMachines(
        authToken,
        communityMemberId
      );
    }
  }

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value.toLowerCase() });
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { machineName } = this.state;
    if (machineName) {
      this.getVendingMachineByName(machineName);
    }
  };

  getVendingMachineByName = (machineName) => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      cookies
    } = this.props;
    actionsFromVendingMachine.getVendingMachineByName(
      machineName,
      cookies,
      this.goTo,
      this.openMachineNotFoundModal
    );
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  openMachineNotFoundModal = () => {
    this.setState({ machineNotFoundModal: true, machineName: '' });
  };

  closeMachineNotFoundModal = () => {
    this.setState({ machineNotFoundModal: false });
  };

  render() {
    const {
      classes,
      recentVendingMachines,
      loadingRecentMachines,
      loading,
      match
    } = this.props;
    const { params } = match;
    const { selectNew } = params;
    const { scan, scanTitle, orEnterID, noRecents, recents } = strings;
    const { machineName, machineNotFoundModal } = this.state;
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <Grid
        className={classes.containerWithHeader}
        container
        justify="flex-start"
        alignItems="flex-start"
      >
        <MachineNotFoundModal
          machineNotFoundModal={machineNotFoundModal}
          closeMachineNotFoundModal={this.closeMachineNotFoundModal}
        />
        <Header
          leftContent={selectNew ? <ChevronLeft /> : ''}
          leftAction={
            selectNew ? () => this.goTo('/machine-inventory') : () => { }
          }
          centerContent={scanTitle}
          type="primary"
        />
        <Grid container justify="center" alignItems="center">
          <QrScanner getVendingMachineByName={this.getVendingMachineByName} />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.smContentContainer}
          >
            <p className={classnames(classes.orEnterID, classes.bodySmallText)}>
              {orEnterID}
            </p>
            <Grid container justify="center" alignItems="center">
              <FormControl>
                <CustomInput
                  placeholder="@saba1234X"
                  value={machineName}
                  onChange={(e) => this.handleChange(e, 'machineName')}
                />
              </FormControl>
              <CustomArrowButton
                onClick={this.handleSubmit}
                color="primary"
                variant="contained"
                className={classes.submitButton}
              >
                <ChevronRight />
              </CustomArrowButton>
            </Grid>
            {/* {!loadingRecentMachines && (
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.recentMachinesContainer}
              >
                {recentVendingMachines.length > 0 ? (
                  <>
                    <h2 className={classes.bodyDefaultText}>{recents}</h2>
                    {recentVendingMachines.map(
                      ({
                        image_url: vendingImage,
                        name: vendingName,
                        contact_info: contactInfo
                      }) => (
                        <RecentVendingMachine
                          key={vendingName}
                          vendingImage={vendingImage}
                          vendingName={vendingName}
                          contactInfo={contactInfo}
                          getVendingMachineByName={this.getVendingMachineByName}
                        />
                      )
                    )}
                  </>
                ) : (
                  <h2 className={classes.bodyDefaultText}>{noRecents}</h2>
                )}
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MachineSelectionPage.propTypes = {
  classes: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  history: shape({}).isRequired,
  cookies: shape({}).isRequired,
  authToken: string,
  communityMemberId: number,
  selectedVendingMachineId: number,
  match: shape({}).isRequired,
  recentVendingMachines: arrayOf(shape({})).isRequired,
  loadingRecentMachines: bool.isRequired,
  loading: bool.isRequired,
  vendingMachineKeyboardInfo: shape({}).isRequired
};

MachineSelectionPage.defaultProps = {
  authToken: '',
  communityMemberId: null,
  selectedVendingMachineId: null
};

const mapStateToProps = (state) => {
  const { communityMember, loading: sessionLoading } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    selectedVendingMachine,
    recentVendingMachines,
    loadingRecentMachines,
    loading: vendingMachineLoading,
    vendingMachineKeyboardInfo
  } = state.vendingMachine;
  const { id: selectedVendingMachineId } = selectedVendingMachine;
  const loading = sessionLoading || vendingMachineLoading;
  return {
    authToken,
    communityMemberId,
    selectedVendingMachineId,
    recentVendingMachines,
    loadingRecentMachines,
    loading,
    vendingMachineKeyboardInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MachineSelectionPage));
