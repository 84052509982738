import * as types from './types';
import {
  getVendingMachineByNameForKeyboardService,
  getRecentVendingMachinesService,
  getVendingMachineByIdService,
  getVendingMachineItemsService,
  getVendingMachineServicesService,
  getProductByIdService,
  createNewDealService,
  createNewDealDirectPaymentService,
  getDealStatusService
} from '../../services/vendingMachine';

const getVendingMachineRequest = () => ({
  type: types.GET_VENDING_MACHINE_REQUEST
});

const getVendingMachineSuccess = (selectedVendingMachine) => ({
  type: types.GET_VENDING_MACHINE_SUCCESS,
  selectedVendingMachine
});

const getVendingMachineFail = (error) => ({
  type: types.GET_VENDING_MACHINE_FAIL,
  error
});

const getVendingMachineById = (vendingMachineId, cookies = null) => (
  dispatch,
  getState
) => {
  const authToken = getState().session.communityMember.auth_token;
  getVendingMachineByIdService(vendingMachineId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { product_server: productServer } = json;
        dispatch(getVendingMachineSuccess(productServer));
        if (cookies) {
          cookies.set('selectedVendingMachineId', productServer.id, {
            path: '/',
            maxAge: 31536000
          });
        }
        sessionStorage.setItem('selectedVendingMachineId', productServer.id);
      } else {
        dispatch(getVendingMachineFail(error));
      }
    })
    .catch((error) => {
      dispatch(getVendingMachineFail(error));
    });
};

const getVendingMachineByName = (
  vendingMachineName,
  cookies = null,
  goTo = () => {},
  openMachineNotFoundModal = () => {}
) => (dispatch) => {
  dispatch(getVendingMachineRequest());
  getVendingMachineByNameForKeyboardService(vendingMachineName)
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { product_server: productServerInfo } = json;
        const { id } = productServerInfo;
        getVendingMachineByIdService(id)
          .then((res) => res.json())
          .then((json2) => {
            const { error2 = '' } = json2;
            if (!error2) {
              const { product_server: productServer } = json2;
              dispatch(getVendingMachineSuccess(productServer));
              if (cookies) {
                cookies.set('selectedVendingMachineId', productServer.id, {
                  path: '/',
                  maxAge: 31536000
                });
              }
              sessionStorage.setItem(
                'selectedVendingMachineId',
                productServer.id
              );
              goTo('/machine-inventory');
            } else {
              dispatch(getVendingMachineFail(error2));
            }
          })
          .catch((error2) => {
            dispatch(getVendingMachineFail(error2));
            openMachineNotFoundModal();
          });
      } else {
        dispatch(getVendingMachineFail(error));
        openMachineNotFoundModal();
      }
    })
    .catch((error) => {
      dispatch(getVendingMachineFail(error));
      openMachineNotFoundModal();
    });
};

const getRecentVendingMachinesRequest = () => ({
  type: types.GET_RECENT_VENDING_MACHINES_REQUEST
});

const getRecentVendingMachinesSuccess = (recentVendingMachines) => ({
  type: types.GET_RECENT_VENDING_MACHINES_SUCCESS,
  recentVendingMachines
});

const getRecentVendingMachinesFail = (error) => ({
  type: types.GET_RECENT_VENDING_MACHINES_FAIL,
  error
});

const getRecentVendingMachines = (authToken, communityMemberId) => (
  dispatch
) => {
  dispatch(getRecentVendingMachinesRequest());
  getRecentVendingMachinesService(authToken, communityMemberId)
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { product_servers: productServers } = json;
        dispatch(getRecentVendingMachinesSuccess(productServers));
      } else {
        dispatch(getRecentVendingMachinesFail(error));
      }
    })
    .catch((error) => {
      dispatch(getRecentVendingMachinesFail(error));
    });
};

const getVendingMachineItemsRequest = () => ({
  type: types.GET_INVENTORY_REQUEST
});

const getVendingMachineItemsSuccess = (productServerItems) => ({
  type: types.GET_INVENTORY_SUCCESS,
  productServerItems
});

const getVendingMachineItemsFail = (error) => ({
  type: types.GET_INVENTORY_FAIL,
  error
});

const getVendingMachineItems = (productServerId) => (dispatch) => {
  dispatch(getVendingMachineItemsRequest());
  return getVendingMachineItemsService(productServerId)
    .then((res) => res.json())
    .then((json) => {
      const { errors = [] } = json;
      if (errors.length === 0) {
        const { product_server_items: productServerItems = [] } = json;
        dispatch(getVendingMachineItemsSuccess(productServerItems));
      } else {
        dispatch(getVendingMachineItemsFail(errors[0]));
      }
    })
    .catch((error) => {
      dispatch(getVendingMachineItemsFail(error));
    });
};

const getVendingMachineServicesRequest = () => ({
  type: types.GET_SERVICES_REQUEST
});

const getVendingMachineServicesSuccess = (productServerServices) => ({
  type: types.GET_SERVICES_SUCCESS,
  productServerServices
});

const getVendingMachineServicesFail = (error) => ({
  type: types.GET_SERVICES_FAIL,
  error
});

const getVendingMachineServices = (productServerId, authToken) => (
  dispatch
) => {
  dispatch(getVendingMachineServicesRequest());
  return getVendingMachineServicesService(productServerId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { errors = [] } = json;
      if (errors.length === 0) {
        const { product_server_items: productServerItems = [] } = json;
        dispatch(getVendingMachineServicesSuccess(productServerItems));
      } else {
        dispatch(getVendingMachineServicesFail(errors[0]));
      }
    })
    .catch((error) => {
      dispatch(getVendingMachineServicesFail(error));
    });
};

const selectProductSuccess = (selectedProduct) => ({
  type: types.SELECT_PRODUCT_SUCCESS,
  selectedProduct
});

const selectProduct = (selectedProduct, goTo) => (dispatch) => {
  dispatch(selectProductSuccess(selectedProduct));
  goTo(`/product/${selectedProduct.id}`);
};

const getProductByIdRequest = () => ({
  type: types.GET_PRODUCT_BY_ID_REQUEST
});

const getProductByIdSuccess = (selectedProduct) => ({
  type: types.GET_PRODUCT_BY_ID_SUCCESS,
  selectedProduct
});

const getProductByIdFail = (error) => ({
  type: types.GET_PRODUCT_BY_ID_FAIL,
  error
});

const getProductById = (productServerId, productId, authToken) => (
  dispatch
) => {
  dispatch(getProductByIdRequest());
  return getProductByIdService(productServerId, productId, authToken)
    .then((res) => res.json())
    .then((json) => {
      const { errors = [] } = json;
      if (errors.length === 0) {
        const { product_server_item: selectedProduct } = json;
        dispatch(getProductByIdSuccess(selectedProduct));
      } else {
        dispatch(getProductByIdFail(errors[0]));
      }
    })
    .catch((error) => {
      dispatch(getProductByIdFail(error));
    });
};

const createNewDealRequest = () => ({
  type: types.CREATE_NEW_DEAL_REQUEST
});

const createNewDealSuccess = (deal, anyProduct) => ({
  type: types.CREATE_NEW_DEAL_SUCCESS,
  deal,
  anyProduct
});

const createNewDealFail = (error) => ({
  type: types.CREATE_NEW_DEAL_FAIL,
  error
});

const createNewDeal = (
  authToken,
  selectedVendingMachineId,
  selectedProductUserCode,
  saleType,
  origin,
  anyProduct,
  language,
  goTo
) => (dispatch) => {
  dispatch(createNewDealRequest());
  createNewDealService(
    authToken,
    selectedVendingMachineId,
    selectedProductUserCode,
    saleType,
    origin,
    anyProduct,
    language
  )
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { deal } = json;
        dispatch(createNewDealSuccess(deal, anyProduct));
        goTo('/connecting');
      } else {
        dispatch(createNewDealFail(error));
        goTo('/transaction-error');
      }
    })
    .catch((error) => {
      dispatch(createNewDealFail(error));
    });
};

const createNewDealDirectPayment = (
  authToken,
  selectedVendingMachineId,
  selectedProductUserCode,
  saleType,
  origin,
  anyProduct,
  creditCardId,
  language,
  goTo
) => (dispatch) => {
  dispatch(createNewDealRequest());
  createNewDealDirectPaymentService(
    authToken,
    selectedVendingMachineId,
    selectedProductUserCode,
    saleType,
    origin,
    anyProduct,
    creditCardId,
    language
  )
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { deal } = json;
        dispatch(createNewDealSuccess(deal, anyProduct));
        goTo('/connecting');
      } else {
        dispatch(createNewDealFail(error));
        goTo('/transaction-error');
      }
    })
    .catch((error) => {
      dispatch(createNewDealFail(error));
    });
};

const getDealStatusRequest = () => ({
  type: types.GET_DEAL_STATUS_REQUEST
});

const getDealStatusSuccess = (deal) => ({
  type: types.GET_DEAL_STATUS_SUCCESS,
  deal
});

const getDealStatusFail = (error) => ({
  type: types.GET_DEAL_STATUS_FAIL,
  error
});

const getDealStatus = (
  dealId,
  selectedVendingMachineId,
  authToken,
  timerFunc,
  automaticDelivery,
  goTo,
  dealStatusChecker
) => (dispatch) => {
  dispatch(getDealStatusRequest());
  getDealStatusService(authToken, selectedVendingMachineId, dealId)
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { deal } = json;
        console.log('result', json);
        dispatch(getDealStatusSuccess(deal));
        const {
          delivery_status: deliveryStatus,
          cta_button_text: ctaButtonText,
          cta_image_url: ctaImageUrl,
          cta_text: ctaText,
          cta_url: ctaUrl
        } = deal;
        const hasCampaign =
          !!ctaButtonText && !!ctaImageUrl && (!!ctaUrl || !!ctaText);
        console.log('hasCampagin', hasCampaign);
        if (deliveryStatus !== dealStatusChecker) {
          clearInterval(timerFunc);
          if (deliveryStatus === 0 && hasCampaign) {
            goTo('/download_with_campaign');
          } else if (deliveryStatus === 0) {
            goTo('/download');
          } else if (deliveryStatus === 2 && automaticDelivery) {
            goTo('/auto-delivery');
          } else if (deliveryStatus === 2) {
            goTo('/machine-ready');
          } else if (deliveryStatus >= 3 && deliveryStatus <= 16) {
            goTo('/connection-error');
          } else {
            goTo('/transaction-error');
          }
        }
      } else {
        dispatch(getDealStatusFail(error));
        goTo('/transaction-error');
      }
    })
    .catch((error) => {
      dispatch(getDealStatusFail(error));
    });
};

const getVendingMachineKeyboardInfoRequest = () => ({
  type: types.GET_VENDING_MACHINE_KEYBOARD_INFO_REQUEST
});

const getVendingMachineKeyboardInfoSuccess = (vendingMachineKeyboardInfo) => ({
  type: types.GET_VENDING_MACHINE_KEYBOARD_INFO_SUCCESS,
  vendingMachineKeyboardInfo
});

const getVendingMachineKeyboardInfoFail = (error) => ({
  type: types.GET_VENDING_MACHINE_KEYBOARD_INFO_FAIL,
  error
});

const getVendingMachineByNameKeyboardInfo = (vendingMachineName, goTo) => (
  dispatch
) => {
  dispatch(getVendingMachineKeyboardInfoRequest());
  getVendingMachineByNameForKeyboardService(vendingMachineName)
    .then((res) => res.json())
    .then((json) => {
      const { error = '' } = json;
      if (!error) {
        const { product_server: productServer } = json;
        dispatch(
          getVendingMachineKeyboardInfoSuccess({
            name: vendingMachineName,
            ...productServer
          })
        );
        dispatch(getVendingMachineById(productServer.id));
        dispatch(getVendingMachineItems(productServer.id));
      } else {
        dispatch(getVendingMachineKeyboardInfoFail(error));
        goTo('/');
      }
    })
    .catch((error) => {
      dispatch(getVendingMachineKeyboardInfoFail(error));
    });
};

const reset = () => (dispatch) => {
  dispatch({
    type: types.RESET
  });
};

export default {
  getVendingMachineByName,
  getVendingMachineByNameKeyboardInfo,
  getRecentVendingMachines,
  getVendingMachineById,
  getVendingMachineItems,
  getVendingMachineServices,
  selectProduct,
  getProductById,
  createNewDeal,
  createNewDealDirectPayment,
  getDealStatus,
  reset
};
