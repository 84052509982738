import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, oneOfType, bool } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CodeInput from '../../CodeInput';
import Header from '../../Header';
import Button from '../../Button';
import sessionActions from '../../../actions/session';
import styles from '../../../styles';
import strings from '../../../language';

const CODE_LENGTH = new Array(4).fill(0);

class PhoneVerificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
    this.codeInput = null;
  }

  componentDidMount() {
    const { phone, history } = this.props;
    console.log("phone", phone);
    if (!phone) {
      history.push('/');
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState((state) => {
      if (state.value.length >= CODE_LENGTH.length) return null;
      return {
        value: (state.value + value).slice(0, CODE_LENGTH.length)
      };
    });
  };

  handleSubmit = () => {
    const {
      sessionActions: actionsFromSession,
      country,
      phone,
      cookies
    } = this.props;
    const { value } = this.state;
    actionsFromSession.verifyCode(phone, country, value, cookies, this.goTo);
  };

  handleKeyUp = (e) => {
    if (e.key === 'Backspace') {
      this.setState((state) => ({
        value: state.value.slice(0, state.value.length - 1)
      }));
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, phone, error, loading } = this.props;
    const {
      verificationTitle,
      messageSent,
      enterCodeLabel,
      verifyButton
    } = strings;
    const { value } = this.state;
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <Header
          leftContent={<ChevronLeft />}
          leftAction={() => this.goTo('/')}
          type="secondary"
        />
        <h1 className={classes.bodyDefaultTitle}>{verificationTitle}</h1>
        <p className={classnames(classes.bodyDefaultText, classes.centered)}>
          {`${messageSent} ${phone}`}
        </p>
        <p className={classnames(classes.bodyDefaultText, classes.centered)}>
          {enterCodeLabel}
        </p>
        <p
          className={classnames(
            classes.bodyDefaultError,
            classes.centered,
            !error && classes.visibilityHidden
          )}
        >
          {error.status}
        </p>
        <CodeInput
          value={value}
          handleChange={this.handleChange}
          handleKeyUp={this.handleKeyUp}
        />
        <Grid className={classes.mdButtonContainer}>
          <Button size="md" type="primary" action={this.handleSubmit}>
            {verifyButton}
          </Button>
        </Grid>
      </>
    );
  }
}

PhoneVerificationPage.propTypes = {
  classes: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  country: string.isRequired,
  phone: string.isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  error: oneOfType([string, shape({})]).isRequired,
  loading: bool.isRequired
};

const mapStateToProps = (state) => {
  const { country, phone, error, loading } = state.session;
  return { country, phone, error, loading };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PhoneVerificationPage));
