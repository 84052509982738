import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
// import styles from '../../styles';
import HeaderSaba from '../../HeaderSaba';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import styles from '../../../styles';
import { bool } from 'prop-types';

const ticketIcon = require('../../../assets/ticketIcon.png');

const BarcodeScanTwo = ({
    classes,
    closeAction,
    data,
    setData,
    stopStream,
}) => {

    // if (window.innerHeight > window.innerWidth) {
    //     alert("Please use Landscape!");
    // }

    const videoConstrains = {
        aspectRatio: 1.6,
    }

    //VIDEOCONSTRANITS FOR IOS
    // const videoConstrainsMobile = {
    //     aspectRatio: 0.5,
    //     facingMode: 'environment',
    // }

    //VIDEOCONSTRINTS FOR ANDROID
    const videoConstrainsMobile = {
        // aspectRatio: 1,
        facingMode: 'environment',
        // focusMode: "continuos",
        // exposureMode: "continuous",
        // torch: true,
    }

    return (
        <div
            className={classes.mainPhotoContainer}
        >
            <div className={classes.scanBarcodeUpperContentContainer}>
                <div className={classes.width100}>
                    <HeaderSaba
                        closeAction={closeAction}
                        title={"Escanea un ticket"}
                    />
                </div>
                <div style={{ maxWidth: 280, marginTop: 15, marginBottom: 20 }}>
                    <p className={classes.generalTextSaba}>Acerca el código de barras de tu ticket</p>
                </div>
                <div>
                    <p className={classes.bodyDefaultText}>Ubica el código de barras sobre la linea</p>
                </div>
            </div>
            <div className={classes.scanBarcodeLowerContentContainer}>
                <div style={{ marginTop: 10, marginBottom: 2 }}>
                    <img src={ticketIcon} alt="scan-icon" className={classes.sabaTicketIcon} />
                </div>
            </div>
            <div className={classes.barcodeBorder} />
            <div className={classes.barcodeScanLine} />
            <div className={classes.photoComponentContainer}>
                <BarcodeScannerComponent
                    width={"100%"}
                    // height={"auto"}
                    onUpdate={(err, result) => {
                        if (result) setData(result.text);
                        else setData();
                    }}
                    stopStream={stopStream}
                    facingMode={'environment'}
                    autoFocus
                />
            </div>
        </div>
    );
};

BarcodeScanTwo.propTypes = {
    classes: shape({}).isRequired,
    closeAction: func,
    data: string,
    setData: func,
    stopStream: bool,
};

BarcodeScanTwo.defaultProps = {
    closeAction: () => { },
    data: null,
    setData: () => { },
    stopStream: false,
};

export default withStyles(styles)(BarcodeScanTwo);