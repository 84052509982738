import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/PersonOutline';
import styles from '../../styles';
import strings from '../../language';

const Profile = ({ classes, name, goTo }) => {
  const { viewProfile } = strings;
  return (
    <Grid
      container
      justify="space-around"
      alignItems="center"
      direction="row"
      className={classes.profileContainer}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.avatarContainer}
      >
        <PersonOutline className={classes.avatarIcon} />
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        direction="column"
        className={classes.profileInfo}
      >
        {/* <p className={classes.bodyDefaultText}>{name}</p> */}
        <p
          className={classes.profileViewLink}
          onClick={() => {
            goTo('/profile');
          }}
        >
          {viewProfile}
        </p>
      </Grid>
    </Grid>
  );
};

Profile.propTypes = {
  classes: shape({}).isRequired,
  goTo: func.isRequired,
  name: string
};

Profile.defaultProps = {
  name: ''
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { name } = communityMember;
  return {
    name
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Profile));
