import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import vendingMachineActions from '../../../actions/vendingMachine';
import styles from '../../../styles';
import strings from '../../../language';

const connectingImage = require('../../../assets/connecting.png');
const connectingGif = require('../../../assets/connecting.gif');
// const connectingGif = require('../../../assets/nes1.gif');

class ConnectingToMachinePage extends Component {
  componentDidMount() {
    const { connectToMachineTimeout, authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
    const timerFunc = setInterval(() => {
      this.checkStatusChange(timerFunc);
    }, 1000);
    setTimeout(() => {
      clearInterval(timerFunc);
      this.goToError();
    }, connectToMachineTimeout * 1000);
  }

  checkStatusChange = (timerFunc) => {
    const {
      vendingMachineActions: actionsFromVendingMachine,
      selectedVendingMachineId,
      deal,
      authToken,
      automaticDelivery
    } = this.props;
    actionsFromVendingMachine.getDealStatus(
      deal.id,
      selectedVendingMachineId,
      authToken,
      timerFunc,
      automaticDelivery,
      this.goTo,
      1
    );
  };

  goToError = () => {
    const { deal } = this.props;
    const { delivery_status: deliveryStatus } = deal;
    if (deliveryStatus === 1) {
      this.goTo('/connection-error');
    }
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const { classes, selectedVendingMachineName } = this.props;
    const { connecting } = strings;
    return (
      // <Grid container justifyContent="center" alignItems="center" direction="column">
      //   <div className={classes.bodyDownloadText}>
      //     <h4 className={classes.noMargin}>{connecting}</h4>
      //     <h3 className={classes.connectingToMachineName}>
      //       {`@${selectedVendingMachineName}`}
      //     </h3>
      //   </div>
      //   <img src={connectingImage} alt="" className={classes.connectingImage} />
      //   <img src={connectingGif} alt="" className={classes.connectingImage} />
      // </Grid>
      <div className={classes.divConnectingImage}>
        <div className={classes.bodyDownloadText} style={{ margin: '10%' }}>
          <h4 className={classes.noMargin}>{connecting}</h4>
          <h3 className={classes.connectingToMachineName}>{`@${selectedVendingMachineName}`}</h3>
        </div>
        <img src={connectingImage} alt="" className={classes.connectingImage} />
        <img src={connectingGif} alt="" className={classes.connectingImage} />
      </div>
    );
  }
}

ConnectingToMachinePage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  vendingMachineActions: shape({}).isRequired,
  authToken: string.isRequired,
  selectedVendingMachineId: number,
  selectedVendingMachineName: string,
  connectToMachineTimeout: number,
  deal: shape({}),
  automaticDelivery: bool
};

ConnectingToMachinePage.defaultProps = {
  selectedVendingMachineId: null,
  selectedVendingMachineName: '',
  connectToMachineTimeout: null,
  deal: {},
  automaticDelivery: false
};

const mapStateToProps = (state) => {
  const { communityMember } = state.session;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const { selectedVendingMachine, deal } = state.vendingMachine;
  const {
    id: selectedVendingMachineId,
    name: selectedVendingMachineName,
    connect_to_machine_timeout: connectToMachineTimeout,
    settings
  } = selectedVendingMachine;
  const { automatic_delivery: automaticDelivery = false } = settings;
  return {
    authToken,
    communityMemberId,
    selectedVendingMachineId,
    selectedVendingMachineName,
    connectToMachineTimeout,
    deal,
    automaticDelivery
  };
};

const mapDispatchToProps = (dispatch) => ({
  vendingMachineActions: bindActionCreators(vendingMachineActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConnectingToMachinePage));
