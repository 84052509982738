import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import Button from '../Button';
import styles from '../../styles';
import strings from '../../language';

const CreatedCardModal = ({
  classes,
  createdCardModal,
  closeCreatedCardModal,
  cardNumber
}) => {
  const { cardAdded1, cardAdded2, confirmLabel } = strings;
  return (
    <Modal open={createdCardModal} onClose={closeCreatedCardModal}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.modalContainer}
      >
        <p className={classes.bodyDefaultText}>
          {`${cardAdded1} ${cardNumber} ${cardAdded2}`}
        </p>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.buttonsContainer}
        >
          <Button size="md" type="primary" action={closeCreatedCardModal}>
            {confirmLabel}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

CreatedCardModal.propTypes = {
  classes: shape({}).isRequired,
  createdCardModal: bool.isRequired,
  closeCreatedCardModal: func.isRequired,
  cardNumber: string.isRequired
};

export default withStyles(styles)(CreatedCardModal);
