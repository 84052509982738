import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, arrayOf, bool, string, number } from 'prop-types';
import {
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import classnames from 'classnames';
import Clear from '@material-ui/icons/Clear';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import sessionActions from '../../../actions/session';
import Header from '../../Header';
import Button from '../../Button';
import ConfirmTopUpModal from '../../Modals/ConfirmTopUpModal';
import CustomSelect from '../../CustomSelect';
import strings from '../../../language';
import styles from '../../../styles';

class TopUpPage extends Component {
  constructor(props) {
    super(props);
    const { creditCards } = props;
    let max = { id: null };
    if (creditCards.length > 0) {
      max = creditCards.reduce((prev, current) =>
        prev.id > current.id ? prev : current
      );
    }
    this.state = {
      amount: 100,
      creditCard: max.id,
      autoTopUpCheck: false,
      confirmTopUpModal: false
    };
  }

  componentDidMount() {
    const { authToken } = this.props;
    if (!authToken) {
      this.goTo('/');
    }
  }

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      autoTopUpCheck: !prevState.autoTopUpCheck
    }));
  };

  rechargeBalance = () => {
    const {
      sessionActions: actionsFromSession,
      currency,
      authToken,
      communityMemberId
    } = this.props;
    const { creditCard, amount } = this.state;
    actionsFromSession.rechargeBalance(
      communityMemberId,
      authToken,
      creditCard,
      amount,
      currency,
      this.getMemberNewBalance,
      this.goTo
    );
  };

  getMemberNewBalance = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId
    } = this.props;
    actionsFromSession.getMemberNewBalance(communityMemberId, authToken);
  };

  confirmTopUp = () => {
    this.setState({ confirmTopUpModal: true });
  };

  closeConfirmTopUp = () => {
    this.setState({ confirmTopUpModal: false });
  };

  render() {
    const {
      classes,
      loading,
      creditCards,
      currency,
      minimumTopUp,
      countryCode
    } = this.props;
    const {
      topUpLabel,
      addThisAmount,
      usingCard,
      confirmLabel,
      autoPayText1,
      autoPayText2,
      addPaymentMethod
    } = strings;
    const {
      creditCard,
      amount,
      autoTopUpCheck,
      confirmTopUpModal
    } = this.state;
    const amountOptions = [50, 100, 200];
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{topUpLabel}</h1>
    );
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <ConfirmTopUpModal
          confirmTopUpModal={confirmTopUpModal}
          closeConfirmTopUp={this.closeConfirmTopUp}
          rechargeBalance={this.rechargeBalance}
          amount={amount}
          currency={currency}
          countryCode={countryCode}
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => this.goTo('/machine-inventory')}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          >
            <p className={classes.fullWidthInputLabel}>{addThisAmount}</p>
            <FormControl className={classes.fullWidthInput}>
              <Select
                value={amount}
                onChange={(e) => this.handleChange(e, 'amount')}
                input={<CustomSelect />}
              >
                {amountOptions.map((amountOption) => (
                  <MenuItem key={amountOption} value={amountOption}>
                    {`$${amountOption.toFixed(2)} ${currency}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className={classes.fullWidthInputLabel}>{usingCard}</p>
            <FormControl className={classes.fullWidthInput}>
              <Select
                value={creditCard}
                onChange={(e) => this.handleChange(e, 'creditCard')}
                input={<CustomSelect />}
              >
                {creditCards.map(({ card_number: cardNumber, id }) => (
                  <MenuItem key={id} value={id}>
                    {cardNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              className={classes.fullWidthCheckContainer}
            >
              <Checkbox
                value={autoTopUpCheck}
                color="primary"
                onChange={this.toggleCheckbox}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircleOutline />}
              />
              <p
                className={classnames(
                  classes.bodyDefaultText,
                  classes.fullWidthCheckText
                )}
              >
                {`${autoPayText1} $${minimumTopUp.toFixed(
                  2
                )} ${currency} ${autoPayText2}`}
              </p>
            </Grid>
            <Button size="md" type="primary" action={this.confirmTopUp}>
              {confirmLabel}
            </Button>
            <Button
              size="md"
              type="outlined"
              action={() => {
                this.goTo('/select-payment-method');
              }}
            >
              {addPaymentMethod}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

TopUpPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  loading: bool.isRequired,
  creditCards: arrayOf(shape({})).isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  currency: string,
  minimumTopUp: number,
  communityMemberId: number,
  countryCode: string
};

TopUpPage.defaultProps = {
  authToken: '',
  communityMemberId: null,
  currency: 'MXN',
  minimumTopUp: 50,
  countryCode: 'MX'
};

const mapStateToProps = (state) => {
  const { loading, creditCards, communityMember } = state.session;
  const { selectedVendingMachine } = state.vendingMachine;
  const { auth_token: authToken, id: communityMemberId } = communityMember;
  const {
    country: {
      currency_iso: currency,
      minimun_top_up: minimumTopUp,
      iso: countryCode
    } = {}
  } = selectedVendingMachine;

  return {
    loading,
    creditCards,
    authToken,
    communityMemberId,
    currency,
    minimumTopUp,
    countryCode
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TopUpPage));
