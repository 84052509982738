import React from 'react';
import {
  shape,
  oneOf,
  func,
  arrayOf,
  oneOfType,
  node,
  string,
  number
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import DisabledButton from './DisabledButton';
import OutlinedButton from './OutlinedButton';
import styles from '../../styles';

const Button = ({ classes, size, type, action, children }) => {
  const button = {
    primary: (
      <PrimaryButton onClick={action} color="primary">
        {children}
      </PrimaryButton>
    ),
    secondary: (
      <SecondaryButton onClick={action} color="primary">
        {children}
      </SecondaryButton>
    ),
    disabled: (
      <DisabledButton onClick={action} color="primary">
        {children}
      </DisabledButton>
    ),
    outlined: (
      <OutlinedButton onClick={action} color="primary">
        {children}
      </OutlinedButton>
    )
  };
  const containerClass = {
    sm: 'smButtonContainer',
    md: 'mdButtonContainer',
    lg: 'lgButtonContainer'
  };
  return <Grid className={classes[containerClass[size]]}>{button[type]}</Grid>;
};

Button.propTypes = {
  classes: shape({}).isRequired,
  size: oneOf(['sm', 'md', 'lg']).isRequired,
  type: oneOf(['primary', 'secondary', 'disabled', 'outlined']).isRequired,
  action: func.isRequired,
  children: oneOfType([node, string, number, arrayOf(shape({}))]).isRequired
};

export default withStyles(styles)(Button);
