import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { shape, string, number, bool } from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import { API_KEY, MERCHANT_ID } from '../../../config/api';
import sessionActions from '../../../actions/session';
import CreatedCardModal from '../../Modals/CreatedCardModal';
import Header from '../../Header';
import strings from '../../../language';
import styles from '../../../styles';

class EdenredCallbackPage extends Component {
  constructor() {
    super();
    this.state = {
      createdCardModal: false
    };
  }

  componentDidMount() {
    const openpayScript = document.createElement('script');
    openpayScript.src = 'https://js.openpay.mx/openpay.v1.min.js';
    openpayScript.async = true;
    document.head.appendChild(openpayScript);
    openpayScript.onload = () => this.openpayScriptLoaded();
  }

  openpayScriptLoaded = () => {
    window.OpenPay.setId(MERCHANT_ID);
    window.OpenPay.setApiKey(API_KEY);
    const openpayDataScript = document.createElement('script');
    openpayDataScript.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
    openpayDataScript.async = true;
    document.head.appendChild(openpayDataScript);
    openpayDataScript.onload = () => this.addEdenredCard();
  };

  goTo = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  addEdenredCard = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId,
      name,
      location,
      cookies
    } = this.props;
    const cookiesVars = cookies.getAll();
    const {
      authToken: authTokenFromCookies = null,
      communityMemberId: communityMemberIdFromCookies = null,
      name: nameFromCookies = null
    } = cookiesVars;
    const usedAuthToken = authToken || authTokenFromCookies;
    const usedCommunityMemberId =
      communityMemberId || communityMemberIdFromCookies;
    const usedName = name || nameFromCookies;
    const deviceSessionId = window.OpenPay.deviceData.setup();
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    if (usedAuthToken && usedCommunityMemberId && usedName) {
      actionsFromSession.addEdenredCard(
        code,
        usedCommunityMemberId,
        usedAuthToken,
        usedName,
        deviceSessionId,
        () => {
          this.getCrediCards();
          this.openCreatedCardModal();
        }
      );
    }
  };

  getCrediCards = () => {
    const {
      sessionActions: actionsFromSession,
      authToken,
      communityMemberId,
      cookies
    } = this.props;
    const cookiesVars = cookies.getAll();
    const {
      authToken: authTokenFromCookies = null,
      communityMemberId: communityMemberIdFromCookies = null
    } = cookiesVars;
    const usedAuthToken = authToken || authTokenFromCookies;
    const usedCommunityMemberId =
      communityMemberId || communityMemberIdFromCookies;
    if (usedAuthToken && usedCommunityMemberId) {
      actionsFromSession.getUserCreditCards(
        usedCommunityMemberId,
        usedAuthToken
      );
    }
  };

  openCreatedCardModal = () => {
    this.setState({
      createdCardModal: true
    });
  };

  render() {
    const { classes, loading } = this.props;
    const { createdCardModal } = this.state;
    const { paymentMethodsLabel } = strings;
    const headerCenterContent = (
      <h1 className={classes.headerDefaultTitle}>{paymentMethodsLabel}</h1>
    );
    if (loading) {
      return <CircularProgress className={classes.primaryLoading} />;
    }
    return (
      <>
        <CreatedCardModal
          createdCardModal={createdCardModal}
          closeCreatedCardModal={() => this.goTo('/top-up')}
          cardNumber="Edenred"
        />
        <Grid
          className={classes.containerWithHeader}
          container
          justify="flex-start"
          alignItems="flex-start"
        >
          <Header
            leftContent={<Clear />}
            leftAction={() => this.goTo('/machine-inventory')}
            centerContent={headerCenterContent}
            type="secondary"
          />
          <Grid
            container
            className={classes.bodyWithHeaderContainer}
            justify="flex-start"
            alignItems="center"
            direction="column"
            wrap="nowrap"
          />
        </Grid>
      </>
    );
  }
}

EdenredCallbackPage.propTypes = {
  classes: shape({}).isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  sessionActions: shape({}).isRequired,
  authToken: string,
  location: shape({}).isRequired,
  communityMemberId: number,
  name: string,
  loading: bool.isRequired
};

EdenredCallbackPage.defaultProps = {
  authToken: '',
  communityMemberId: null,
  name: ''
};

const mapStateToProps = (state) => {
  const { communityMember, loading } = state.session;
  const {
    auth_token: authToken,
    id: communityMemberId,
    name
  } = communityMember;
  return {
    authToken,
    communityMemberId,
    name,
    loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EdenredCallbackPage));
